import { useRecoilState } from "recoil";
import APIService from "../Services/APIService";
import { cateListState } from "../Dev/Recoil/ticketCategoryState";
import { useCallback } from "react";

const GET_TICKET_PATH = "api/backend/ticket-cate-list";

const useTicketCategory = () => {
    const [cateList, setCateList] = useRecoilState(cateListState);

    const getCateList = useCallback(async () => {
        const { body } = await APIService.post(GET_TICKET_PATH);
        if (body) {
            const datas = body.datas || [];
            
            setCateList(datas);
        }
    }, [setCateList]);

    return { cateList, getCateList }
}

export default useTicketCategory;