import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Swal from "../Containers/SweetAlertUtil";
import RegisterService from "../Services/RegisterService";
import GlobalConstant from "../GlobalConstant";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import SyncLoader from "react-spinners/SyncLoader";

function RegisterForm() {
  const cssLoading = {
    display: "flex",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    justifyContent: "center",
    alignItems: "center",
    "z-index": "5400",
  };

  const color = "#f5f6fa";

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = async (data) => {
    setLoading(true);
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha();
    // console.log("token ", token);
    
    try {
      const resps = await RegisterService.register({...data, token: token});
      setLoading(false);
      if (resps.data.header.success === 'Y') {
        Swal.showAlertWithCallBack(GlobalConstant.ALERT_TYPE.SUCCESS, "Register success", "").then(
          (ok) => {
            if (ok) {
              window.location.replace("/login");
            }
          }
        );
      } else {
        Swal.showAlert("error", "Error", "ไม่สามารถลงทะเบียนได้");
      }
    } catch (error) {
      // console.log(error);
      setLoading(false);
      Swal.showAlert("error", "Error", "ไม่สามารถลงทะเบียนได้");
    }
  };

  return (
    <>
      <SyncLoader
        color={color}
        loading={loading}
        css={cssLoading}
      />
      <div className="card">
        <div className="card-body register-card-body">
          <p className="login-box-msg">สมัครเพื่อใช้งาน</p>
          <form method="post" onSubmit={handleSubmit(onSubmit)}>
            <div className="input-group mb-3">
              <input
                className={
                  errors.username
                    ? "form-control is-invalid"
                    : "form-control"
                }
                placeholder="บัญชี"
                {...register("username", { required: true, type: "text" })}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-user" />
                </div>
              </div>
            </div>

            <div className="input-group mb-3">
              <input
                className={
                  errors.firstname
                    ? "form-control is-invalid"
                    : "form-control"
                }
                placeholder="ชื่อ"
                {...register("firstname", { required: true, type: "text" })}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-address-book" />
                </div>
              </div>
            </div>

            <div className="input-group mb-3">
              <input
                className={
                  errors.lastname
                    ? "form-control is-invalid"
                    : "form-control"
                }
                placeholder="นามสกุล"
                {...register("lastname", { required: true, type: "text" })}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-address-book" />
                </div>
              </div>
            </div>

            {errors.email && (
              <span className="text-danger" role="alert">
                {errors.email.message}
              </span>
            )}
            <div className="input-group mb-3">
              <input
                className={
                  errors.email ? "form-control is-invalid" : "form-control"
                }
                placeholder="Email"
                {...register("email", {
                  required: true,
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Entered value does not match email format",
                  },
                  type: "email",
                })}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-envelope" />
                </div>
              </div>
            </div>
            {errors.mobile && (
              <span className="text-danger" role="alert">
                {errors.mobile.message}
              </span>
            )}
            <div className="input-group mb-3">
              <input
                className={
                  errors.mobile ? "form-control is-invalid" : "form-control"
                }
                placeholder="08XXXXXXXX"
                {...register("mobile", {
                  required: true,
                  type: "text",
                  maxLength: {
                    value: 10,
                    message: "The mobile max 10 charecter.",
                  },
                  // validate: (value) => value.length == 10,
                })}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-mobile" />
                </div>
              </div>
            </div>
            <div className="input-group mb-3">
              <input
                type="password"
                className={
                  errors.password
                    ? "form-control is-invalid"
                    : "form-control"
                }
                placeholder="รหัสผ่าน"
                {...register("password", { required: true })}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-lock" />
                </div>
              </div>
            </div>

            {errors.password_confirm &&
              errors.password_confirm.type === "validate" && (
                <span className="text-danger" role="alert">
                  The passwords do not match.
                </span>
              )}
            <div className="input-group mb-3">
              <input
                type="password"
                className={
                  errors.password_confirm
                    ? "form-control is-invalid"
                    : "form-control"
                }
                placeholder="ยืนยันรหัสผ่าน"
                {...register("password_confirm", {
                  required: true,
                  validate: (value) => value === watch("password"),
                })}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-lock" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <button type="submit" className="btn btn-primary btn-block">
                  ลงทะเบียน
                </button>
              </div>
              {/* /.col */}
            </div>
          </form>
          <Link to="/login" className="text-center">
            มีบัญชีแล้วใช่มั้ย?
          </Link>
        </div>
        {/* /.form-box */}
      </div>
    </>
  );
}

export default RegisterForm;
