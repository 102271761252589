import config from "./Config";

const AVATAR_PATH = config.SERVER_URL + "/files/avatar/";
const AVATAR_EMPTY_PATH = "/dist/img/icons/admin-avatar.png";
const EMPTY_IMAGE = "/dist/img/icons/image-empty.png";
const LOGO_EMPTY_PATH = "/dist/img/icons/chat-speech.png";
const DEFUALT_COLOR = "#343a40";

const ALERT_TYPE = {
  PRIMARY: "primary",
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
};

const SUCCESS_TITLE = "สำเร็จ";
const SUCCESS_MSG = "";
const ERROR_TITLE = "พบข้อผิดพลาด";
const ERROR_MSG = "กรุณาลองใหม่อีกครั้ง";
const WARNING_TITLE = "แจ้งเตือน";

const EMPTY_DATA = "No Data.";

//Loading css
const LOADING_CSS = {
  position: "relative",
  display: "flex",
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.4)",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1040,
};

const LOADING_COLOR = "#f5f6fa";

export default {
  DEFUALT_COLOR: DEFUALT_COLOR,
  LOGO_EMPTY_PATH: LOGO_EMPTY_PATH,
  AVATAR_PATH: AVATAR_PATH,
  AVATAR_EMPTY_PATH: AVATAR_EMPTY_PATH,
  SUCCESS_TITLE: SUCCESS_TITLE,
  SUCCESS_MSG: SUCCESS_MSG,
  ERROR_TITLE: ERROR_TITLE,
  ERROR_MSG: ERROR_MSG,
  EMPTY_DATA: EMPTY_DATA,
  WARNING_TITLE: WARNING_TITLE,
  ALERT_TYPE: ALERT_TYPE,
  LOADING_CSS: LOADING_CSS,
  LOADING_COLOR: LOADING_COLOR,
  EMPTY_IMAGE: EMPTY_IMAGE
};
