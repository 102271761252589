import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
// import { useRecoilValue } from "recoil";
import Swal from "../../../Containers/SweetAlertUtil";
import LocalStorageUtil from "../../../Dev/LocalStorageUtil";
// import { storageState } from "../../../Dev/Recoil/storageState";
import GlobalConstant from "../../../GlobalConstant";
import RegisterChatController from "./RegisterChat.data";
import { useSetRecoilState } from "recoil";
import { hasLoadingAtom } from "./Recoil/guestAtom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

function RegisterChat(props) {
  const { tokenKey, handle, socketId, hasRegister } = props;
  const { register, handleSubmit, setValue, clearErrors, formState: { errors } } = useForm();
  const setShowLoading = useSetRecoilState(hasLoadingAtom)
  const { executeRecaptcha } = useGoogleReCaptcha();

  // const dataFromStorage = useRecoilValue(storageState);
  const [categoryList, setCategoryList] = useState([]);

  // useEffect(() => {
  //   if (dataFromStorage) {
  //     const fields = ["category", "detail", "name", "email"];
  //     fields.forEach((field) => {
  //       setValue(field, dataFromStorage[field]);
  //     });
  //   }
  // }, [dataFromStorage]);

  useEffect(() => {
    !hasRegister && getCategory();
    return () => {};
  }, []);

  const getCategory = async () => {
    const params = {
      tokenKey: tokenKey
    }
    const { status, data } = await RegisterChatController.getCategory(params);
    if (status) {
      let list = data.categoryList;
      setCategoryList(list);
    }
  }

  const onSubmit = useCallback( async (data) => {
    setShowLoading(true)
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha();
    // let oldGuest = LocalStorageUtil.getGuest();

    const params = {
      token: token,
      // guest_id: oldGuest && oldGuest.guest_id,
      name: data.name,
      socket_id: socketId,
      // email: data.email,
    };

    const result = await RegisterChatController.registerChat(params);
    if (result.status) {
      let category = getCategoryById(data.category);
      const { guest } = result.data;
      let params = {
        detail: data.detail,
        cate_id: category.id,
        cate_name: category.cate_name,
        guest_id: guest.guest_id,
        name: guest.name,
        // email: guest.email,
      };
      LocalStorageUtil.setGuestItem(params);
      handle(params);
    } else {
      let msg = result.errorText;
      if (msg && msg != "") {
        Swal.showAlert("error", GlobalConstant.ERROR_TITLE, msg);
      }
    }
  }, [executeRecaptcha, categoryList]);

  const getCategoryById = useCallback((id) => {
    let category = categoryList.find((item) => item.id == id);
    return category;
  }, [categoryList])

  const [showDropdownList, setShowDropdownList] = useState(false)
  useEffect(() => {
    // console.log(showDropdownList);
  
    return () => {
    }
  }, [showDropdownList])
  
  
  return (
    <div className="position-absolute w-100 h-100 p-3">
      <div className="text-center">
        <h3>ติดต่อเราผ่านการแชท</h3>
      </div>

      <div className="container-fluid p-0 mt-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group" style={{marginBottom: "40px"}}>
            <div className="d-flex align-items-center">
              <i className="fas fa-comment-dots fa-2x avatar bg-dark rounded-circle text-white p-3 position-absolute"></i>
              <select
                id="select_category"
                className={
                  errors.category ? "form-control is-invalid ml-5 pl-4" : "form-control ml-5 pl-4"
                }
                defaultValue={"default"}
                {...register("category", {
                  required: true,
                  validate: (value) => value !== "default",
                })}
              >
                <option value={"default"} disabled hidden>
                  เลือกหัวข้อที่ต้องการติดต่อ
                </option>
                {categoryList && categoryList.length > 0
                  ? categoryList.map((item, index) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.cate_name}
                        </option>
                      );
                    })
                  : null}
              </select>
            </div>
          </div>

          <div className="form-group" style={{marginBottom: "40px"}}>
            <div className="d-flex align-items-center">
              <i className="fas fa-user-circle fa-2x avatar bg-dark rounded-circle text-white p-3 position-absolute"></i>
              <input 
                type="text" 
                id="input_name" 
                placeholder="ชื่อ"
                className={`form-control ${errors.name && 'is-invalid'} ml-5 pl-4`}
                {...register("name", {
                  required: true,
                })}
              />
            </div>
          </div>

          {/* <div className="form-group" style={{marginBottom: "30px"}}>
            <div className="d-flex align-items-center">
              <i className="fas fa-envelope fa-2x avatar bg-dark rounded-circle text-white p-3 position-absolute"></i>
              <input 
                type="text" 
                id="input_email" 
                placeholder="อีเมล์"
                className="form-control ml-5 pl-4"
                {...register("email")}
              />
            </div>
          </div> */}

          <div className="form-group">
            <label htmlFor="input_detail">รายละเอียด</label>
            <textarea
              id="input_detail"
              rows="2"
              className="form-control"
              {...register("detail")}
            >
            </textarea>
          </div>

          <div className="row justify-content-center mt-4">
            <button type="submit" className="btn btn-success w-40 mb-2" style={{borderRadius: "30px"}}>
              เริ่มสนทนา
            </button>

            <button
              type="button"
              className="btn btn btn-danger mb-2 ml-2"
              style={{borderRadius: "30px"}}
              onClick={() => {
                clearErrors();
                window.parent.postMessage("collap", "*");
              }}
            >
              ยกเลิก
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default RegisterChat;
