import axios from "axios";
import config from "../../../Config";
const REGISTER_CHAT_PATH = "api/public/guest-register";
const GET_CATEGORY_PATH = "api/public/category-list";

let responseMsg = {
  status: false,
  data: null,
  errorText: null,
};

class ResgisterChatController {
  async getCategory(params) {
    //console.log("== START GET CATEGORY ==");
    let _url = config.SERVER_URL + GET_CATEGORY_PATH;
    let response = await axios.post(_url, params);
    let data = response.data;
    //console.log("== END GET CATEGORY ==");
    if (data && data.header.success === "Y") {
      responseMsg.status = true;
      responseMsg.data = data.body;
    } else {
      responseMsg.status = false;
      responseMsg.errorText = data.header.message;
    }
    return responseMsg;
  }

  async registerChat(params) {
    //console.log("== START REGISTER CHAT ==");
    let _url = config.SERVER_URL + REGISTER_CHAT_PATH;
    //console.log("<<<<< PARAMS : ", params);
    let response = await axios.post(_url, params);
    //console.log("response", response);
    let data = response.data;
    //console.log("== END REGISTER CHAT ==");
    let result = null;
    if (data && data.header.success === "Y") {
      responseMsg.status = true;
      responseMsg.data = data.body;
      result = responseMsg;
    } else {
      responseMsg.status = false;
      responseMsg.errorText = data.header.message;
      result = responseMsg;
    }
    return result;
  }
}

export default new ResgisterChatController();
