import React, { useState, useEffect } from "react";
import globalConstant from "../../../GlobalConstant";
import ContentHeader from "../../ContentHeader";
import AuthenService from "../../../Services/AuthenService";
import ClipLoader from "react-spinners/ClipLoader";
import SummaryCard from "./components/SummaryCard";
import HomePageData from "./HomePage.data";
import SweetAlertUtil from "../../../Containers/SweetAlertUtil";
import GlobalConstant from "../../../GlobalConstant";
import Image from "../../../Components/Image";
import useSummary from "../../../hooks/useSummary";

function HomePage() {
  const [hasDetailLoading, setHasDetailLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const { userSummary, getSummary } = useSummary()

  const { userid, firstname, lastname } = AuthenService.getAccessor();
  
  useEffect(() => {
    setHasDetailLoading(false);
  }, [userSummary])

  const color = "#3DB2FF";

  const imageHandler = async (e) => {
    setLoading(true);
    const image = e.target.files[0];
    let formData = new FormData();
    formData.append("userid", userid);
    formData.append("avatar", image);
    
    const {status, data, errorText} = await HomePageData.onUploadAvatar(formData);
    setLoading(false);

    if (status) {
      getSummary()
    }else {
      SweetAlertUtil.showAlert(GlobalConstant.ALERT_TYPE.ERROR, GlobalConstant.ERROR_TITLE, errorText);
    }
  };

  return (
    <>
      <ContentHeader
        title="Admin Home Page"
        titleName="Admin Home"
        backTitle="Admin Index"
        backLink="/admin"
      />

      <div className="content">
        <div className="container-fluid">
          <div className="row ml-3 mb-3 align-items-center">
            {loading ? (
              <ClipLoader color={color} loading={loading} />
            ) : (
              <a href="#">
                <Image 
                  key={userSummary.user_info?.img_profile_full_path || ''} 
                  src={userSummary.user_info?.img_profile_full_path || ''} 
                  className='img-circle'
                  fallback={globalConstant.AVATAR_EMPTY_PATH} 
                  width='50' 
                  height='50' 
                  onClick={() => document.getElementById("file_avatar").click()}
                />

                <input
                  type="file"
                  id="file_avatar"
                  style={{ display: "none" }}
                  accept="image/*"
                  multiple={false}
                  onChange={imageHandler}
                />
              </a>
            )}
            <div className="col">
              <h4 className="my-0">
                สวัสดี {firstname} {lastname}
              </h4>
              <p className="my-0">Thank you for use help chat</p>
            </div>
          </div>

          <SummaryCard loading={hasDetailLoading} counts={userSummary.counts || {}} />

          {/* <div className="card">
            <div className=" card-header bg-primary">
              <h4 className="card-title">Real time overview</h4>
            </div>
            <div className=" card-body">
              <div className="row">
                <div className="col-4">
                  <h6>Operator online</h6>
                  <h2>0</h2>
                </div>

                <div className="col-4">
                  <h6>Ongoing chats</h6>
                  <h2>0</h2>
                </div>

                <div className="col-4">
                  <h6>Unassigned tickets</h6>
                  <h2>0</h2>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
export default HomePage;
