import apiService from "../../../Services/APIService";
import responseBody from "../../../Services/ResponseBody";
const ADD_SITE_PATH = "api/backend/site/add";
const EDIT_SITES_PATH = "api/backend/site/edit";
const SEARCH_SITES_PATH = "api/backend/site/search";
const STATUS_SITES_PATH = "api/backend/site/edit-status";
const DELETE_SITE_PATH = "api/backend/site/delete";

let responseMsg = {
  status: false,
  data: null,
  errorText: null,
};

class SitesPageController {
  async addSite(data) {
    //console.log("== START ADD SITE ==");
    const params = {
      domain: data.domain,
      key_name: data.key_name,
      line_notify_token: data.line_notify_token,
    };
    let response = await apiService.post(ADD_SITE_PATH, params);
    //console.log("== END ADD SITE ==");
    let result = null;
    if (response && response.header.success === "Y") {
      result = responseBody(true, response.body);
    } else {
      result = responseBody(false, null, response.header.message);
    }
    return result;
  }

  editSite(data) {
    return new Promise(async (success, reject) => {
      //console.log("===== Start Edit Site");
      const params = {
        site_id: data.site_id,
        domain: data.domain,
        key_name: data.key_name,
        line_notify_token: data.line_notify_token
      };
      let response = await apiService.post(EDIT_SITES_PATH, params);
      //console.log("===== End Edit Site");
      if (response && response.header.success == "Y") {
        responseMsg.status = true;
        responseMsg.data = response.body;
        success(responseMsg);
      } else {
        responseMsg.status = false;
        responseMsg.errorText = response.header.message;
        success(responseMsg);
      }
    });
  }

  search(data) {
    return new Promise(async (success, reject) => {
      //console.log("===== START SEARCH SITES");
      let response = await apiService.post(SEARCH_SITES_PATH, data);
      //console.log("===== END SEARCH SITES");
      if (response && response.header.success == "Y") {
        responseMsg.status = true;
        responseMsg.data = response.body;
        success(responseMsg);
      } else {
        responseMsg.status = false;
        responseMsg.errorText = response.header.message;
        success(responseMsg);
      }
    });
  }
  
  async changeStatus(data) {
    //console.log("== START UPDATE STATUS SITE ==");
    let response = await apiService.post(STATUS_SITES_PATH, data);
    //console.log("== END UPDATE STATUS SITE ==");
    let result = null;
    if (response && response.header.success === "Y") {
      result = responseBody(true, response.body);
    } else {
      result = responseBody(false, null, response.header.message);
    }
    return result;
  }

  async deleteSite(data) {
    let response = await apiService.post(DELETE_SITE_PATH, data);
    let result = null;
    if (response && response.header.success === "Y") {
      result = responseBody(true, response.body);
    } else {
      result = responseBody(false, null, response.header?.message || "");
    }
    return result;
  }
}

export default new SitesPageController();
