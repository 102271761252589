import APIService from "../../../Services/APIService";
import responseBody from "../../../Services/ResponseBody";

const CHANGEPASS = "api/backend/change-password";

class ChangePasswordData {
  async onChangePassword(params) {
    let response = await APIService.post(CHANGEPASS, params);
    let result = null;
    if (response && response.header.success === "Y") {
      result = responseBody(true, response.body);
    } else {
      result = responseBody(false, null, response.header.message);
    }
    return result;
  }
}

export default new ChangePasswordData();
