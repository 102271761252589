const moment = require('moment');

const monthThaiNames = [
    "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน",
    "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม.",
    "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"
];
const monthThaiShortNames = ["ม.ก.", "ก.พ.", "มี.ค.", "เม.ย.","พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค..","ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];
const dateutility = {
    compareDate: (dateA, dateB) => {
        // if(!(dateA instanceof Date) || !(dateB instanceof Date)) return undefined;
        let result = 0;
        let newDateA = moment(dateA, 'DD/MM/YYYY').toDate();
        let newDateB = moment(dateB, 'DD/MM/YYYY').toDate();
        if(moment(newDateA).isBefore(newDateB))  result = 1;
        else if(moment(newDateA).isAfter(newDateB))  result = -1;
        return result;
    },
    compareDateTime: (dateA, dateB) => {
        if(!(dateA instanceof Date) || !(dateB instanceof Date)) return undefined;
        let result = 0;
        if(dateA.getTime() > dateB.getTime()) result = -1;
        else if(dateA.getTime() < dateB.getTime())  result = 1;
        return result;
    },
    betweenDate: (stDate, edDate) => {
        if(!(stDate instanceof Date) || !(edDate instanceof Date)) return undefined;
        return moment(new Date()).isBetween(stDate, edDate);
    },
    compareBetween: (dateA, dateB) => {
        let newDateA = moment(dateA, 'DD/MM/YYYY').toDate();
        let newDateB = moment(dateB, 'DD/MM/YYYY').toDate();
        return moment(new Date).isBetween(newDateA, newDateB);
    },
    parseDate: (strdate) => {
        var date = moment(strdate, 'DD/MM/YYYY').toDate();
        return date;
    },parseSQLDate: (strdate) => {
        var date = moment(strdate, 'YYYY-MM-DD').toDate();
        return date;
    },
    parseDateTime: (strdatetime) => {
        var datetime = moment(strdatetime, 'DD/MM/YYYY HH:mm:ss').toDate();
        return datetime;
    },parseSQLDateTime: (strdatetime) => {
        var datetime = moment(strdatetime, 'YYYY-MM-DD HH:mm:ss').toDate();
        return datetime;
    },
    formatDate: (date, format) => {
       // if(!(date instanceof Date)) return '';
       try{
            return moment(date).format(format?format:'DD/MM/YYYY');
       }catch(e){
            console.error(e);
       }
       return '';
    },
    formatDateTime: (date, format) => {
        try{
            return moment(date).format(format?format:'DD/MM/YYYY HH:mm:ss');
        }catch(e){
            console.error(e);
        }
        return '';
    },
    formatTime: (date, format) => {
        try{
            return moment(date).format(format?format:'HH:mm:ss');
        }catch(e){
            console.error(e);
        }
        return '';
    },
    formatSQLDate: (date, format) => {
        // if(!(date instanceof Date)) return '';
        try{
             return moment(date).format(format?format:'YYYY-MM-DD');
        }catch(e){
             console.error(e);
        }
        return '';
     },
     formatSQLDateTime: (date, format) => {
        // if(!(date instanceof Date)) return '';
        try{
             return moment(date).format(format?format:'YYYY-MM-DD HH:mm:ss');
        }catch(e){
             console.error(e);
        }
        return '';
     },
    getAge: (birthDate, date) => {
        try{
            var age = moment.duration(moment(date?date:Date.now()).diff(birthDate));
            return age;
        }catch(e) {
            console.error(e);
        }
        return undefined;
        // age.getYears() , age.getMonths(), age.getDays()
    },
    formatFullDateThai: (pdate)=>{
        try{
            let date = moment(pdate).toDate();
            let year = date.getFullYear() + 543;
            let month = monthThaiNames[date.getMonth()];
            let numOfDay = date.getDate();
            return `${numOfDay} ${month} ${year}`;
        }catch(e) {
            console.error(e);
        }
        return "";
    },
    formatFullDateThaiShortMonth: (pdate)=>{
        try{
            let date = moment(pdate).toDate();
            let year = date.getFullYear() + 543;
            let month = monthThaiShortNames[date.getMonth()];
            let numOfDay = date.getDate();
            return `${numOfDay} ${month} ${year}`;
        }catch(e) {
            console.error(e);
        }
        return "";
    },
    formatFullDateTimeThai: (pdate)=>{
        try{
            let date = moment(pdate).toDate();
            let hour = date.getHours().toString().padStart(2, "0");
            let minutes = date.getMinutes().toString().padStart(2, "0");
            let second = date.getSeconds().toString().padStart(2, "0");
            return dateutility.formatFullDateThai(date) + ` ${hour}:${minutes}:${second} น.`;
        }catch(e) {
            console.error(e);
        }
        return "";
    },
    formatFullDateTimeThaiShortMonth: (pdate)=>{
        try{
            let date = moment(pdate).toDate();
            let hour = date.getHours().toString().padStart(2, "0");
            let minutes = date.getMinutes().toString().padStart(2, "0");
            let second = date.getSeconds().toString().padStart(2, "0");
            return dateutility.formatFullDateThaiShortMonth(date) + ` ${hour}:${minutes}:${second} น.`;
        }catch(e) {
            console.error(e);
        }
        return "";
    }
    ,
    getMonthThaiNames: ()=>{
        return monthThaiNames
    }
    ,
    getMonthThaiShortNames: ()=>{
        return monthThaiShortNames
    }
    
};



module.exports = dateutility;