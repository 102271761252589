import ClipLoader from "react-spinners/ClipLoader";

function SummaryCard({loading, counts}) {

  const loadingCss = {
    width: "15px",
    height: "15px"
  }
  
  return (
    <>
      <div className="row">
        <div className="col-12 col-sm-6 col-md-3">
          <div className="info-box">
            <span className="info-box-icon bg-danger elevation-1">
              <i className="fas fa-exclamation"></i>
            </span>
            <div className="info-box-content">
              <span className="info-box-text">ใหม่</span>
              <span className="info-box-number">
                { loading ? <ClipLoader loading={loading} css={loadingCss} /> : (counts.new_room) }
              </span>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-3">
          <div className="info-box mb-3">
            <span className="info-box-icon bg-warning elevation-1">
              <i className="fas fa-hand-paper"></i>
            </span>
            <div className="info-box-content">
              <span className="info-box-text">ไม่พบปัญหา</span>
              <span className="info-box-number">
                { loading ? <ClipLoader loading={loading} css={loadingCss} /> : (counts.not_found) }
              </span>
            </div>
          </div>
        </div>
        <div className="clearfix hidden-md-up" />
        <div className="col-12 col-sm-6 col-md-3">
          <div className="info-box mb-3">
            <span className="info-box-icon bg-info elevation-1">
              <i className="fas fa-hourglass-half"></i>
            </span>
            <div className="info-box-content">
              <span className="info-box-text">อยู่ระหว่างดำเนินการ</span>
              <span className="info-box-number">
                { loading ? <ClipLoader loading={loading} css={loadingCss} /> : (counts.in_rogress) }
              </span>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-3">
          <div className="info-box mb-3">
            <span className="info-box-icon bg-success elevation-1">
              <i className="fas fa-thumbs-up" />
            </span>
            <div className="info-box-content">
              <span className="info-box-text">สำเร็จ</span>
              <span className="info-box-number">
                { loading ? <ClipLoader loading={loading} css={loadingCss} /> : (counts.success) }
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SummaryCard;
