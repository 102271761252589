import Config from "../Config";

class LocalStorageUtil {
    checkGuest() {
        let guest = this.getGuest();
        return guest ? true : false
    }

    setGuestItem(params) {
        const path = Config.GUEST_LOCAL_STORAGE_PATH;
        sessionStorage.setItem(path, JSON.stringify(params));
    }
    
    setAdminItem(params) {
        const path = Config.ADMIN_LOCAL_STORAGE_PATH;
        sessionStorage.setItem(path, JSON.stringify(params));
    }
    
    getGuest() {
        const path = Config.GUEST_LOCAL_STORAGE_PATH;
        let item = sessionStorage.getItem(path);
        return JSON.parse(item);
    }
    
    getAdmin() {
        const path = Config.ADMIN_LOCAL_STORAGE_PATH;
        let item = sessionStorage.getItem(path);
        return JSON.parse(item);
    }

    updateGuest(data) {
        let old = this.getGuest();
        const path = Config.GUEST_LOCAL_STORAGE_PATH;
        sessionStorage.setItem(path, JSON.stringify({...old, data}));
    }

    remove(key) {
        let path = ''
        switch (key) {
            case 'admin':
                path = Config.ADMIN_LOCAL_STORAGE_PATH
                break;
            case 'guest':
                path = Config.GUEST_LOCAL_STORAGE_PATH;
                break;
            default:
                break;
        }
        sessionStorage.removeItem(path)
    }
}

export default new LocalStorageUtil;