import React from 'react'

const FooterAdmin = ()=> {

    return (
        <footer className="main-footer">
            <strong>Copyright © 2021 <a href="http://ichat.kdc-product.com">ichat</a></strong>&nbsp;All rights reserved.
            <div className="float-right d-none d-sm-inline-block"><b>Version</b> 1.0</div>
        </footer>
    );
}

export default FooterAdmin;