import { format } from "timeago.js";
import "./MessageCss.css";

const Message = ({ message, readMsgId }) => {
  // //console.log("MESSAGE : ", message);
  return (
    <div>
      <li key={message.id} className={message.is_staff ? "sent" : "replies"}>
        <img src="/dist/img/icons/chat-avatar.png" />
        <p
          dangerouslySetInnerHTML={{
            __html: message.content,
          }}
        />
      </li>
      <div className={`${ message.is_staff ? "date-time-receive" : "date-time-sender" } text-gray align-items-center`} >
        {!message.is_staff && message.id === readMsgId ? <i className="fas fa-check-circle mx-1"></i> : ""} {format(message.create_date, "th_TH")}
      </div>
    </div>
  );
};

export default Message;