import { useRecoilState } from "recoil";
import SweetAlertUtil from "../Containers/SweetAlertUtil";
import GlobalConstant from "../GlobalConstant";
import APIService from "../Services/APIService";
import AuthenService from "../Services/AuthenService";
import { userRecoilState } from "../Dev/Recoil/userState";
const GET_SUMMARY = "api/backend/summary";

const useSummary = () => {
    const { userid } = AuthenService.getAccessor();
    const [userSummary, setUser] = useRecoilState(userRecoilState);

    const getSummary = async () => {
        let params = {
            userId: userid
        }
        const {body, message} = await APIService.post(GET_SUMMARY, params);
        if (message) return SweetAlertUtil.showAlert(GlobalConstant.ALERT_TYPE.ERROR, GlobalConstant.ERROR_TITLE , message);

        if (body) {
            setUser(body);
        }
    };

return {userSummary, getSummary}
}
export default useSummary;