import { atom } from "recoil";

  const allRoomState = atom({
    key: "allRoomState",
    default: [],
  });

  const newRoomState = atom({
    key: "newRoomState",
    default: [],
  });
  
  export { 
    allRoomState,
    newRoomState
   };