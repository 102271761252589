import APIService from "../../../Services/APIService";
import responseBody from "../../../Services/ResponseBody";
let SITE_PATH = "api/backend/site";
let UPDATE_SITE_PATH = "api/backend/site/edit";

class SiteSettingData {
  async onGetSiteSetting(tokenKey) {
    //console.log("== START GET SETTING SITE ==");
    let response = await APIService.get(`${SITE_PATH}/${tokenKey}`);
    //console.log("== END GET SETTING SITE ==");
    let result = null;
    if (response && response.header.success === "Y") {
      result = responseBody(true, response.body);
    } else {
      result = responseBody(false, null, response.header.message);
    }
    return result;
  }
  async updateSite(params, site_id) {
    //console.log("===== Start Update Site");
    let header = {
      headers: { "Content-Type": "multipart/form-data;" },
    };
    let formData = new FormData();
    Object.keys(params).forEach(key => {
      formData.append(key, params[key]);
    })
    let url = UPDATE_SITE_PATH + "/" + site_id;
    let response = await APIService.post(url, formData, header);
    //console.log("===== End Update Site");
    let res = null;
    if (response && response.header.success === "Y") {
      res = responseBody(true, response.body);
    } else {
      res = responseBody(false, null, response.header.message);
    }
    return res;
  }
}

export default new SiteSettingData();
