import React, { useEffect, useRef } from "react";
import Config from "../../../Config";
import './ManualCss.css';
const pathPdf = 'upload/files/manual.pdf';

const ManualPage = () => {
    const framRef = useRef(null);
    useEffect(() => {
        framRef.current.src = Config.SERVER_URL + pathPdf;
    }, []);

    return (
        <div className="row justify-content-center px-3 px-sm-0">
            <div className="col-md-8">
                <div className="text-center">
                    <iframe
                        ref={framRef} 
                        className="content-pdf py-3"
                        frameBorder="0"
                        width="100%"
                    ></iframe>
                </div>
            </div>
        </div>
    )
}
export default ManualPage;
