import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { RecoilRoot } from "recoil";
import PageRouters from "./Pages/PageRouters";
import "./App.css";
import LoginPage from "./Login/LoginPage";
import RegisterPage from "./Register/RegisterPage";
import AuthenService from "./Services/AuthenService";
import GuardedRoute from "./Pages/GuardeRoute/GuardeRoute";
import AdminChatPage from "./Pages/Admin/AdminChatPage/AdminChatPage";
import { SocketContext, socket } from "./Services/Socket";
import GuestChatPage from "./Pages/User/UserChatPage/GuestChatPage";

const App = (props) => {
  return (
    <RecoilRoot>
      <SocketContext.Provider value={socket}>
        <Router>
          <Switch>
            <Route path="/login" exact component={LoginPage} />
            <Route path="/register" exact component={RegisterPage} />
            <Route path="/live/chat/:key" exact component={GuestChatPage} />

            <GuardedRoute
              path="/page/chat/:tokenKey"
              component={AdminChatPage}
              auth={AuthenService.isAuthen()}
            ></GuardedRoute>

            <GuardedRoute
              path="/"
              component={PageRouters}
              auth={AuthenService.isAuthen()}
            ></GuardedRoute>
            <PageRouters />
          </Switch>
        </Router>
      </SocketContext.Provider>
    </RecoilRoot>
  );
}
export default App
