import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import SyncLoader from "react-spinners/SyncLoader";
import SweetAlert from "react-bootstrap-sweetalert";

import AuthenService from "../Services/AuthenService";

function LoginPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const cssLoading = {
    display: "flex",
    width: "100%",
    height: "100%",
    position: "fixed",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    justifyContent: "center",
    alignItems: "center",
    "z-index": "5400",
  };

  const color = "#f5f6fa";

  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {}, []);

  const onSubmit = async (data) => {
    setLoading(true);

    let params = {
      username: data.email,
      password: data.password,
    };

    // console.log("onSubmit params", params);

    let user = await AuthenService.userLogin(params);
    setErrorMsg("");
    setLoading(false);
    
    if (!user.is_log) {
      setErrorMsg(user.error);
      setShowAlert(true);
    } else {
      if (user.data.user.role_id === "100") {
        setErrorMsg('บัญชีนี้สำหรับระบบ back office');
        setShowAlert(true);
        AuthenService.userLogout();
        return
      }
      let isAuthen = AuthenService.isAuthen();
      if (isAuthen) {
        window.location.replace("/page/homepage");
      }

      // console.log("Login Success", isAuthen);
    }
  };

  const onConfirm = () => {
    setShowAlert(false);
  };

  return (
    <>
      <SyncLoader color={color} loading={loading} css={cssLoading} />

      <div className="login-page">
        <div className="login-box">
          <div className="login-logo">
            <a href="/login">
              <b>Help</b>Chat
            </a>
          </div>
          <div className="card">
            <div className="card-body login-card-body">
              <p className="login-box-msg">Sign in to start your session</p>
              <form method="post" onSubmit={handleSubmit(onSubmit)}>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className={
                      errors.email ? "form-control is-invalid" : "form-control"
                    }
                    placeholder="Username"
                    {...register("email", { required: true, type: "text" })}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-envelope"></span>
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    type="password"
                    className={
                      errors.password
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    placeholder="Password"
                    {...register("password", {
                      required: true,
                      type: "password",
                    })}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock"></span>
                    </div>
                  </div>
                </div>
                <div className="icheck-primary">
                  <input type="checkbox" id="remember" />
                  <label htmlFor="remember">&nbsp;Remember Me</label>
                </div>
                <div className="row">
                  {/* ปิดไว้ก่อน รอเปิด register ตอนหลัง */}
                  <div className="col-md-6">
                    {/* <a href="/register" className="btn btn-secondary btn-block">Register</a> */}
                  </div>
                  <div className="col-md-6">
                    <button type="submit" className="btn btn-primary btn-block">
                      Sign In
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {showAlert ? (
        <SweetAlert error title="Error" onConfirm={onConfirm}>
          {errorMsg}
        </SweetAlert>
      ) : null}
    </>
  );
}
export default LoginPage;
