import React, { useCallback, useContext, useEffect } from "react";
import globalConstant from "../GlobalConstant";
import { Link, useLocation } from "react-router-dom";
import AuthenService from "../Services/AuthenService";
import './MenusLeft.css';
import useSiteList from "../hooks/useSiteList";
import { SocketContext } from "../Services/Socket";
import Image from "../Components/Image";
import useSiteUnread from "../hooks/useSiteUnread";
import SweetAlertUtil from "./SweetAlertUtil";
import GlobalConstant from "../GlobalConstant";
import AudioServices from "../Services/AudioServices";
import Swal from "sweetalert2";
import useSummary from "../hooks/useSummary";

const MenusRight = () => {
  const socket = useContext(SocketContext)
  const location = useLocation();
  const { firstname, lastname } = AuthenService.getAccessor();
  const {siteList, getSiteList} = useSiteList();
  const { userSummary, getSummary } = useSummary()

  const {unreadSite, getSiteUnread} = useSiteUnread()
  const credentials = AuthenService.getCredentials();

  useEffect(() => {
    getSummary()
  }, [])

  useEffect(() => {
    getSiteList();
    getSiteUnread();

    socket.on("on_refresh_count_unread", refreshCountUnreadCallBack);

    return () => {
      socket.off("on_refresh_count_unread", refreshCountUnreadCallBack);
      socket.off("sound_nonification", soundNonificationCallBack);
    };
  }, []);

  const refreshCountUnreadCallBack = ((object) => {
    getSiteUnread();
  })

  useEffect(() => {
    socket.on("sound_nonification", soundNonificationCallBack);
  }, [siteList]);
  const soundNonificationCallBack = useCallback((object) => {
    const {token_key, content} = object;
    if(siteList.some(site => site.token_key === token_key)){
      const index = siteList.findIndex(site => site.token_key === token_key)
      const site = siteList[index]
      Swal.fire({
        title: `${site.key_name}`,
        text: `${content}`,
        iconHtml: '<img src="/dist/img/meZhat/meZhatLogo.png" alt="alertIcon" width="35" height="35" class="bg-white p-1">',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      AudioServices.playAudio();
    }
  }, [siteList])

  const logout = () => {
    SweetAlertUtil.showAlertWithConfirmButton(GlobalConstant.ALERT_TYPE.WARNING, GlobalConstant.WARNING_TITLE, 'ต้องการออกจากระบบ?')
    .then(ok => {
      if(!ok) return

      let isAuthen = AuthenService.isAuthen();
      if (isAuthen) {
        AuthenService.userLogout();
        window.location.replace('/login');
      }
    });
  };

  const superMenu = [];
  if (credentials.user.role_id == 300 || credentials.user.role_id == 200) {
    superMenu.push(
      <React.Fragment key={0}>
        <li className="nav-item">
          <Link 
            to={{ pathname: '/page/agent' }}
            className={
              location.pathname == '/page/agent' ? "nav-link active" : "nav-link"
            }
          >
            <i className="nav-icon fas fa-user-friends text-success"></i>
            <p>จัดการเจ้าหน้าที่</p>
          </Link>
        </li>

        <li className="nav-item">
          <Link 
            to={{ pathname: '/page/sites' }}
            className={
              location.pathname.includes('sites') ? "nav-link active" : "nav-link"
            }
          >
            <i className="nav-icon fas fa-globe text-primary"></i>
            <p>จัดการเว็บไซต์</p>
          </Link>
        </li>
        {/* ปิดไว้ก่อนใน version นี้ */}
        {/* <li className="nav-item">
          <Link 
            to={{ pathname: '/page/member' }}
            className={
              location.pathname == '/page/member' ? "nav-link active" : "nav-link"
            }
          >
            <i className="nav-icon fas fa-cubes text-warning"></i>
            <p>จัดการแพ็คเกจ</p>
          </Link>
        </li> */}
      </React.Fragment>
    );
  }

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <Link to="/page" className="brand-link">
        <img
          src="/dist/img/meZhat/meZhatLogo.png"
          alt="AdminLTE Logo"
          className="brand-image"
        />
        <span className="brand-text">MeZhat</span>
      </Link>

      <div className="sidebar">
        {/* <!-- Sidebar user panel (optional) --> */}
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <Image 
            key={userSummary.user_info?.img_profile_full_path || ''} 
            src={userSummary.user_info?.img_profile_full_path || ''}
            className="img-circle elevation-2 ml-2"  
            width='50' 
            height='50'
            fallback={globalConstant.AVATAR_EMPTY_PATH}
          />
          <div className="info">
            <Link to="/page/homepage" className="d-block">
              {firstname} {lastname}
            </Link>
          </div>
        </div>

        {/* <!-- Sidebar Menu --> */}
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li className="nav-item menu-open">
              <a href="#" className="nav-link">
                <i className="nav-icon far fa-comments text-success"></i>
                <p>
                  รายการกำลังสนทนา <i className="fas fa-angle-left right"></i>
                </p>
              </a>
              <ul className="nav nav-treeview">
                {siteList && siteList.length > 0
                  ? siteList.map((item) => {
                      return (
                        <li className="nav-item" key={item.token_key}>
                          <Link
                            to={{ pathname: `/page/chat/${item.token_key}` }}
                            className={
                              location.pathname ==
                              `/page/chat/${item.token_key}`
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                            <i className="fa fa-circle nav-icon text-success"></i>
                            <p>{item.key_name}</p>
                            {unreadSite &&
                              unreadSite.length > 0 &&
                              unreadSite.map((i, index) => {
                                if (i.token_key === item.token_key && i.un_read_count !== '0') {
                                  return (
                                    <span key={index} className="badge badge-danger right">{i.un_read_count}</span>
                                  );
                                }
                              })}
                          </Link>
                        </li>
                      );
                    })
                  : null}
              </ul>
            </li>
            <li><hr className="bg-secondary"/></li>
            <li className="nav-item">
              <Link 
                to={{ pathname: `/page/chat-list` }}
                className={ (location.pathname == `/page/chat-list` ? "nav-link active" : "nav-link")}
              >
                <i className="nav-icon fa fa-list-alt text-info"></i>
                <p>
                  ประวัติรายการสนทนา
                </p>
              </Link>
            </li>
            {superMenu}
            <li><hr className="bg-secondary"/></li>
            {/* <li className="nav-item">
              <Link 
                to={"/page/user-manual"} 
                className={(location.pathname == '/page/user-manual' ? "nav-link active" : "nav-link")}
              >
                <i className="nav-icon fas fa-address-book text-success"></i> คู่มือ
              </Link>
            </li> */}
            <li className="nav-item">
              <Link 
                to={{ pathname: '/page/changepassword' }}
                className={
                  'text-warning '+ (location.pathname == '/page/changepassword' ? "nav-link active" : "nav-link")
                }
              >
                <i className="nav-icon fas fa-key"></i>
                <p>เปลี่ยนรหัสผ่าน</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link 
                to="#"
                className="nav-link text-danger"
                onClick={logout}
              >
                <i className="nav-icon fas fa-sign-out-alt"></i>
                <p>ออกจากระบบ</p>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
};
export default MenusRight;
