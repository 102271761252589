import APIService from "../../../Services/APIService";
import responseBody from "../../../Services/ResponseBody";
const CREATE_RENEW = "api/backend/renew/create";
const CANCEL_RENEW = "api/backend/renew/cancel";

class MemberManageData {
    async onCreateRenew(params) {
        //console.log("== START MEMBER RENEW ==");
        let response = await APIService.post(CREATE_RENEW, params);
        //console.log("== END MEMBER RENEW ==");
        let result = null;
        if (response && response.header.success === "Y") {
            result = responseBody(true, response.body);
        } else {
            const {header} = response
            result = responseBody(false, null, header.message);
        }
        return result;
    }
    async onCancelRenew(params) {
        //console.log("== START CANCEL RENEW ==");
        let response = await APIService.post(CANCEL_RENEW + '/' + params);
        //console.log("== END CANCEL RENEW ==");
        let result = null;
        if (response && response.header.success === "Y") {
            result = responseBody(true, response.body);
        } else {
            const {header} = response
            result = responseBody(false, null, header.message);
        }
        return result;
    }
}

export default new MemberManageData;