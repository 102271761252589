import React, { useEffect } from 'react'
import MenusLeft from './MenusLeft'
import HeaderAdmin from './HeaderAdmin'
//import ControlSidebar from './ControlSideBar'
import FooterAdmin from './FooterAdmin'
import AlertPackage from '../Components/AlertPackage';
import GlobalConstant from '../GlobalConstant';
import useCheckPackage from '../hooks/useCheckPackage';
import AuthenService from '../Services/AuthenService';

function AdminLayout({ children }) {
    const {errorPackage, getCheckPackage} = useCheckPackage()
    const {role_id} = AuthenService.getAccessor()

    useEffect(() => {
        getCheckPackage();
    }, []);

    return (
        <>
            <AlertPackage show={errorPackage} showBackDrop={false} type={GlobalConstant.ALERT_TYPE.WARNING} description={errorPackage} buttonText={"จัดการแพ็คเกจ"} link={role_id == 200 || role_id == 300 ? "/page/member" : null}/>

            <HeaderAdmin />
            <MenusLeft />
            <div className="wrapper">
                <div className="content-wrapper">
                    {children}
                </div>
            </div>
            {/* <ControlSidebar /> */}
            <FooterAdmin />
        </>
    );
}
export default AdminLayout;