import { Link } from "react-router-dom";

function AlertPackage ({show, showBackDrop, type, description, buttonText, link}) {

    const className = `position-fixed top-0 right-0 left-0 w-100 shadow-lg bg-${type || 'white'} p-4 animate__bounceIn`
    
    return ( 
        <>
        <div className={show ? className : 'd-none'} style={{zIndex: 1050}}>
            <div className='text-center'>
                <h5>{description}</h5>
                {link && 
                    <Link to={link} className='btn btn-success'>{buttonText}</Link>
                }
            </div>
        </div>
        {showBackDrop && show && <div className="swal2-container swal2-center swal2-backdrop-show" style={{overflowY: "auto", zIndex: 1040}}></div>}
        </>
     );
}
 
export default AlertPackage;