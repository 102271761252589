import axios from "axios";
import config from "../../../Config";
import responseBody from "../../../Services/ResponseBody";
const GET_MSG_BY_ROOM = "api/public/chat-msg-byroom";
const GET_SITE_SETTING_BY_TOKEN = "api/public/chat-config";
const CHECK_PACKAGE = "api/public/check-package";

class GuestChatPage {
  async onGetMsgByRoomId(params) {
    let _url = config.SERVER_URL + GET_MSG_BY_ROOM;
    //console.log("== START GET MSG ROOM ==");
    //console.log("<< PARAMS : ", params);
    let response = await axios.post(_url, params);
    // console.log("RESPONSE GET MSG BY ROOM : ", response);
    const { data } = response;
    //console.log("== END GET MSG ROOM ==");
    let result = null;
    if (data && data.header.success === "Y") {
      result = responseBody(true, data.body);
    } else {
      result = responseBody(false, null, data.header.message);
    }
    return result;
  }
  async onGetSiteSetting(token_key) {
    let _url = config.SERVER_URL + GET_SITE_SETTING_BY_TOKEN;
    const params = {
      token_key: token_key,
    };
    //console.log("== START CONFIG CHAT ==");
    //console.log("<< PARAMS : ", params);
    let response = await axios.get(_url, { params: params });
    let data = response.data;
    // //console.log("== CONFIG CHAT ==", data);
    //console.log("== END CONFIG CHAT ==");
    let res = null;
    if (data && data.header.success === "Y") {
      res = responseBody(true, data.body);
    } else {
      res = responseBody(false, data, data.header.message);
    }
    return res;
  }
  async onCheckPackage(domain, token_key) {
    let _url = config.SERVER_URL + CHECK_PACKAGE;
    const params = {
      domain: domain,
      token: token_key,
    };
    //console.log("== START CHECK PACKAGE ==");
    //console.log("<< PARAMS : ", params);
    let response = await axios.post(_url, params);
    let data = response.data;
    //console.log("CHECK PACKAGE RESPONSE : ", data);
    //console.log("== END CHECK PACKAGE ==");
    let res = null;
    if (data && data.header.success === "Y") {
      res = responseBody(true, data.body);
    } else {
      res = responseBody(false, data, data.header.message);
    }
    return res;
  }
}

export default new GuestChatPage();
