import { useRecoilState } from "recoil";
import SweetAlertUtil from "../Containers/SweetAlertUtil";
import { memberListAtom, memberDetailAtom } from "../Dev/Recoil/memberAtom";
import GlobalConstant from "../GlobalConstant";
import APIService from "../Services/APIService";
const GET_MEMBER_LIST = "api/backend/list";
const GET_MEMBER_DETAIL = "api/backend/detail";

function useMember() {
    const [memberList, setMemberList] = useRecoilState(memberListAtom);
    const [memberDetail, setMemberDetail] = useRecoilState(memberDetailAtom);
    
    const getMemberList = async (companyId, statusIds, page, size, orderBy, sortBy) => {
        let values = {
            companyId: companyId,
            statusIds: JSON.stringify(statusIds),
            page: page,
            size: size || 5,
            orderBy: orderBy,
            sortBy: sortBy
        }
        
        const {body, message} = await APIService.get(GET_MEMBER_LIST, values);
        if (message) return SweetAlertUtil.showAlert(GlobalConstant.ALERT_TYPE.ERROR, GlobalConstant.ERROR_TITLE , message);
        
        if (body) {
            // console.log(body);
            const packages = body.list || [];
            setMemberList(packages);
        }
    }

    const getMemberDetail = async (memberId) => {
        
        const {body, message} = await APIService.get(GET_MEMBER_DETAIL + '/' + memberId);
        if (message) return SweetAlertUtil.showAlert(GlobalConstant.ALERT_TYPE.ERROR, GlobalConstant.ERROR_TITLE , message);
        
        if (body) {
            // console.log(body);
            setMemberDetail({...body.member, package: body.package});
        }
    }

    return {memberList, getMemberList, setMemberList, memberDetail, getMemberDetail};
}

export default useMember;