import { useRecoilState } from "recoil";
import SweetAlertUtil from "../Containers/SweetAlertUtil";
import { packageListAtom, packageListPaginateAtom ,lastPackageListAtom ,lastPackageListPaginateAtom} from "../Dev/Recoil/packageAtom";
import GlobalConstant from "../GlobalConstant";
import APIService from "../Services/APIService";
const GET_PACKAGE_LIST = "api/backend/package/list";
const GET_LASTPACKAGE = "api/backend/package/lastpackage";

const usePackageList = () => {
    const [packageList, setPackageList] = useRecoilState(packageListAtom);
    const [packageListPaginate, setPaginate] = useRecoilState(packageListPaginateAtom);
    const [lastPackage, setLastPackageList] = useRecoilState(lastPackageListAtom);
    // const [lastPackageListPaginate ,setLastPackageListPaginate] = useRecoilState(lastPackageListPaginateAtom);


    
    const getPackageList = async (page, size, orderBy, sortBy) => {
        let values = {
            page: page,
            size: size,
            orderBy: orderBy,
            sortBy: sortBy
        }
        const {body, message} = await APIService.get(GET_PACKAGE_LIST, values);
        if (message) return SweetAlertUtil.showAlert(GlobalConstant.ALERT_TYPE.ERROR, GlobalConstant.ERROR_TITLE , message);

        if (body) {
            setPackageList(body.list || []);
            setPaginate(body.pagination);
        }
    };

    const getLastPackage = async () => {

        const {body, message} = await APIService.get(GET_LASTPACKAGE, {});
        if (message) return SweetAlertUtil.showAlert(GlobalConstant.ALERT_TYPE.ERROR, GlobalConstant.ERROR_TITLE , message);

        if (body) {
            setLastPackageList(body.list || []);
            // setPaginate(body.pagination);
        }
    };

return {packageList, packageListPaginate, getPackageList,getLastPackage,lastPackage}
}
export default usePackageList;