
import { atom } from "recoil";

const renewListAtom = atom({
    key: "renewList",
    default: [],
});

const renewPaginateAtom = atom({
    key: "renewPaginateAtom",
    default: {
        "currentPage": 0,
        "pages": 0,
        "currentCount": 0,
        "totalCount": 0,
    }
});

export {renewListAtom, renewPaginateAtom};