import apiService from "../../../Services/APIService";
import responseBody from "../../../Services/ResponseBody";

const GET_SITE_LIST = "api/backend/site/list";
const GET_CHAT_LIST = "api/backend/chat-list";
const GET_MESSAGE_LIST = "api/backend/message-list";
const GET_STATUS_ROOM = "api/backend/room-status-master-list";

class ChatListPage {
  async onGetStatusRoom() {
    //console.log("== START GET STATUS ROOM ==");
    let response = await apiService.get(GET_STATUS_ROOM);
    //console.log("== END GET STATUS ROOM ==");
    let result = null;
    if (response && response.header.success === "Y") {
      result = responseBody(true, response.body);
    } else {
      result = responseBody(false, null, response.header.message);
    }
    return result;
  }

    async onGetSiteList() {
      //console.log("== START GET SITE LIST ==");
      let url = GET_SITE_LIST;
      let response = await apiService.post(url);
      //console.log("== END GET SITE LIST ==");
      let result = null;
      if (response && response.header.success === "Y") {
        result = responseBody(true, response.body);
      } else {
        result = responseBody(false, null, response.header.message);
      }
      return result;
    }
    async onGetChatList(params) {
        //console.log("== START GET CHAT LIST ==");
        let url = GET_CHAT_LIST;
        let response = await apiService.post(url, params);
        //console.log("== END GET CHAT LIST ==");
        let result = null;
        if (response && response.header.success === "Y") {
          result = responseBody(true, response.body);
        } else {
          result = responseBody(false, null, response.header.message);
        }
        return result;
    }
    async onGetMessageList(params) {
      //console.log("== START GET MESSAGE LIST ==");
      let url = GET_MESSAGE_LIST;
      let response = await apiService.post(url, params);
      //console.log("== END GET MESSAGE LIST ==");
      let result = null;
      if (response && response.header.success === "Y") {
        result = responseBody(true, response.body);
      } else {
        result = responseBody(false, null, response.header.message);
      }
      return result;
  }
}
export default new ChatListPage();