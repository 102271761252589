import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

/*
<button
type="button"
class="btn btn-default"
data-toggle="modal"
data-target="#modal-default"
>
Launch Default Modal
</button>
*/

function Modal ({
  modalId,
  title,
  show,
  position,
  btnSuccessText,
  btnCancelText,
  children,
  handleSuccess,
  cancel,
}) {
  const successText = btnSuccessText == "" || btnSuccessText == null ? "ตกลง" : btnSuccessText;
  const cancelText = btnCancelText == "" || btnCancelText == null ? "ยกเลิก" : btnCancelText;
  const showModalBtnRef = useRef();
  const cancelModalBtnRef = useRef();

  useEffect(() => {
    if (show) {
      showModalBtnRef.current.click();
    } else {
      cancelModalBtnRef.current.click();
    }
  }, [show]);

  const onCancel = () => {
    cancelModalBtnRef.current.click();
    cancel();
  }

  return (
    <>
      <button
        type="button"
        className="d-none"
        ref={showModalBtnRef}
        data-toggle="modal"
        data-target={'#' + modalId}
        data-backdrop="static"
        data-keyboard="false"
      ></button>

      <div className="modal fade" id={modalId} aria-hidden="true">
        <div className={`modal-dialog ${position?position:''}`}>
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{title}</h4>
              <button
                ref={cancelModalBtnRef}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onCancel}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">{children}</div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                onClick={onCancel}
              >
                {cancelText}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSuccess}
              >
                {successText}
              </button>
            </div>
          </div>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>
    </>
  );
};

Modal.propTypes = {
  modalId: PropTypes.element.isRequired,
  modalId: PropTypes.string,
  title: PropTypes.element.isRequired,
  title: PropTypes.string,
  show: PropTypes.element.isRequired,
  show: PropTypes.bool,
  btnSuccessText: PropTypes.string,
  handleSuccess: PropTypes.element.isRequired,
  handleSuccess: PropTypes.func,
  cancel: PropTypes.func,
};

export default Modal;
