import { atom } from "recoil";

const packageListAtom = atom({
  key: "packageList",
  default: [],
});

const lastPackageListAtom = atom({
  key: "lastPackageListAtom",
  default: [],
});

const lastPackageListPaginateAtom = atom({
  key: "lastPackage",
  default: {
      "currentPage": 0,
      "pages": 0,
      "currentCount": 0,
      "totalCount": 0,
  }
});
const packageListPaginateAtom = atom({
  key: "packageListPaginateAtom",
  default: {
      "currentPage": 0,
      "pages": 0,
      "currentCount": 0,
      "totalCount": 0,
  }
});

export { packageListAtom, packageListPaginateAtom ,lastPackageListAtom ,lastPackageListPaginateAtom};