import React, { useState, useEffect, useContext, useCallback } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import "./AdminChatPageCss.css";
import { SocketContext } from "../../../Services/Socket";
import Swal from "../../../Containers/SweetAlertUtil";

import AuthenService from "../../../Services/AuthenService";
import AdminChatController from "./AdminChatPage.data";
import GlobalConstant from "../../../GlobalConstant";

import Message from "../../Admin/AdminChatPage/components/Message";
import useCountRoom from "../../../hooks/useCountRoom";
import useSiteUnread from "../../../hooks/useSiteUnread";
import HeaderChat from "./components/HeaderChat";
import { allRoomsAtom, newRoomsAtom, textAtom, currentStatusCaseAtom, showAssignAtom, showInputAtom, messagesAtom, selectRoomAtom } from "./Recoil/AdminChatPageAtom";
import SideBar from "./components/Sidebar";
import Input from "./components/Input";
import HeaderRoomChat from "./components/HeaderRoomChat";
import MessageContent from "./components/MessageContent";
import AlertPackage from "../../../Components/AlertPackage";
import useCheckPackage from "../../../hooks/useCheckPackage";
import { checkPackage } from "../../../Dev/Recoil/checkPackage";
import useRoomList from "../../../hooks/useRoomList";
import AudioServices from "../../../Services/AudioServices";

function AdminChatPage(props) {
  const { tokenKey } = props.match.params;
  const [msgLoading, setMsgLoading] = useState(false);
  const [text, setText] = useRecoilState(textAtom);
  const socket = useContext(SocketContext);
  const [messages, setMessage] = useRecoilState(messagesAtom);
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [rooms, setRooms] = useRecoilState(allRoomsAtom);
  const [newRooms, setNewRooms] = useRecoilState(newRoomsAtom);
  const [isActiveRoom, setIsActiveRoom] = useState(false);
  const [selectRoom, setSelectRoom] = useRecoilState(selectRoomAtom);
  const [activeRoomDetail, setActiveRoomDetail] = useState(null);
  const [filterStatusList, setFilterStatusList] = useState({ statusOnline: "", filterStatus: [], onlyMyRoom: false, roomStatus: 'all' })
  const setHasShowAssignRoom = useSetRecoilState(showAssignAtom);
  const setHasShowInput = useSetRecoilState(showInputAtom);
  const setCurrentStatusCase = useSetRecoilState(currentStatusCaseAtom);
  const {getRoomList} = useRoomList()
  const {getRoomUnread} = useCountRoom()
  const {getSiteUnread} = useSiteUnread()

  const [readMsgIdState, setReadMsgIdState] = useState(null)
  const {errorPackage, getCheckPackage} = useCheckPackage(checkPackage);

  const { role_id, userid } = AuthenService.getAccessor();

  useEffect(() => {
    getCheckPackage();
  }, [])

  useEffect(() => {
    console.log('---------USE EFFECT AdminChatPage ----------');

    !socket.connected && socket.connect();
    setTimeout(() => {
      socket.emit('status', {userid, is_staff: true});
    }, 1000);

    socket.on("message", messageReplyCallBack);
    socket.on("on_refresh_count_unread", refreshCountUnreadCallBack);
    socket.on("sound_nonification", soundNonificationCallBack);
    return () => {
      socket.off("message", messageReplyCallBack);
      socket.off("on_refresh_count_unread", refreshCountUnreadCallBack);
      socket.off("sound_nonification", soundNonificationCallBack);
      clearScreen();
    }
  }, [])

  const refreshCountUnreadCallBack = useCallback((object) => {
    getSiteUnread();
    getRoomUnread(tokenKey);

    if (object.token_key === tokenKey && !(object.is_staff)) {
      setReadMsgIdState(object.read_msg_id);
    }
  }, [tokenKey])

  const soundNonificationCallBack = useCallback((object) => {
    const {token_key} = object;
    if(tokenKey === token_key){
      AudioServices.playAudio();
    }
  }, [tokenKey])

  useEffect(() => {
    clearScreen();
  }, [tokenKey]);
  const clearScreen = () => {
    setSelectRoom({roomId: "", guestId: "", statusId: ""});
    setActiveRoomDetail(null);
    setIsActiveRoom(false);
    setMessage([]);
  }

  //update rooms
  useEffect(() => {
    if (arrivalMessage && rooms) {
      let room = rooms.some((item) => item.id === arrivalMessage.room_id);
      room &&
        setRooms((arr) => {
          let filter = arr.filter((item) => item.id !== arrivalMessage.room_id);
          let old = arr.find((item) => item.id === arrivalMessage.room_id);
          const newList = {
            ...old,
            content: arrivalMessage.content
          };
          return [newList, ...filter];
        });
    }

    if (arrivalMessage && newRooms) {
      let room = newRooms.some((item) => item.id === arrivalMessage.room_id);
      room &&
        setNewRooms((arr) => {
          let index = arr.findIndex((item) => item.id === arrivalMessage.room_id);
          let newSite = arr.find((i) => i.id === arrivalMessage.room_id);
          if (index !== -1) {
            const newList = replaceRoom(arr, index, {
              ...newSite,
              content: arrivalMessage.content
            });
            return newList;
          }
          return arr;
        });
    }
  }, [arrivalMessage]);

  //Update message
  useEffect(() => {
    if (arrivalMessage && selectRoom.roomId === arrivalMessage.room_id && tokenKey === arrivalMessage.token_key) {
      setMessage((prev) => [...prev, arrivalMessage]);
      // hasShowInput && setReadFlag(arrivalMessage.room_id);
    }
  }, [tokenKey, arrivalMessage, selectRoom]);

  const messageReplyCallBack = useCallback((object) => {
    //console.log("message : ", object);
    let values = {
      ...object,
      create_date: Date.now()
    };
    setArrivalMessage(values);
    callGetRomList();
  }, []);

  function callGetRomList(){
    let params = {
      tokenKey: tokenKey,
      filter: filterStatusList,
      staffid: userid,
    }

    getRoomList(params);
  }

  const sendMessage = useCallback(() => {
    if (selectRoom.guestId && selectRoom.roomId && text != "") {
      let values = {
        token_key: tokenKey,
        room_id: selectRoom.roomId,
        to: selectRoom.guestId,
        own_id: userid,
        is_staff: true,
        content: text,
        read_flag: 0,
        create_date: new Date(),
      };
      socket.emit("message", values, emitMessageCallBack);
    }
  }, [tokenKey, selectRoom, text])

  const emitMessageCallBack = useCallback((object) => {
    const { status, errors, data } = object;
    if (status) {
      // console.log("message : ", data);
      let values = {
        ...data,
        create_date: Date.now(),
      };
      setArrivalMessage(values);
      setText("");
      setReadFlag();

      let params = {
        tokenKey: tokenKey,
        filter: filterStatusList,
        staffid: userid,
      }
      getRoomList(params);
    } else {
      console.error(errors);
      Swal.showAlert(GlobalConstant.ALERT_TYPE.ERROR, GlobalConstant.ERROR_TITLE, errors);
    }
  });

  const onTabSendTextBox = () => {
    setReadFlag();
  }

  const onSelectRoom = (guestId, roomId, statusId) => {
    //console.log("-------------onSelectRoom---------------");
    //console.log("roomId ", roomId);
    //console.log("guestId ", guestId);
    selectRoom.roomId !== roomId &&
    setSelectRoom({ roomId: roomId, guestId: guestId, statusId: statusId });
  }

  useEffect(() => {
    const onGetMsg = async () => {
      setIsActiveRoom(true);
      await getMsgByRoom();
      // check status room
      switch (selectRoom.statusId) {
        case 1: // new
          setHasShowAssignRoom(true);
          setHasShowInput(false);
          break;
        case 2: // officer received case
          setHasShowAssignRoom(false);
          setHasShowInput(true);
          break;
        case 3 || 4: // close room
          setHasShowAssignRoom(false);
          setHasShowInput(false);
          setReadFlag();
          break;
        default:
          setHasShowAssignRoom(false);
          setHasShowInput(false);
          break;
      }
    }
    selectRoom.roomId !== "" && onGetMsg()
    return () => {
      setHasShowAssignRoom(false);
      setHasShowInput(false);
    }
  }, [selectRoom])

  const getMsgByRoom = async () =>  {
    setMsgLoading(true);

    const param = { room_id: selectRoom.roomId };
    const { status, data, errorText } = await AdminChatController.onGetMsgByRoom(param);

    setMsgLoading(false);

    if (status) {
      let room = data.room || {};
      setActiveRoomDetail(room);
      setCurrentStatusCase(room.case_status && room.case_status.id); // {...room.case_status}
      let msg = data.messages || [];
      setMessage(msg);
      data.read_msg_id && setReadMsgIdState(data.read_msg_id);
    }else {
      Swal.showAlert(GlobalConstant.ALERT_TYPE.ERROR, GlobalConstant.ERROR_TITLE, errorText);
    }
  }

  const setReadFlag = useCallback(() => {
    let value = {
      token_key: tokenKey,
      userid: userid,
      room_id: selectRoom.roomId,
      is_staff: true,
    };
    socket.emit("set_read_flag", value, (response) => {
      console.log("set_read_flag : ", response);
      const { status, errorText } = response;
      if (status) {
        getSiteUnread();
        getRoomUnread(tokenKey);
      }else {
        Swal.showAlert(GlobalConstant.ALERT_TYPE.ERROR, GlobalConstant.ERROR_TITLE, errorText);
      }
    });
  }, [tokenKey, selectRoom, messages])
  
  function replaceRoom(list, i, newVal) {
    return [...list.slice(0, i), newVal, ...list.slice(i + 1)];
  }

  return (
    <>
      <div className="content">
        <AlertPackage show={errorPackage} showBackDrop={true} type={GlobalConstant.ALERT_TYPE.WARNING} description={errorPackage} buttonText={"จัดการแพ็คเกจ"} link={role_id == 200 || role_id == 300 ? "/page/member" : null}/>

        <HeaderChat tokenKey={tokenKey}/>

        <div id="frame-admin">

          <SideBar tokenKey={tokenKey} isActiveRoom={isActiveRoom} onClickRoom={onSelectRoom}/>
          
          {/* Message Layout */}
          <MessageContent msgIsLoading={msgLoading} isActiveRoom={isActiveRoom}>

            {/* CHAT ROOM HEAD */}
            <HeaderRoomChat guestId={selectRoom.guestId} activeRoom={activeRoomDetail} onClickBack={() => clearScreen()}/>
            {/* END CHAT ROOM HEAD */}

            {/* MESSAGE */}
            <Message message={messages} readMsgId={readMsgIdState}/>
            {/* END MESSAGE */}

            {/* INPUT */}
            {!(errorPackage) && 
              <Input tokenKey={tokenKey} onClick={onTabSendTextBox} onSendMessage={sendMessage}/>
            }
            {/* END INPUT */}

          </MessageContent>
          {/* End Message Layout */}
        </div>
      </div>
    </>
  );
}

export default AdminChatPage;
