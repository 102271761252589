import Cookies from 'universal-cookie';
const cookie = new Cookies();
class CookieService {
    get(key, optionals){
        return cookie.get(key, optionals || {path: '/'});
    }
    set(key, value, optionals){
        cookie.set(key, value, optionals || this.getOptionals());
    }
    remove(key,optionals){
        cookie.remove(key,optionals || {path: '/'});
    }
    cookieIns(){
        return cookie;
    }
    getOptionals(){
        let date = new Date();
        let expiresDate = new Date(date.getTime() + (60 * 60 * 1000 * 24)); // 1 day
        return {
            path: '/',
            expires: expiresDate
        }
    }
}
export default new CookieService();