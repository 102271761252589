import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import GlobalConstant from "../../../../GlobalConstant";
import api from "../AdminChatPage.data";
import { allRoomsAtom, currentStatusCaseAtom, newRoomsAtom, roomsCaseStatusAtom, showAssignAtom } from "../Recoil/AdminChatPageAtom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useCallback, useContext, useEffect, useState } from "react";
import { SocketContext } from "../../../../Services/Socket";
import SweetAlertUtil from "../../../../Containers/SweetAlertUtil";

const HeaderRoomChat = ({ guestId, activeRoom, onClickBack }) => {
    const socket = useContext(SocketContext);
    const disableStatus = useRecoilValue(showAssignAtom)
    const roomCaseStatus = useRecoilValue(roomsCaseStatusAtom)
    const [currentStatusCase, setCurrentStatusCase] = useRecoilState(currentStatusCaseAtom);
    const setAllrooms = useSetRecoilState(allRoomsAtom)
    const setNewRoom = useSetRecoilState(newRoomsAtom)
    const [nickName, setNickName] = useState("")
    const [editNickName, setEditNickName] = useState(false)

    useEffect(() => {
      activeRoom && setNickName(activeRoom.name)
      setEditNickName(false)
    }, [activeRoom])

    useEffect(() => {
      
      socket.on("update_case_status_room", updateCaseStatusCallBack);

      return () => {
        socket.off("update_case_status_room", updateCaseStatusCallBack);
      }
    }, [])

    const updateCaseStatusCallBack = useCallback((object) => {
      // console.log("updateCaseStatusCallBack: ", object);
      setAllrooms((arr) => {
        let old = arr.find((item) => item.id === object.room_id);
        let index = arr.indexOf(old);
        const newList = {
          ...old,
          case_status: object.case_status
        };
        // console.log("INDEX : ", index);
        return index !== -1 ? replaceRoom(arr, index, newList) : arr;
      });
    }, [])
    
    const replaceRoom = (list, i, newVal) => {
      return [...list.slice(0, i), newVal, ...list.slice(i + 1)];
    }

    const statusCaseClass = (id) => {
      let classStatus = "form-check-label ";
      if (id == 1) classStatus += "badge badge-pill badge-success";
      if (id == 2) classStatus += "badge badge-pill badge-info";
      if (id == 3) classStatus += "badge badge-pill badge-warning";
      if (id == 4) classStatus += "badge badge-pill badge-danger";
      return classStatus;
    }
    
    const statusCaseBtnClass = (id) => {
      let classStatus = "btn dropdown-toggle ";
      if (id == 1) classStatus += "btn-success";
      if (id == 2) classStatus += "btn-info";
      if (id == 3) classStatus += "btn-warning";
      if (id == 4) classStatus += "btn-danger";
      return classStatus;
    }

    const handleClickStatus = useCallback(async (event) => {
      let id = event.target.value;
      setCurrentStatusCase(id);
      const params = {
        room_id: activeRoom.room_id,
        status: id
      }
      const { status, data, errorText } = await api.onUpdateRoomCaseStatus(params);
      if (status) {
        toast("สำเร็จ");
  
        socket.emit("update_case_status_room", {
          room_id: activeRoom.room_id,
          case_status: data.case_status
        });
      }else {
        SweetAlertUtil.showAlert(GlobalConstant.ALERT_TYPE.ERROR, GlobalConstant.ERROR_TITLE, errorText);
      }
    }, [activeRoom])

    const handleClickEditName = useCallback(() => {
      SweetAlertUtil.showAlertWithConfirmButton("warning", GlobalConstant.WARNING_TITLE, "คุณต้องการแก้ไขชื่อ?")
      .then(async (ok) => {
        if(!ok) return

        const {status, errorText} = await api.onUpdateNickname({id: guestId, nickName})
        if (status) {
          setEditNickName(false)
          setAllrooms(pv => {
            let index = pv.findIndex(i=>i.id === activeRoom.room_id)
            if (index !== -1) {
              let newArr = [...pv]
              let old = pv[index]
              let newItem = {...old, name: nickName}
              newArr.splice(index, 1, newItem)
              return newArr
            }
            return pv
          })
          setNewRoom(pv => {
            let index = pv.findIndex(i=>i.id === activeRoom.room_id)
            if (index !== -1) {
              let newArr = [...pv]
              let old = pv[index]
              let newItem = {...old, name: nickName}
              newArr.splice(index, 1, newItem)
              return newArr
            }
            return pv
          })
        }else {
          SweetAlertUtil.showAlert("error", GlobalConstant.ERROR_TITLE, errorText)
        }
      })
    }, [guestId, nickName, activeRoom])

    return ( 
        <>
        <ToastContainer hideProgressBar={true} autoClose={2000} />

          <div className="d-flex flex-row justify-content-between bg-white py-2">
            <div className="d-flex flex-row align-items-center pl-2" >
              <div className="d-block d-md-none">
                <i
                  className="fa fa-chevron-left"
                  aria-hidden="true"
                  style={{
                    cursor: "pointer",
                    fontSize: "16pt",
                    padding: "4px 0px 0px 5px",
                  }}
                  onClick={onClickBack}
                ></i>
              </div>

              {!(editNickName) && <h4 className="m-0 ml-2">{nickName}</h4>}
              {!(editNickName) && 
                <button className=" btn btn-sm">
                  <i 
                    className="fas fa-edit" 
                    style={{ cursor: 'pointer' }} 
                    onClick={() => setEditNickName(true)}
                  ></i>
                </button>
              }
              {editNickName && 
                <div className="d-flex align-items-center">
                  <input type="text" className="form-control mr-1" defaultValue={nickName} onChange={(e) => setNickName(e.target.value)}/>
                  <button className="btn btn-sm btn-success" onClick={() => handleClickEditName()}>บันทึก</button>
                </div>
              }
            </div>

            {/* WEB */}
            <div className="d-none d-lg-block ml-md-2">
              {roomCaseStatus.length > 0 &&
                roomCaseStatus.map((item, index) => {
                  let checked = currentStatusCase == item.id;
                  let claseeLabel = " case-check-label " + (checked ? "checked" : "uncheck");

                  return (
                    <div key={item.id} className="form-check form-check-inline" >
                      <input
                        className="form-check-input status-case-radio"
                        type="radio"
                        id={`checkbox-${item.id}`}
                        value={item.id}
                        name="status"
                        defaultChecked={currentStatusCase == item.id}
                        disabled={disableStatus}
                        onClick={handleClickStatus}
                      />
                      <label className={statusCaseClass(item.id) + claseeLabel} htmlFor={`checkbox-${item.id}`} >
                        {item.name_th}
                      </label>
                    </div>
                  );
                })}
            </div>
            {/* END WEB */}

            {/* MOBILE */}
            <div className="d-block d-lg-none mr-2">
                <div className="btn-group">
                  <button type="button" className={statusCaseBtnClass(currentStatusCase)} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {currentStatusCase ? roomCaseStatus && roomCaseStatus.map((item) => 
                      (`${currentStatusCase == item.id ? item.name_th : ''}`)
                    ): "เลือกสถานะ"}
                  </button>
                  <div className="dropdown-menu">
                    {roomCaseStatus.length > 0 &&
                      roomCaseStatus.map((item, index) => {
                        let checked = currentStatusCase == item.id;
                        let claseeLabel = " case-check-label " + (checked ? "checked" : "uncheck");

                        return (
                          <div key={item.id} className="dropdown-item">
                            <div className="form-check form-check-inline" >
                              <input
                                className="form-check-input status-case-radio"
                                type="radio"
                                id={`checkbox-${item.id}`}
                                value={item.id}
                                name="status"
                                defaultChecked={currentStatusCase == item.id}
                                disabled={disableStatus}
                                onClick={handleClickStatus}
                              />
                              <label className={statusCaseClass(item.id) + claseeLabel} htmlFor={`checkbox-${item.id}`} >
                                {item.name_th}
                              </label>
                            </div>
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
            </div>
            {/* END MOBILE */}
          </div>
        </>
     );
}
 
export default HeaderRoomChat;