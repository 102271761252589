import { useCallback } from 'react'
import { useRecoilState } from 'recoil';
import { siteListState } from '../Dev/Recoil/siteState';
import APIService from "../Services/APIService";

const GET_SITES_PATH = "api/backend/site/list";

const useSiteList = () => {
    const [siteList, setSiteList] = useRecoilState(siteListState)

    const getSiteList = useCallback(async (searchText = '') => {
        const { body } = await APIService.get(GET_SITES_PATH + `?searchText=${searchText}`);
        if (body) {
            const siteList = body.sites || [];
            const list = siteList.map(item => {
                item.statusOptions = {
                    site_id: item.site_id,
                    key_name: item.key_name,
                    is_active: item.is_active,
                }
                item.settingOptions = {
                    domain: item.domain,
                    token_key: item.token_key,
                    key_name: item.key_name
                }
                return item
            })
            setSiteList(list);
        }
    }, [setSiteList])

    return { siteList, getSiteList, setSiteList }
}
export default useSiteList