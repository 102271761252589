const HOST_URL = "https://mezhat.com/";
const SERVER_URL = "https://helpchat-server.onrender.com/";
const SOCKET_URL = "https://helpchat-server.onrender.com/"; 


// const SOCKET_URL = "http://localhost:7707/";
// const SOCKET_URL = "http://chat-api.kdc-product.com/";
const GUEST_LOCAL_STORAGE_PATH = window.location.pathname + "_guest";
const ADMIN_LOCAL_STORAGE_PATH = window.location.pathname + "_admin";

// Google reCapchar
const CAPTCHAR_SECRET_KEY = "6LebZb0iAAAAAH2tfeOx34DAx2tF9CPrVpsrjPHa"
const CAPTCHAR_SITE_KEY = "6LebZb0iAAAAAF4d4bFBnftSt5ztk64FLyD7Ej3L"

export default {
    SERVER_URL,
    SOCKET_URL,
    HOST_URL,
    GUEST_LOCAL_STORAGE_PATH,
    ADMIN_LOCAL_STORAGE_PATH,
    CAPTCHAR_SECRET_KEY,
    CAPTCHAR_SITE_KEY
}