import { useRecoilState } from "recoil";
import {renewListAtom, renewPaginateAtom} from "../Dev/Recoil/renewAtom";
import APIService from "../Services/APIService";
const GET_RENEW_LIST = "api/backend/renew/list";

function useRenew() {
    const [renewList, setRenewList] = useRecoilState(renewListAtom);
    const [paginate, setPaginate] = useRecoilState(renewPaginateAtom);
    
    const getRenewList = async (companyId, status, page, size, orderBy, sortBy) => {
        let values = {
            companyId: companyId,
            status: status,
            page: page,
            size: size || 10,
            orderBy: orderBy || 'ASC',
            sortBy: sortBy
        }
        const {body} = await APIService.get(GET_RENEW_LIST, values);
        if (body) {
            // console.log(body);
            setRenewList(body.list || []);
            setPaginate(body.pagination || {});
        }
    };

    return {renewList, paginate, getRenewList, setRenewList};
}

export default useRenew;