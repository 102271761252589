import APIService from "../../../../Services/APIService";
import responseBody from "../../../../Services/ResponseBody";

const ADD_CATEGORY_PATH = "api/backend/site/category/add";
const UPDATE_CATEGORY_PATH = "api/backend/site/category/edit";

class CategoryData {
  async onAddCategory(params) {
    //console.log("== START ADD CATEGORY ==");
    let response = await APIService.post(ADD_CATEGORY_PATH, params);
    //console.log("== END ADD CATEGORY ==");
    let result = null;
    if (response && response.header.success === "Y") {
      result = responseBody(true, response.body);
    } else {
      result = responseBody(false, null, response.header.message);
    }
    return result;
  }
  async onEditCategory(params) {
    //console.log("== START UPDATE CATEGORY ==");
    let response = await APIService.post(UPDATE_CATEGORY_PATH, params);
    //console.log("== END UPDATE CATEGORY ==");
    let result = null;
    if (response && response.header.success === "Y") {
      result = responseBody(true, response.body);
    } else {
      result = responseBody(false, null, response.header.message);
    }
    return result;
  }
}

export default new CategoryData();
