import { atom } from "recoil";

const selectRoomAtom = atom({
  key: "selectRoom",
  default: {roomId: "", guestId: "", statusId: ""},
});

const contactLoadingAtom = atom({
  key: "contactLoading",
  default: true,
});

const newRoomsAtom = atom({
  key: "newRooms",
  default: [],
});

const allRoomsAtom = atom({
  key: "allRooms",
  default: [],
});

const roomsCaseStatusAtom = atom({
  key: "roomsCaseStatus",
  default: [],
});

const roomsStatusAtom = atom({
  key: "roomsStatus",
  default: [],
});

const activeGuestIdAtom = atom({
  key: "activeGuestId",
  default: null,
});

const activeRoomIdAtom = atom({
  key: "activeRoomId",
  default: null,
});

const searchTextAtom = atom({
  key: "searchText",
  default: "",
});

const textAtom = atom({
  key: "text",
  default: "",
});

const currentStatusCaseAtom = atom({
  key: "currentStatusCase",
  default: "",
});

const showInputAtom = atom({
  key: "showInput",
  default: false,
});

const showAssignAtom = atom({
  key: "showAssign",
  default: false,
});

const messagesAtom = atom({
  key: "messages",
  default: [],
});

export {
  selectRoomAtom,
  contactLoadingAtom,
  newRoomsAtom,
  allRoomsAtom,
  roomsCaseStatusAtom,
  roomsStatusAtom,
  activeGuestIdAtom,
  activeRoomIdAtom,
  searchTextAtom,
  textAtom,
  currentStatusCaseAtom,
  showInputAtom,
  showAssignAtom,
  messagesAtom
};
