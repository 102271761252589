function Promotionpm({pk, pm, isHot, isCurrent, handleClick}) {
  return (
    <div className="card promotion-card promotion-active mb-0 mx-md-3">
      {(isCurrent || isHot) && 
        <div className="ribbon-wrapper">
          <div className="ribbon bg-danger">
            {isCurrent ? pk.name_th : isHot && 'HOT'}
          </div>
        </div>
      }
      <div className="card-header text-center text-white">
        <h5>{pm.name_th}</h5>
        <p className="text-wrap text-white">{pm.desc_th}</p>
      </div>
      <div className="card-body text-center">
        <h2 className="bg-light rounded">{pm.package_id !== 0 ? '฿' + pm.prices : 'ทดลองใช้'}</h2>
        <ul className="p-0 my-3 text-white" style={{listStyleType: "none"}}>
          <li>
            <i className="nav-icon fa fa-check text-success mr-1"></i>
            {pk.site_limit} <span>Site Limit</span>
          </li>
          <li>
            <i className="nav-icon fa fa-check text-success mr-1"></i>
            {pk.operator_limit} <span>Operator Limit</span>
          </li>
          <li>
            <i className="nav-icon fa fa-check text-success mr-1"></i>
            {pm.period_days} <span>Period days</span>
          </li>
        </ul>

        <div className="my-4">
          <button
            type="button"
            className={`btn btn-${isCurrent ? 'danger' : 'primary'} btn-sm rounded-pill `}
            onClick={(e) => handleClick(pm)}
          >{isCurrent ? 'ต่ออายุแพ็คเกจ' : 'ซื้อแพ็คเกจ'}</button>
        </div>
      </div>
    </div>
  );
}

export default Promotionpm;