import React, { useEffect, useCallback } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import HomePage from "./Admin/Home/HomePage";
import PageNotFound from "./PageNotFound";
import AgentPage from "./Admin/Agent/Agent";
import AgentAddToSitePage from "./Admin/Agent/AgentAddToSite";
import AdminLayout from "../Containers/AdminLayout";
import ChangePasswordPage from "./Admin/ChangePassword/ChangePasswordPage";
import SitesPage from "./Admin/Sites/SitesPage";
import SiteSettingPage from "./Admin/Sites/SiteSetting";
import MemberManage from "./Admin/Member/MemberManage";
import ChatListPage from "./Admin/ChatList/ChatListPage";

import AuthenService from "../Services/AuthenService";
import { socket } from "../Services/Socket";
import ManualPage from "./Admin/Manual/ManualPage";

import { TableCell } from '@material-ui/core'

const oldRender = TableCell.render

TableCell.render = function (...args) {
    const [props, ...otherArgs] = args
    if (typeof props === 'object' && props && 'isEmpty' in props) {
        const { isEmpty, ...propsWithoutEmpty } = props
        return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs])
    } else {
        return oldRender.apply(this, args)
    }
}

const AdminRouters = (props) => {
  const { userid } = AuthenService.getAccessor();

  useEffect(() => {
    socket.on("connect", loginSocketCallBack);

    return () => {
      socket.off("connect", loginSocketCallBack);
      socket.close();
    };
  }, [socket]);

  const loginSocketCallBack = useCallback(() => {
    // console.log("socket connect ::", socket.id);
    const params = {
      userid: userid,
      is_staff: true,
    };
    socket.emit("status", params);
  }, []);

  return (
    <AdminLayout>
      <Switch>
        <Route path="/page/homepage" exact component={HomePage} />
        <Route path="/page/changepassword" exact component={ChangePasswordPage} />
        <Route path="/page/agent" exact component={AgentPage} />
        <Route path="/page/agent/manage/site/:agentId" exact component={AgentAddToSitePage} />
        <Route path="/page/sites" exact component={SitesPage} />
        <Route path="/page/sites/setting/:tokenKey" exact component={SiteSettingPage} />
            
        <Route path="/page/chat-list" exact component={ChatListPage} />

        <Route path="/page/member" exact component={MemberManage} />
        <Route path="/page/user-manual" exact component={ManualPage} />

        <Route path="/404" component={PageNotFound} />
        <Redirect to="/page/homepage" />
      </Switch>
    </AdminLayout>
  );
};
export default AdminRouters;
