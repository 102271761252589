import React, { useState, useEffect, useCallback, useRef } from "react";
import { useParams } from "react-router";
import { io } from "socket.io-client";
import config from "../../../Config";
import RegisterChat from "./RegisterChat";
import "./GuestChatPage.css";

import SyncLoader from "react-spinners/SyncLoader";
import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import { storageState } from "../../../Dev/Recoil/storageState";
import Swal from "../../../Containers/SweetAlertUtil";
import GlobalConstant from "../../../GlobalConstant";
import GuestChatPageData from "./GuestChatPage.data";
import LocalStorageUtil from "../../../Dev/LocalStorageUtil";
import Message from "./components/Message";
import { v4 } from "uuid";
import { hasLoadingAtom } from "./Recoil/guestAtom";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import Config from "../../../Config";
import AudioServices from "../../../Services/AudioServices";

function GuestChatPage() {
  const _messageDiv = document.querySelector(".messages");

  const { key } = useParams();

  const socket = useRef();
  const currentRoom = useRef();
  const [text, setText] = useState("");
  const [messages, setMessage] = useState([]);
  const [hasLoadingMsg, setHasLoadingMsg] = useRecoilState(hasLoadingAtom);
  const [adminState, setAdminState] = useState(null);
  const [guestState, setGuestState] = useState(null);
  const [configState, setConfigState] = useState({});
  const [readMsgIdState, setReadMsgIdState] = useState(null)

  const [isRegister, setIsRegister] = useState(false);
  const setDataFromStorage = useSetRecoilState(storageState);
  const refreshDataStoraage = useResetRecoilState(storageState);

  const [errorPackage, setErrorPackage] = useState({status: false, text: ""});

  useEffect(() => {
    messages && messages.length > 0 && _messageDiv && scrollToBottom();
  }, [messages]);

  function scrollToBottom() {
    _messageDiv.scrollTop = _messageDiv.scrollHeight;
  }

  useEffect(() => {
    window.addEventListener("message", receiveMessage);

    getSiteSettingByToken(key);

    startConnection();

    return () => {
      if (socket.current.connected) {
        socket.current.off("connect", socketConnectCallBack);
        socket.current.off("status", socketStatusCallBack);
        socket.current.off("message", messageReplyCallBack);
        socket.current.off("assign_room", assignRoomCallBack);
        socket.current.off("close_room", closeRoomCallback);
        socket.current.off("on_refresh_count_unread", readFlagCallback);
      }
    }
  }, [])

  async function receiveMessage(e) {
    if (
      e.data === "callHelpchat-onclick" ||
      e.message === "callHelpchat-onclick"
    ) {
      if (key != null && key != "") {
        await checkPackage(e.origin);
        getGuestFromStorage();
        // domain.current = e.origin;
        // getGuestFromStorage();
      }
    }
  }

  const startConnection = () => {
    if (socket.current != null) return;

    socket.current = io(config.SOCKET_URL);
    socket.current.on("connect", socketConnectCallBack);
    socket.current.on("status", socketStatusCallBack);
    socket.current.on("message", messageReplyCallBack);
    socket.current.on("assign_room", assignRoomCallBack);
    socket.current.on("close_room", closeRoomCallback);
    socket.current.on("on_refresh_count_unread", readFlagCallback);
  };

  const socketConnectCallBack = useCallback(() => {
    //console.log("socket connected = " + socket.current.id);
    loginSocket();
  }, []);

  const socketStatusCallBack = useCallback((objJson) => {
    //console.log("socketStatusCallBack : ", objJson);
    setAdminState((admin) => {
      if (admin) {
        let status = objJson.some(i=>i.userid === admin.id)
        return {...admin, status: status}
      }
    });
  }, []);

  const messageReplyCallBack = useCallback((objJson) => {
    //console.log("messageReplyCallBack : ", objJson);
    if (objJson.room_id === currentRoom.current) {
      AudioServices.playAudio();
      let values = {
        id: objJson.id,
        to: objJson.to,
        own_id: objJson.own_id,
        content: objJson.content,
        is_staff: objJson.is_staff,
        create_date: Date.now(),
      };
      setMessage((arr) => [...arr, values]);
    }
  }, []);

  const assignRoomCallBack = useCallback((objJson) => {
    //console.log("assignRoomCallBack", objJson);
    //console.log("current room ", currentRoom.current);
    if (objJson.room_id === currentRoom.current) {
      setAdminState((admin) => {
        return {
          ...admin, 
          id: objJson.own_id,
          full_name: objJson.owner_name
        };
      });
      
      let date = new Date();
      const newMessage = {
        id: objJson.id,
        room_id: objJson.room_id,
        own_id: objJson.own_id,
        is_staff: objJson.is_staff,
        content: objJson.content,
        create_date: date,
      };
      setMessage((arr) => [...arr, newMessage]);

      socket.current.emit('status', {});
    }
  }, []);

  const closeRoomCallback = useCallback(async (objJson) => {
    //console.log("closeRoomCallback", objJson);
    if (objJson.token_key === key && objJson.room_id === currentRoom.current) {
      handleCloseRoom();
    }
  }, []);

  const readFlagCallback = useCallback((objJson) => {
    if (objJson.token_key === key && objJson.room_id === currentRoom.current && objJson.is_staff) {
      setReadMsgIdState(objJson.read_msg_id);
    }
  }, [key]);

  const loginSocket = () => {
    const guest = LocalStorageUtil.getGuest();
    //console.log("LOGIN SOCKET : ", guest);
    if (guest) {
      const values = {
        userid: guest.guest_id,
        is_staff: false,
      };
      socket.current.emit("status", values);
    }
  }

  const getSiteSettingByToken = async (token_key) => {
    const {status, data, errorText} = await GuestChatPageData.onGetSiteSetting(token_key);
    if (status) {
      setConfigState(data.config);
    }else {
      Swal.showAlert(GlobalConstant.ALERT_TYPE.ERROR, GlobalConstant.ERROR_TITLE, errorText);
    }
  }

  const getGuestFromStorage = () => {
    const guest = LocalStorageUtil.getGuest();
    setGuestState(guest);
  }

  useEffect(() => {
    if (guestState) {
      const { room_id } = guestState

      if (room_id) {
        currentRoom.current = room_id;
        window.parent.postMessage("register-chat-onclick", "*");
        setIsRegister(true);
        setHasLoadingMsg(true);
        setTimeout(() => {
          getMsgByRoom(room_id);
        }, 1000);
      } else {
        setDataFromStorage(guestState);
        setIsRegister(false);
      }
    }

    return () => {}
  }, [guestState])
  
  const checkPackage = async (domain) => {
    if (domain === "") return;

    const { status, data } = await GuestChatPageData.onCheckPackage(domain, key);
    let isSuccess = data.success || false;
    if (status && !isSuccess) {
      let text = data.text || "";
      setErrorPackage({status: true, text: text});
      Swal.showAlertWithOutClose(GlobalConstant.ALERT_TYPE.ERROR, GlobalConstant.ERROR_TITLE, text).then(
        (ok) => {
          if (!ok) return;
          window.parent.postMessage("collap", "*");
        }
      );
    }else {
      setErrorPackage({status: false, text: ""});
    }
  }

  const getMsgByRoom = async (roomId) => {
    const param = { room_id: roomId };
    const { status, data, errorText } = await GuestChatPageData.onGetMsgByRoomId(param);
    setHasLoadingMsg(false);
    if (status) {
      let admin = data.admin || null;
      setAdminState(admin);
      let msg = data.messages || [];
      setMessage(msg);
      data.read_msg_id && setReadMsgIdState(data.read_msg_id);
    }else {
      Swal.showAlert(GlobalConstant.ALERT_TYPE.ERROR, GlobalConstant.ERROR_TITLE, errorText);
    }

    socket.current.emit('status', {})
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  const sendMessage = useCallback(() => {
    if (currentRoom.current != "" && !errorPackage.status && text != "") {
      let admin = LocalStorageUtil.getAdmin();
      if (!admin) admin = {};
      if (!admin.id) admin.id = null;

      let date = new Date();
      let values = {
        token_key: key,
        room_id: currentRoom.current,
        to: admin.id,
        own_id: guestState.guest_id,
        is_staff: false,
        content: text,
        read_flag: 0,
        create_date: date,
      };

      //console.log("message : ", values);
      socket.current.emit("message", values, handleClientReply);
    }
    
    errorPackage.status && Swal.showAlertWithOutClose(GlobalConstant.ALERT_TYPE.WARNING, GlobalConstant.WARNING_TITLE, errorPackage.text).then(
      (ok) => {
        if (!ok) return;
        window.parent.postMessage("collap", "*");
      }
    );
  }, [guestState, errorPackage, text]);

  const handleClientReply = (object) => {
    //console.log("handleClientReply : ", object);
    const {status, errors, data} = object;
    if (status) {
      const id = socket.id;
      const newMessage = {
        id: data.id,
        room_id: currentRoom.current,
        own_id: id,
        is_staff: false,
        content: data.content,
        create_date: Date.now()
      };
      setMessage((arr) => [...arr, newMessage]);
      setText("");
      setReadFlag();
    }
  }

  const setReadFlag = useCallback(() => {
    let value = {
      token_key: key,
      userid: guestState.guest_id,
      room_id: currentRoom.current,
      is_staff: false,
    };
    socket.current.emit("set_read_flag", value, (response) => {
      //console.log("set_read_flag : ", response);
      const { status, errorText } = response;
      if (status) {
        // getUnreadCount();
        // getUnreadCountSite();
      }else {
        Swal.showAlert(GlobalConstant.ALERT_TYPE.ERROR, GlobalConstant.ERROR_TITLE, errorText);
      }
    });
  }, [guestState, key, currentRoom.current, messages])

  const handleRegister = (params) => {
    if (params) {
      newRoom(params);
    }
  };

  const newRoom = (params) => {
    if ( (key !== "", params.guest_id !== "") ) {
      setHasLoadingMsg(true);
      setMessage([]);
      // let msg = `รายละเอียด:<br> ${detail} <br> <br> ชื่อ:<br> ${guest.name} <br> <br> อีเมลล์:<br>${guest.email}`;
      const param = {
        token_key: key,
        own_id: params.guest_id,
        name: params.name,
        // email: params.email,
        detail: params.detail,
        cate_id: params.cate_id,
        cate_name: params.cate_name,
        // content: msg,
        is_staff: false
      };
      socket.current.emit("new_room", param, handleNewRoom);
    }
  }

  const handleNewRoom = (params) => {
    const { status, data } = params;
    if (status) {
      //console.log("new_room = ", data);
      const newMessage = {
        id: v4(),
        room_id: data.id,
        own_id: data.own_id,
        is_staff: false,
        content: data.content,
        create_date: Date.now()
      };
      setMessage([newMessage]);

      updateGuestToStorage(data.id);
      setHasLoadingMsg(false);
      setIsRegister(true);
      window.parent.postMessage("register-chat-onclick", "*");
    }
  }

  function updateGuestToStorage(roomId) {
    currentRoom.current = roomId;
    const guest = LocalStorageUtil.getGuest();
    if (guest) {
      let newData = { ...guest, room_id: roomId };
      LocalStorageUtil.setGuestItem(newData);
      setGuestState(newData);
      const values = {
        userid: guest.guest_id,
        is_staff: false,
      };
      socket.current.emit("status", values);
    }
  }

  const onCloseChat = () => {
    Swal.showAlertWithConfirmButton(
      GlobalConstant.ALERT_TYPE.WARNING,
      GlobalConstant.WARNING_TITLE,
      "คุณต้องการปิดห้องสนทนาใช่หรือไม่"
    ).then(async (ok) => {
      if (ok) {
        const guest = LocalStorageUtil.getGuest();
        if (guest) {
          let params = {
            token_key: key,
            room_id: currentRoom.current,
            status_id: 3
          };
          socket.current.emit("close_room", params, (response) => {
            const {status} = response;
            if (status) {
              handleCloseRoom();
            }
          });
        }
      }
    });
  };

  const handleCloseRoom = () => {
    LocalStorageUtil.remove('guest')
    socket.current.disconnect()
    socket.current = null
    startConnection()

    setAdminState(null);
    refreshDataStoraage();
    window.parent.postMessage("collap", "*");
    setTimeout(() => {
      setIsRegister(false);
    }, 500);
  };

  return (
    <div className="client-chat-container" id="client-chat-container">
      <SyncLoader
        color={GlobalConstant.LOADING_COLOR}
        loading={hasLoadingMsg}
        css={{ ...GlobalConstant.LOADING_CSS, position: "absolute" }}
      />

      {isRegister === false ? (
        <GoogleReCaptchaProvider
          reCaptchaKey={Config.CAPTCHAR_SITE_KEY}
        >
          <RegisterChat 
            tokenKey={key} 
            handle={handleRegister} 
            socketId={socket.current && socket.current.id} 
            hasRegister={isRegister}
          />
        </GoogleReCaptchaProvider>
      ) : (
        <div>
          <nav 
            className="navbar" 
            style={{
              backgroundColor: configState.color ?? GlobalConstant.DEFUALT_COLOR, borderTopLeftRadius: "10px", borderTopRightRadius: "10px"
            }}
          >
            <span className="d-flex align-items-center navbar-brand mb-0 h1 text-white">
              <img src={configState.logo_full_path ?? GlobalConstant.LOGO_EMPTY_PATH} alt="logo" width='25' height='25'/> 
              <div>กระดานสนทนา</div>
            </span>
            <div className="row">
              <button
                className="btn"
                onClick={() => window.parent.postMessage("collap", "*")}
              >
                <i className="fas fa-minus text-white"></i>
              </button>

              <button className="btn" onClick={onCloseChat}>
                <i className="fas fa-times text-danger"></i>
              </button>
            </div>
          </nav>
          <div id="frame-guest" style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
            <div className="content-messages" style={{ width: "100%"}} >
              <div className="admin-contact p-2">
                <div className="text-center">{ adminState ? adminState.full_name : "กำลังรอเจ้าหน้าที่..." }</div>
                <span className={`status ${adminState && adminState.status ? 'status-online' : 'status-offline'}`}></span>
              </div>
              
              <div className="messages">
                <ul>
                  {messages && messages.length > 0 ? messages.map((m) => (
                    <Message key={m.id} message={m} readMsgId={readMsgIdState}/>
                  )) : null}
                </ul>
              </div>
              {!(errorPackage.status) &&
                <div className="message-input">
                  <div className="wrap">
                    <input
                      type="text"
                      placeholder="พิมพ์ข้อความ..."
                      value={text}
                      onClick={setReadFlag}
                      onChange={(e) => setText(e.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                    <button className="submit mr-1" onClick={sendMessage}>
                      <i className="fa fa-paper-plane" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default GuestChatPage;
