import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import "../../Css/Pagination.css";
import "./SitesPageCSS.css";

import ContentHeader from "../../ContentHeader";
import api from "./SitesPage.data";

import Modal from "../../../Components/Modal";
import Swal from "../../../Containers/SweetAlertUtil";
import GlobalConstant from "../../../GlobalConstant";
import MUIDataTable from "mui-datatables";
import useSiteList from "../../../hooks/useSiteList";
import ClipLoader from "react-spinners/ClipLoader";
import Config from "../../../Config";

const SitesPage = () => {
  const { register, handleSubmit, reset, clearErrors, formState: { errors } } = useForm();

  const [showModal, setShowModal] = useState(false);
  const {setSiteList} = useSiteList()
  const [searchText, setSearchText] = useState("")
  const [submitText, setSubmitText] = useState("")

  useEffect(() => {
    reset();
    clearErrors();
  }, [showModal])

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const onSubmit = (data) => {
    onAddSite(data);
  };

  function onAddSite(params) {
    Swal.showAlertWithConfirmButton(
      GlobalConstant.ALERT_TYPE.WARNING,
      GlobalConstant.WARNING_TITLE,
      `คุณต้องการเพิ่มเว็บไซต์ ${params.key_name} ใช่หรือไม่?`
    ).then(async (ok) => {
      if (ok) {
        const { status, data, errorText } = await api.addSite(params)
        if (status) {
          Swal.showAlertWithCallBack(
            GlobalConstant.ALERT_TYPE.SUCCESS,
            GlobalConstant.SUCCESS_TITLE,
            `เพิ่มเว็บไซต์ ${params.key_name} สำเร็จ`
          ).then(() => {
            const site = data.site || [];
            site.statusOptions = {
              site_id: site.site_id,
              key_name: site.key_name,
              is_active: 1,
            }
            site.settingOptions = {
              domain: site.domain,
              token_key: site.token_key,
              key_name: site.key_name
            }
            setSiteList((list) => [...list, site]);
          });
          closeModal();
        } else {
          Swal.showAlert(GlobalConstant.ALERT_TYPE.ERROR, GlobalConstant.ERROR_TITLE, errorText);
        }
      }
    });
  }
  
  useEffect(() => {
    setSubmitText(searchText);
  }, [searchText])

  const handleSearchText = () => {
    setSubmitText(searchText)
  }

  const handleClearSearchText = () => {
    setSearchText("")
    setSubmitText("")
  }

  return (
    <div>
      <ContentHeader
        title="จัดการเว็บไซต์"
        titleName="จัดการเว็บไซต์"
        backTitle="หน้าหลัก"
        backLink="/page/homepage"
      />

      <div className="content pb-3">
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">เว็บไซต์</h3>
              <div className="card-tools">
                <button type="button" className="btn btn-primary btn-block" onClick={openModal}>เพิ่มเว็บไซต์</button>
              </div>
            </div>
            <div className="card-body">
              <div className="form-group">
                <label htmlFor="searchText">ค้นหา (SITE NAME, DOMAIN)</label>
                <input type="text" className="form-control" name="searchText" id="searchText" placeholder="example, example.com" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
              </div>
              <div className="float-right">
                <button className="btn btn-sm btn-info mr-1" onClick={() => handleSearchText()}><i className="fas fa-search"></i> ค้นหา</button>
                <button className="btn btn-sm btn-dark" onClick={() => handleClearSearchText()}><i className="fas fa-redo-alt"></i> ล้างค่า</button>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <SitesTable searchText={submitText} />
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal
        modalId="createSite"
        show={showModal}
        title="เพิ่มเว็บไซต์"
        btnSuccessText="เพิ่ม"
        btnCancelText="ยกเลิก"
        handleSuccess={handleSubmit(onSubmit)}
        cancel={closeModal}
      >
        <form>
          <div className="form-group">
            <input
              className="form-control"
              readOnly={true}
              type="hidden"
              {...register("site_id")}
            />
          </div>

          <div className="form-group">
            <label htmlFor="domain">โดเมน <code>*</code></label>
            <input
              className={
                errors.domain ? "form-control is-invalid" : "form-control"
              }
              id="domain"
              placeholder="www.example.com"
              {...register("domain", {
                required: "Please enter domain",
                type: "text",
              })}
            />

            <ErrorMessage
              errors={errors}
              name="domain"
              render={({ message }) => (
                <span className="error invalid-feedback">{message}</span>
              )}
            />
          </div>

          <div className="form-group">
            <label htmlFor="key_name">ชื่อเว็บไซต์ <code>*</code></label>
            <input
              className={
                errors.key_name ? "form-control is-invalid" : "form-control"
              }
              id="key_name"
              {...register("key_name", {
                required: "Please enter site name",
                type: "text",
              })}
            />

            <ErrorMessage
              errors={errors}
              name="key_name"
              render={({ message }) => (
                <span className="error invalid-feedback">{message}</span>
              )}
            />
          </div>

          <div className="form-group">
            <label htmlFor="line_notify_token">Line Notifly Token</label>
            <input
              className={
                errors.line_notify_token
                  ? "form-control is-invalid"
                  : "form-control"
              }
              id="line_notify_token"
              {...register("line_notify_token", {
                // required: "Please enter line token",
                type: "text",
              })}
            />

            <ErrorMessage
              errors={errors}
              name="line_notify_token"
              render={({ message }) => (
                <span className="error invalid-feedback">{message}</span>
              )}
            />
          </div>
        </form>
      </Modal>
    </div>
  );
};

function ScriptModal({ domain, title, token }) {
  const textAreaRef = useRef(null);

  const copyCodeToClipboard = () => {
    textAreaRef.current.select();
    navigator.clipboard.writeText(textAreaRef.current.value);
  };

  const textAreaCSS = {
    resize: "none",
    textAlign: "start",
    wordWrap: "break-word",
    color: "rgb(142, 148, 154)",
  }

  return (
    <div className="modal fade" id={`siteToken-${token}`} role="dialog" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>
              Copy and paste this code before the closing {"</body>"} tag on every
              page of your website.
            </p>
            <textarea
              className="form-control"
              rows="4"
              readOnly={true}
              style={textAreaCSS}
              ref={textAreaRef}
              value={`<!-- HelpCaht Script -->\n<script src='${Config.HOST_URL}/livechat.js?key=${token}'></script>\n<!-- HelpCaht Script -->`}
            />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" className="btn btn-primary" onClick={() => copyCodeToClipboard()}>Copy</button>
          </div>
        </div>
      </div>
    </div>
  );
}

const SitesTable = ({ searchText }) => {

  const {siteList, getSiteList, setSiteList} = useSiteList()
  const [hasLoading, setHasLoading] = useState(false)

  useEffect(() => {
    const onGetSiteList = async () => {
      setSiteList([])
      setHasLoading(true)
      await getSiteList(searchText)
      setHasLoading(false)
    }
    onGetSiteList()
    return () => {
      setHasLoading(false)
    }
  }, [searchText]);
  

  const onChangeStatus = async (checked, item) => {
    let params = {
      status: checked ? 1 : 0,
      siteid: item.site_id,
    };
    const { status, data, errorText } = await api.changeStatus(params)
    if (status) {
      let site = data.site || {};
      const msg = `${site.is_active ? 'เปิด' : 'ปิด'} เว็บไซต์ "${item.key_name}" เรียบร้อยแล้ว`;
      site && Swal.showAlert(
        GlobalConstant.ALERT_TYPE.SUCCESS,
        GlobalConstant.SUCCESS_TITLE,
        msg
      );
    } else {
      Swal.showAlert(GlobalConstant.ALERT_TYPE.ERROR, GlobalConstant.ERROR_TITLE, errorText);
    }
  };

  const onDeleteSite = (token_key) => {
    Swal.showAlertWithConfirmButton(
      GlobalConstant.ALERT_TYPE.WARNING, 
      GlobalConstant.WARNING_TITLE, 
      "ยืนยันการทำรายการ?"
    ).then(async (ok) => {
      if(!ok){return}
      try {
        const response = await api.deleteSite({token_key});
        const {status, data, errorText} = response;
        if(status){
          const {site} = data;
          setSiteList((items)=>{
            let newArray = items.filter(obj => obj.token_key !== site.token_key);
            return newArray;
          });
          Swal.showAlert(GlobalConstant.ALERT_TYPE.SUCCESS, GlobalConstant.SUCCESS_TITLE);
        } else {
          Swal.showAlert(GlobalConstant.ALERT_TYPE.ERROR, GlobalConstant.ERROR_TITLE, errorText);
        }
      } catch (error) {
        Swal.showAlert(GlobalConstant.ALERT_TYPE.ERROR, GlobalConstant.ERROR_TITLE, error);
      }
    });
  };

  const columns = [
    {
      name: 'key_name',
      label: 'ชื่อเว็บไซต์',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <div className="px-3">
              {value}
            </div>
          )
        }
      }
    },
    {
      name: "domain",
      label: 'โดเมน',
      options: {
        filter: true,
        customBodyRender: (value) => {
          return (
            <div className="px-3">
              {value}
            </div>
          )
        }
      }
    },
    {
      name: "line_notify_token",
      label: 'LINE NOTIFY TOKEN',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <div className="text-center">
              {value ? <i className="fas fa-check-circle text-success"></i> : <i className="fas fa-times-circle text-danger"></i>}
            </div>
          )
        }
      }
    },
    {
      name: "statusOptions",
      label: 'สถานะ',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const { site_id, key_name, is_active } = value;
          const item = {
            site_id,
            key_name,
            is_active
          }
          return (
            <div className="text-center">
              <div className="form-group">
                <div className="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                  <input type="checkbox" className="custom-control-input" id={`customSwitch-${site_id}`} defaultChecked={is_active} onChange={(e) => onChangeStatus(e.target.checked, item)} />
                  <label className="custom-control-label" htmlFor={`customSwitch-${site_id}`}></label>
                </div>
              </div>
            </div>
          );
        }
      }
    },
    {
      name: "settingOptions",
      label: 'จัดการ',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const { domain, token_key, key_name } = value;
          return (
            <div className="d-flex justify-content-center">
              {/* Modal Token*/}
              <div>
                <button 
                  className="btn btn-warning mr-2" 
                  data-toggle="modal" 
                  data-target={`#siteToken-${token_key}`} 
                  data-backdrop="false"
                ><i className="fas fa-info-circle"></i> Script</button>

                <ScriptModal domain={domain} title={key_name} token={token_key} />
              </div>

              <Link to={`/page/sites/setting/${token_key}`}>
                <button className="btn btn-primary mr-2" >
                  <i className="fas fa-sliders-h mr-1"></i> ตั้งค่า
                </button>
              </Link>

              <button className="btn btn-danger" onClick={() => onDeleteSite(token_key)}>
                <i className="fas fa-trash-alt"></i>
              </button>
            </div>
          );
        }
      }
    },
  ]

  const options = {
    filter: true,
    filterType: "dropdown",
    selectableRows: "none",
    selectableRowsOnClick: false,
    print: false,
    download: false,
    onDownload: (buildHead, buildBody, columns, data) => {
      return "\uFEFF" + buildHead(columns) + buildBody(data);
    },
    textLabels: {
      body: {
          noMatch: hasLoading ? <ClipLoader loading={hasLoading} /> : 'Sorry, there is no matching data to display',
      },
    }
  };

  return (
    <div className="sites-table">
      <MUIDataTable
        data={siteList}
        columns={columns}
        options={options}
      />
    </div>
  );
}

export default SitesPage;
