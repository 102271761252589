import apiService from "../../../Services/APIService";
import responseBody from "../../../Services/ResponseBody";
import responseMsg from "../../../Services/ResponseBody";
const GET_MY_SITE_PATH = "api/backend/site/my-site";
const ADD_SITE_TO_AGENT_PATH = "api/backend/operator/add-site";
const LEAVE_SITE_PATH = "api/backend/operator/leave-site";

class AgentAddToSiteData {
  async getMySiteList(params) {
    //console.log("== START GET MY SITE ==");
    let response = await apiService.post(GET_MY_SITE_PATH, params);
    //console.log("== END GET MY SITE ==");
    let result = null;
    if (response && response.header.success === "Y") {
      result = responseBody(true, response.body);
    } else {
      result = responseBody(false, null, response.header.message);
    }
    return result;
  }
  async addSiteToAgent(params) {
    //console.log("== START ADD SITE TO AGENT ==");
    let response = await apiService.post(ADD_SITE_TO_AGENT_PATH, params);
    //console.log("== END ADD SITE TO AGENT ==");
    let result = null;
    if (response && response.header.success === "Y") {
      result = responseBody(true, response.body);
    } else {
      result = responseBody(false, null, response.header.message);
    }
    return result;
  }
  async leaveSite(params) {
    //console.log("== START LEAVE SITE ==");
    let response = await apiService.post(LEAVE_SITE_PATH, params);
    //console.log("== END LEAVE SITE ==");
    let result = null;
    if (response && response.header.success === "Y") {
      result = responseBody(true, response.body);
    } else {
      result = responseBody(false, null, response.header.message);
    }
    return result;
  }
}

export default new AgentAddToSiteData();
