import { useCallback } from "react";
import { useRecoilState } from "recoil";
import SweetAlertUtil from "../Containers/SweetAlertUtil";
import { allRoomState, newRoomState } from "../Dev/Recoil/roomState";
import GlobalConstant from "../GlobalConstant";
import { roomsCaseStatusAtom, roomsStatusAtom } from "../Pages/Admin/AdminChatPage/Recoil/AdminChatPageAtom";
import APIService from "../Services/APIService";

const GET_ROOM_LIST = "api/backend/chat-room-list";

const useRoomList = () => {
    const [allRoom, setAllRoom] = useRecoilState(allRoomState)
    const [newRoom, setNewRoom] = useRecoilState(newRoomState)
    const [roomCaseStatus, setRoomCaseStatus] = useRecoilState(roomsCaseStatusAtom)
    const [roomStatus, setRoomStatus] = useRecoilState(roomsStatusAtom)

    const getRoomList = useCallback(async(params)=>{
        //call api 
        // console.log("params: ",params);
        const { status, data, errorText }  = await onGetRoomList(params);
        //set state
        if(status){
            const {rooms, room_case_status, room_status} = data;
            setRoomCaseStatus(room_case_status);
            setRoomStatus(room_status);
            
            const {all_room, new_room} = rooms;
            setAllRoom(all_room);
            // setNewRoom(new_room);
        }else {
            SweetAlertUtil.showAlert(GlobalConstant.ALERT_TYPE.ERROR, GlobalConstant.ERROR_TITLE, "", errorText);
        }
      }, []);

      async function onGetRoomList(params) {
        // console.log("### Get Room List ###");
        let url = GET_ROOM_LIST + "?tokenKey=" + params.tokenKey;
        let response = await APIService.post(url,params);  
        let res = {
            status: false,
            data: null,
            errorText: null
        };
        if (response && response.header.success === "Y") {
            res.status = true;
        } else {
            res.status = false;
            res.errorText = response.header.message;
        }
        res.data = response.body || null;
        return res;                                                        
    };

    return {allRoom, newRoom, getRoomList}
}
export default useRoomList;