function RenewItem({renew, onCancel}) {

    const cardClasses = () => {
        if (renew.status_id === 1) return "bg-warning";
        if (renew.status_id === 2) return "bg-success";
        return "bg-danger";
    };

  return (
    <div className="card mx-md-3">
      <div className={"card-header text-center " + cardClasses()}>
        <h5>
          {renew.name_th}
          {renew.status_id === 1 && 
            (
                <span className="float-right">
                <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={() => onCancel(renew.id)}
                >
                    <span aria-hidden="true">×</span>
                </button>
                </span>
            )
          }
        </h5>
      </div>
      <div className="card-body text-center">
        <div className="p-2">
          <h5>฿{renew.package_prices}</h5>
        </div>

        <div className="dropdown-divider"></div>

        <div className="p-2">
          <h5>{renew.period_days} วัน</h5>
        </div>

        <div className="dropdown-divider"></div>

        <div className="p-2">
          <h5>ร้องขอ</h5>
          <h5>{renew.createdate}</h5>
        </div>
      </div>
      <div className={"d-block text-center p-2 " + cardClasses()} >
        {
            renew.status_id === 1 ? "รอการยืนยัน" : 
            renew.status_id === 2 ? "สำเร็จ" : "ยกเลิก"
        }
      </div>
    </div>
  );
}

export default RenewItem;
