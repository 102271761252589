import React from "react";


import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import Config from "../Config";
import RegisterForm from "./RegisterForm";

function RegisterPage() {

  return (
    <>
      <div className="hold-transition register-page">
        <div className="register-box">
          <div className="register-logo">
            {/* <a href="../../index2.html"> */}
            <b>Help</b>Chat
            {/* </a> */}
          </div>
          <GoogleReCaptchaProvider
            reCaptchaKey={Config.CAPTCHAR_SITE_KEY}
          >
            <RegisterForm />
          </GoogleReCaptchaProvider>
        </div>
      </div>
    </>
  );
}

export default RegisterPage;
