import axios from 'axios';
import config from '../Config';

const url_register = config.SERVER_URL + 'api/public/admin-register';

class RegisterService {
    async register(dataRegister) {
        let response = await axios.post(url_register, dataRegister);
        return response;
    }
}

export default new RegisterService();