import React, { useState, useEffect, useCallback, useContext } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { SyncLoader } from "react-spinners";
import { allRoomsAtom, contactLoadingAtom, messagesAtom, newRoomsAtom, roomsCaseStatusAtom, roomsStatusAtom, selectRoomAtom, showAssignAtom, showInputAtom } from "../Recoil/AdminChatPageAtom";
import GlobalConstant from "../../../../GlobalConstant";
import MyRooms from "./MyRooms";
import Modal from "../../../../Components/Modal";
import api from "../AdminChatPage.data";
import AuthenService from "../../../../Services/AuthenService";
import SweetAlertUtil from "../../../../Containers/SweetAlertUtil";
import { SocketContext } from "../../../../Services/Socket";
import useCountRoom from "../../../../hooks/useCountRoom";
import dateutility from "../../../../Dev/dateutility";
import '../AdminChatPageCss.css';
import useRoomList from "../../../../hooks/useRoomList";

const moment = require('moment');

const SideBar = ({tokenKey, onClickRoom, isActiveRoom}) => {
    const socket = useContext(SocketContext);

    const [onlineUsers, setOnlineUsers] = useState([]);
    const [contactLoading, setContactLoading] = useRecoilState(contactLoadingAtom)
    const [newRooms, setNewRooms] = useRecoilState(newRoomsAtom)
    const [allRooms, setAllRooms] = useRecoilState(allRoomsAtom)
    const [roomCaseStatus, setRoomCaseStatus] = useRecoilState(roomsCaseStatusAtom)
    const [roomStatus, setRoomStatus] = useRecoilState(roomsStatusAtom)
    const [filterStatusList, setFilterStatusList] = useState({ statusOnline: "", filterStatus: [], onlyMyRoom: false, roomStatus: 'all' })
    const selectRoom = useRecoilValue(selectRoomAtom)

    const [searchText, setSearchText] = useState("")
    const [searchRoom, setSearchRoom] = useState(null)
    const [showModalFilter, setShowModalFilter] = useState(false)
    const setShowInput = useSetRecoilState(showInputAtom)
    const setShowAssign = useSetRecoilState(showAssignAtom)
    const setMessage = useSetRecoilState(messagesAtom)
    
    const {unreadRoom, getRoomUnread} = useCountRoom()
    const {allRoom, newRoom, getRoomList} = useRoomList()

    const { userid } = AuthenService.getAccessor();

    useEffect(() => {
        document.querySelector("#all").click();
        document.querySelector("#filterRoomStatus-all").click();
        
        socket.on("status", socketStatusCallBack);
        socket.on("new_room", newRoomCallBack);
        socket.on("assign_room", assignRoomCallBack);
        socket.on("close_room", closeRoomCallBack);

        return () => {
          socket.off("status", socketStatusCallBack);
          socket.off("new_room", newRoomCallBack);
          socket.off("assign_room", assignRoomCallBack);
          socket.off("close_room", closeRoomCallBack);

          clearScreen()
          setContactLoading(true)
        }
    }, [])

    const clearScreen = () => {
      setSearchRoom(null)
      setNewRooms([])
      setAllRooms([])
    }

    const socketStatusCallBack = useCallback((objJson) => {
      // console.log("socketStatusCallBack : ", objJson);
      setOnlineUsers(objJson);
      //Sort First User Online 
      filterRoomOnline(objJson);
    }, []);


    function filterRoomOnline (objJson) {
      setAllRooms((rooms) => {
        let userOnlines = [];
        let userOfflines = [];
        for (let i = 0; i < rooms.length; i++) {
            let userOnline = objJson.filter(element => element.userid == rooms[i].guest_id);
          if(userOnline.length > 0){
            userOnlines.push(rooms[i]);
          }else{
            userOfflines.push(rooms[i]);
          }
      }
        return [...userOnlines, ...userOfflines]
      });
    }

    const newRoomCallBack = useCallback((object) => {
      //console.log("new room : ", object);
      if (object.token_key === tokenKey) {
        getRoomListFunc();
        getRoomUnread(tokenKey);
      }
    }, []);

    const assignRoomCallBack = useCallback((object) => {
      //console.log("assignRoomCallBack : ", object);
      if (object && object.token_key === tokenKey) {
        setAllRooms((rooms) => {
          return rooms.filter(item => item.id !== object.room_id)
        })
        getRoomListFunc();
        getRoomUnread(tokenKey);
  
        let values = {
          id: object.id,
          room_id: object.room_id,
          own_id: object.own_id,
          is_staff: object.is_staff,
          content: object.content,
          create_date_text: dateutility.formatFullDateTimeThaiShortMonth(new Date()),
        };
        setMessage((item) => [...item, values]);

        setShowAssign(false);
        setShowInput(true);
      }
    }, []);

    const closeRoomCallBack = useCallback((objJson) => {
      //console.log("closeRoomCallBack : ", objJson);
      if (objJson.token_key === tokenKey) {
        getRoomListFunc();
        setShowInput(false);
        setShowAssign(false);
      }
    }, []);
    
    useEffect(() => {
        console.log('Hook filterStatusList', tokenKey);
        const onGetRoom = async () => {
          setContactLoading(true);
          clearScreen()
          await getRoomListFunc();
          getRoomUnread(tokenKey);
          setContactLoading(false);
        }
        onGetRoom()
    }, [tokenKey, filterStatusList])

    useEffect(() => {
      //set show last action time all room chat
      // console.log("allRoom===>", allRoom);
      let allRooms = JSON.parse(JSON.stringify(allRoom));
      if(allRooms){
        for(let i = 0; i < allRooms.length; i++){
          let allRoomObj = allRooms[i];
          if(allRoomObj){
            allRoomObj.showLastActiontime = getShowLastActionTime(allRoomObj.last_action_at);
          }
        }
      }

      let _allRooms = allRooms;
      let tempFilterRoom = [];
      if (filterStatusList.statusOnline != 'all' && filterStatusList.statusOnline != '') {
        //console.log("statusFilter ", filterStatusList.statusOnline);
        for (let i = 0; i < _allRooms.length; i++) {
          let userOnline = onlineUsers.filter(element => element.userid == _allRooms[i].guest_id);
          if (filterStatusList.statusOnline == 'offline' && userOnline.length == 0) {
            tempFilterRoom.push(_allRooms[i]);
          }
          if (filterStatusList.statusOnline == 'online' && userOnline.length > 0) {
            tempFilterRoom.push(_allRooms[i]);
          }

        }
        setSearchRoom(tempFilterRoom);
      } else {
        setSearchRoom(null);
        setAllRooms(_allRooms);
      }
    }, [allRoom])

    // useEffect(() => {
    //   // console.log("newRoom===>", newRoom);
    //   //set show last action time new room chat
    //   let newRooms = JSON.parse(JSON.stringify(newRoom));
    //   if(newRooms){
    //     for(let i = 0; i < newRooms.length; i++){
    //       let newRoomObj = newRooms[i];
    //       if(newRoomObj){
    //         newRoomObj.showLastActiontime = getShowLastActionTime(newRoomObj.last_action_at);
    //       }
    //     }
    //   }
    //   setNewRooms(newRooms);
    // }, [newRoom])

    const getRoomListFunc = useCallback(async () => {
      
        let params = {
          tokenKey: tokenKey,
          filter: filterStatusList,
          staffid: userid,
        }

        await getRoomList(params);

    },[tokenKey, filterStatusList, allRooms])

    //show update date or time chat
    const getShowLastActionTime = (updateTime) => {
      if(!updateTime) return '';
      var a = moment(new Date());
      var b = moment(updateTime);
      const dif = a.diff(b, 'days');
      let time = ''
      if(dif <= 0){
        time = moment(updateTime).format("HH:mm");
      } else if(dif <= 7) {
        time = moment(updateTime).format("dddd");
      }else{
        time = moment(updateTime).format("DD/MM/YYYY");
      }
      return time;
    } 
  
    const searchUser = useCallback(() => {
        if (searchText !== "") {
          let myRoomsFilter = filterItems(searchText, allRooms);
          setSearchRoom(myRoomsFilter);
        } else {
          setSearchRoom(null);
        }
    }, [searchText, allRooms])

    const filterItems = (needle, heystack) => {
        let query = needle.toLowerCase();
        return heystack.filter(
          (item) => item.name.toLowerCase().indexOf(query) >= 0
        );
    };

    const setFilterSearch = () => {

        console.log(roomCaseStatus);
        setShowModalFilter(true);
    
    }

    const searhFilter = () => {
        let statusCaseListSelect = document.querySelectorAll("input[name='filterStatusCase']");
        setFilterStatusList([]);
        let temp = {
          statusOnline: "",
          filterStatus: [],
          onlyMyRoom: false,
          roomStatus:'all'
        };
        for (let i = 0; i < statusCaseListSelect.length; i++) {
          let element = document.querySelectorAll("input[name='filterStatusCase']")[i];
          if (element.checked) {
            let value = element.getAttribute("val");
            temp.filterStatus.push(value);
          }
        }
        // status user
        temp.statusOnline = document.querySelector("input[type=radio][name=filterStatusOnline]:checked").value;
        temp.roomStatus = document.querySelector("input[type=radio][name=filterRoomStatus]:checked").value;
    
        temp.onlyMyRoom = document.querySelector("#onlyMyRoom").checked;
        setFilterStatusList(temp);
        setShowModalFilter(false);
    
        //console.log("searhFilter!!!!!!!!!!", filterStatusList);
    
    }

    const closeModalFilter = () => {
        setShowModalFilter(false);
        //console.log("CloseModal", showModalFilter);
    }

    const handleCloseRoom = useCallback((id) => {
      SweetAlertUtil.showAlertWithConfirmButton(
        GlobalConstant.ALERT_TYPE.WARNING,
        GlobalConstant.WARNING_TITLE,
        "คุณต้องการปิดห้องสนทนาใช่หรือไม่"
      ).then(async (ok) => {
        if (!ok) return

        let params = {
          token_key: tokenKey,
          room_id: id,
          status_id: 4
        };
        socket.emit("close_room", params, async (response) => {
          // //console.log("CLOSE ROOM : ", response);
          const {status} = response;
          if (status) {
            await getRoomListFunc()
          }
        });
      });
    }, [tokenKey])
  
    return (
      <>
      {/* SIDE BAR */}
      <div id="sidepanel" className={isActiveRoom ? 'none-display-chat' : 'block-display-chat'}>
  
        <SyncLoader
          color={GlobalConstant.LOADING_COLOR}
          loading={contactLoading}
          css={GlobalConstant.LOADING_CSS}
        />
    
        <div className="d-flex flex-row justify-content-between">
          <label id="headtitle" className="label-control">สนทนา</label>
          <button style={{ background: 'none', border: '0', color: "#fff" }}
            onClick={(e) => setFilterSearch()}
          >
            <i className="fa fa-filter" aria-hidden="true" title="คัดกรอง"></i>
          </button>
        </div>
    
        {/* SEARCH */}
        <div id="search">
          <label htmlFor="true">
            <i className="fa fa-search" aria-hidden="true" />
          </label>
          <input
            type="text"
            placeholder="ค้นหา ผู้สนทนา..."
            onKeyUp={searchUser}
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
          />
        </div>
        {/* END SEARCH */}
    
        {/* CONTACT */}
        <div id="contacts">
          <div id="accordion" className={contactLoading ? 'd-none' : 'd-block'}>
            {/* Top fix 24/01/2023 <button
              className="btn btn-default btn-block btn-nav-accordion text-left mb-1 chat-groups"
              data-toggle="collapse"
              data-target="#new_rooms"
              aria-expanded={newRooms && newRooms.length <= 0 ? "false" : "true"}
              aria-controls="new_rooms"
            >
              <h6 className="mb-0" style={{ fontSize: '15px' }}>
                ห้องสนทนาใหม่{" "}
                {`(${newRooms && newRooms.length > 0 ? newRooms.length : 0})`}
                <i className="fas fa-angle-left float-right"></i>
              </h6>
            </button>
            
            <div
              id="new_rooms"
              className={newRooms && newRooms.length <= 0 ? "collapse" : "collapse show"}
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <ul>{newRooms && newRooms.length > 0 ? newRooms.map((r) => (
                <MyRooms 
                  key={r.id} 
                  activeRoom={selectRoom} 
                  room={r}
                  onlineUsers={onlineUsers} 
                  unreads={unreadRoom}  
                  handleSelect={onClickRoom} 
                />
              )) : null}</ul>
            </div> */}
    
            <button
              className="btn btn-default btn-block btn-nav-accordion text-left mb-1 chat-groups"
              data-toggle="collapse"
              data-target="#all_rooms"
              aria-expanded={ allRooms.length <= 0 ? "false" : "true" }
              aria-controls="all_rooms"
            >
              <h6 className="mb-0" style={{ fontSize: '15px' }}>
                ห้องสนทนาทั้งหมด{" "}
                {`(${searchRoom ? searchRoom.length : allRooms.length})`}
                <i className="fas fa-angle-left float-right"></i>
              </h6>
            </button>
    
            <div
              id="all_rooms"
              className={ allRooms.length <= 0 ? "collapse" : "collapse show" }
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <ul>
                  {searchRoom ? searchRoom.map( item => (
                      <MyRooms 
                        key={item.id} 
                        activeRoom={selectRoom} 
                        room={item} 
                        onlineUsers={onlineUsers} 
                        unreads={unreadRoom} 
                        handleSelect={onClickRoom} 
                        handleClose={handleCloseRoom}
                      />
                  )) : 
                  allRooms.map((r) => (
                      <MyRooms 
                        key={r.id} 
                        activeRoom={selectRoom} 
                        room={r} 
                        onlineUsers={onlineUsers} 
                        unreads={unreadRoom} 
                        handleSelect={onClickRoom} 
                        handleClose={handleCloseRoom}
                      />
                  ))
                  }
              </ul>
            </div>
          </div>
        </div>
        {/* END CONTACT */}
      </div>
      {/* END SIDE BAR */}

      <Modal
        modalId={"filter-modal"}
        title={"กรองการค้นหา"}
        show={showModalFilter}
        handleSuccess={searhFilter}
        cancel={closeModalFilter}
      >
        {
            <>
              <div className="float-left">
                <h5>สถานะเคส</h5>
                <span>
                  {roomCaseStatus && roomCaseStatus.map((item) => {
                    let classStatus = "form-check-label ";
                    if (item.id == 1) classStatus += 'badge badge-pill badge-success';
                    if (item.id == 2) classStatus += 'badge badge-pill badge-info';
                    if (item.id == 3) classStatus += 'badge badge-pill badge-warning';
                    if (item.id == 4) classStatus += 'badge badge-pill badge-danger';
                    return (
                      <React.Fragment key={item.id}>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input " type="checkbox" val={`${item.id}`} id={`checkboxFilter-${item.id}`} name="filterStatusCase" />
                          <label className={classStatus} style={{ fontSize: "14x" }} htmlFor={`checkboxFilter-${item.id}`}>{item.name_th}</label>
                        </div>
                        <br></br>
                      </React.Fragment>
                    );
                  })}
                </span>
                <h5>สถานะห้อง</h5>
                <span>
                  {roomStatus && roomStatus.map((item) => {
                    let classStatus = "form-check-label ";
                    return (
                      <React.Fragment key={item.id}>
                        <div className="form-check form-check-inline">
                          <input type="radio" id={`filterRoomStatus-${item.id}`} name={`filterRoomStatus`} value={`${item.id}`} />
                          <label className="form-check-label" htmlFor={`filterRoomStatus-${item.id}`} style={{ marginLeft: "5px" }}>{item.name_th}</label><br></br>
                        </div>
                        <br></br>
                      </React.Fragment>
                    );
                  })}
                 <div className="form-check form-check-inline">
                  <input type="radio" id={`filterRoomStatus-all`} name={`filterRoomStatus`} value='all' />
                  <label className="form-check-label" htmlFor={`filterRoomStatus-all`} style={{ marginLeft: "5px" }}>ทั้งหมด</label><br></br>
                </div>
                <br></br>
                </span>
                <h5 style={{ marginTop: "10px" }}>สถานะผู้ใช้งาน</h5>
                <span >
                  <input type="radio" id="online" name="filterStatusOnline" value="online" />
                  <label className="form-check-label" htmlFor="online" style={{ marginLeft: "5px" }}>ออนไลน์</label><br></br>
                  <input type="radio" id="offline" name="filterStatusOnline" value="offline" />
                  <label className="form-check-label" htmlFor="offline" style={{ marginLeft: "5px" }}>ออฟไลน์</label><br></br>
                  <input type="radio" id="all" name="filterStatusOnline" value="all" />
                  <label className="form-check-label" htmlFor="all" style={{ marginLeft: "5px" }}>ทั้งหมด</label><br></br>
                </span>

                <h5 style={{ marginTop: "10px" }}>แสดงเฉพาะห้องของฉัน</h5>
                <span>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" val="onlyMyRoom" id='onlyMyRoom' name="onlyMyRoom" />
                    <label className="form-check-label" htmlFor='onlyMyRoom'>ห้องของฉัน</label>
                  </div>
                </span>
              </div>
              <br></br>
            </>
        }
      </Modal>
      </>
    );
  }

  export default SideBar;