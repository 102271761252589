import React, { useState, useEffect, useRef, useCallback } from "react";
import ContentHeader from "../../ContentHeader";
import MUIDataTable from "mui-datatables";
import { MultiSelect } from "react-multi-select-component";
import { SyncLoader } from "react-spinners";
import GlobalConstant from "../../../GlobalConstant";
import SweetAlertUtil from "../../../Containers/SweetAlertUtil";
import ChatListPageData from "./ChatListPage.data";
import DatePicker from "react-datepicker";
import moment from "moment";
import Modal from "../../../Components/Modal";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import "react-datepicker/dist/react-datepicker.css";
import "./ChatListPage.css";
import useSiteList from "../../../hooks/useSiteList";
import useTicketCategory from "../../../hooks/useTicketCategory";

const cssLoading = {
  display: "flex",
  width: "100%",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
};

const color = "#666666";

const now = new Date();
// Get the start of the month
const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
// Get the end of the month
const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

const chatStatusState = atom({
  key: "chatStatus",
  default: [],
});

const ChatListPage = () => {
  const [loading, setLoading] = useState(false);
  const [chatList, setChatList] = useState([]);
  const [defaultSite] = useState("");
  const [defaultFromDate] = useState(startOfMonth);
  const [defaultToDate] = useState(endOfMonth);
  const [fromDate, setFromDate] = useState(startOfMonth);
  const [toDate, setToDate] = useState(endOfMonth);
  const [site, setSite] = useState("");
  const [cate, setCate] = useState("");
  const [name, SetName] = useState("");
  const [isShowModal, setIsShowModal] = useState(false);
  const [modalRoomDetail, setModalRoomDetail] = useState("");
  const [modalMessageList, setModalMessageList] = useState([]);
  const [statusRoomList, setStatusRoomList] = useState([]);
  const [selected, setSelected] = useState([]);

  const { siteList, getSiteList } = useSiteList();
  const { cateList, getCateList } = useTicketCategory();

  const setChatStatus = useSetRecoilState(chatStatusState);

  // ** Function
  const convertDateFormat = useCallback((date) => {
    if (!date) return "";
    return moment(date).format("YYYY-MM-DD");
  }, []);

  const onGetSiteList = useCallback(async () => {
    try {
      await getSiteList("");
    } catch (error) {
      console.log("onGetSiteList err =>", error);
    }
  }, [getSiteList]);

  const onGetCateList = useCallback(async () => {
    try {
      await getCateList("");
    } catch (error) {
      console.log("onGetCateList err =>", error);
    }
  }, [getCateList]);

  const onGetRoomStatusList = useCallback(async () => {
    try {
      let roomStatusList = await ChatListPageData.onGetStatusRoom();
      setStatusRoomList(roomStatusList.data.status);
    } catch (error) {
      console.log("onGetCateList err =>", error);
    }
  }, []);

  useEffect(() => {
    onGetSiteList();
    onGetCateList();
    onGetRoomStatusList();
  }, [onGetSiteList, onGetCateList, onGetRoomStatusList]);

  const getChatList = useCallback(
    async (params) => {
      setLoading(true);
      const { status, data, errorText } = await ChatListPageData.onGetChatList(
        params
      );
      if (status) {
        //console.log("<<<<< Chat list : ", data);
        const { datas } = data;
        if (datas.chat_list) {
          let i = 1;
          datas.chat_list.map((e) => {
            e.no = i;
            i++;
            return e;
          });
          setChatList(datas.chat_list);
        }
        if (datas.status_master) {
          datas.status_master.map((e) => {
            let color_tags = "danger";
            switch (e.id) {
              case 1:
                color_tags = "danger";
                break;
              case 2:
                color_tags = "warning";
                break;
              case 3:
                color_tags = "secondary";
                break;
              case 9:
                color_tags = "success";
                break;
              default:
                color_tags = "danger";
                break;
            }
            e.color = color_tags;
            return e;
          });
          setChatStatus(datas.status_master);
        }
      } else {
        SweetAlertUtil.showAlert(
          "error",
          GlobalConstant.ERROR_TITLE,
          "",
          errorText
        );
      }
      setLoading(false);
    },
    [setChatStatus]
  );

  const onSearchFilter = useCallback(async () => {
    let statusFilter = [];
    selected.forEach((element) => {
      statusFilter.push(element.value);
    });

    const p = {
      site: site,
      cate: cate,
      from: convertDateFormat(fromDate),
      to: convertDateFormat(toDate),
      status: statusFilter,
      name: name,
    };
    getChatList(p);
  }, [
    cate,
    name,
    selected,
    site,
    fromDate,
    toDate,
    getChatList,
    convertDateFormat,
  ]);

  const onFilterByName = useCallback(async (p) => {
    // console.log(p);
    SetName(p);
  }, []);

  const onFilterByFromDate = useCallback(
    async (p) => {
      setFromDate(p);
      setSelected([]);

      const params = {
        site: site,
        cate: cate,
        from: convertDateFormat(p),
        to: convertDateFormat(toDate),
        status: [],
        name: name,
      };
      getChatList(params);
    },
    [cate, name, site, toDate, getChatList, convertDateFormat]
  );

  const onFilterByToDate = useCallback(
    async (p) => {
      setToDate(p);
      setSelected([]);

      const params = {
        site: site,
        cate: cate,
        from: convertDateFormat(fromDate),
        to: convertDateFormat(p),
        status: [],
        name: name,
      };
      getChatList(params);
    },
    [cate, name, site, fromDate, getChatList, convertDateFormat]
  );

  const onFilterBySite = useCallback(
    async (p) => {
      setSite(p);
      setSelected([]);

      const params = {
        site: p,
        cate: cate,
        from: convertDateFormat(fromDate),
        to: convertDateFormat(toDate),
        status: [],
        name: name,
      };
      getChatList(params);
    },
    [cate, name, fromDate, toDate, getChatList, convertDateFormat]
  );

  const onFilterByCate = useCallback(
    async (p) => {
      setCate(p);
      setSelected([]);

      const params = {
        site: site,
        cate: p,
        from: convertDateFormat(fromDate),
        to: convertDateFormat(toDate),
        status: [],
        name: name,
      };
      getChatList(params);
    },
    [site, name, fromDate, toDate, getChatList, convertDateFormat]
  );

  const onClearFilter = useCallback(async () => {
    setSite(defaultSite);
    setCate("");
    setSelected([]);
    setFromDate(defaultFromDate);
    setToDate(defaultToDate);
    SetName("");
    const p = {
      site: defaultSite,
      cate: "",
      from: convertDateFormat(defaultFromDate),
      to: convertDateFormat(defaultToDate),
      status: [],
      name: "",
    };
    getChatList(p);
  }, [
    defaultSite,
    defaultFromDate,
    defaultToDate,
    getChatList,
    convertDateFormat,
  ]);

  const getMessageList = useCallback(async (params) => {
    //console.log("params",params);
    setLoading(true);
    const { status, data, errorText } = await ChatListPageData.onGetMessageList(
      params
    );
    if (status) {
      //console.log("<<<<< Message list : ", data);
      const { datas } = data;
      if (datas) {
        const { message_list, room_detail } = datas;
        let title = {
          room_detail: room_detail,
          titleName: params.name,
        };
        setModalRoomDetail(title);
        setModalMessageList(message_list);
      }
    } else {
      SweetAlertUtil.showAlert(
        "error",
        GlobalConstant.ERROR_TITLE,
        "",
        errorText
      );
    }
    setLoading(false);
  }, []);

  const toggleModal = useCallback(() => {
    setIsShowModal(!isShowModal);
  }, [isShowModal]);

  const openMessageListInfoModal = useCallback(
    async (value, name) => {
      //console.log("value: ", value);
      setModalRoomDetail("");
      setModalMessageList([]);
      getMessageList({ room_id: value, name: name });
      toggleModal();
    },
    [getMessageList, toggleModal]
  );

  const successMessageListInfoModal = useCallback(() => {
    // document.getElementById(`btn_modal_messageListInfo`).click();
    setIsShowModal(false);
  }, []);

  const closeMessageListInfoModal = useCallback(() => {
    setIsShowModal(false);
  }, []);

  useEffect(() => {
    if (defaultFromDate && defaultToDate) {
      onSearchFilter();
    }
  }, [defaultFromDate, defaultToDate, onSearchFilter]);

  return (
    <div>
      <ContentHeader
        title="Chat History"
        titleName="Chat History"
        backTitle="หน้าหลัก"
        backLink="/"
      />

      <div className="content">
        <div className="container-fluid">
          <SearchCriteria
            statusRoomList={statusRoomList}
            siteList={siteList}
            formDateValue={fromDate}
            toDateValue={toDate}
            siteValue={site}
            cateList={cateList}
            cateValue={cate}
            onFromDate={onFilterByFromDate}
            onToDate={onFilterByToDate}
            onSiteBy={onFilterBySite}
            onCateBy={onFilterByCate}
            onName={onFilterByName}
            onSearch={onSearchFilter}
            onClear={onClearFilter}
            setSelected={setSelected}
            selected={selected}
          />
          <SyncLoader color={color} loading={loading} css={cssLoading} />
          <ChatListTable
            datas={chatList}
            show={isShowModal}
            handleOpenModal={openMessageListInfoModal}
          />
          <MessageList
            show={isShowModal}
            titleDetail={modalRoomDetail}
            messages={modalMessageList}
            handleSuccess={successMessageListInfoModal}
            handleCancel={closeMessageListInfoModal}
          />
        </div>
      </div>
    </div>
  );
};

const SearchCriteria = ({
  statusRoomList,
  siteList,
  formDateValue,
  toDateValue,
  siteValue,
  cateList,
  cateValue,
  onFromDate,
  onToDate,
  onSiteBy,
  onCateBy,
  onName,
  onSearch,
  onClear,
  setSelected,
  selected,
}) => {
  const _form = useRef(null);

  const options = [];

  statusRoomList.forEach((element) => {
    options.push({ label: element.name_th, value: element.id });
  });

  const siteListData = siteList || [];

  const cateListData = cateList || [];

  const clear = () => {
    _form.current.reset();
    onClear();
  };

  return (
    <>
      <div className="content">
        <div className="container-fluid">
          <div className="card">
            {/* <div className='card-header'>
                <h5>เกณฑ์การค้นหา</h5>
              </div> */}
            <div className="card-body">
              <form ref={_form}>
                <div className="row">
                  <div className="col-sm-12 col-md-12">
                    <div className="row">
                      <label className="col-xs-4 col-md-3 col-lg-2 text-left text-md-right">
                        ชื่อเว็บไซต์:
                      </label>
                      <div className="col-xs-8 col-md-9 col-lg-4">
                        <select
                          className="form-control form-control-sm"
                          style={{ width: "100%" }}
                          aria-hidden="true"
                          onChange={(e) => onSiteBy(e.target.value)}
                          value={siteValue}
                        >
                          <option value="">-ทั้งหมด-</option>

                          {siteListData && siteListData.length > 0
                            ? siteListData.map((item, index) => {
                                return (
                                  <option key={index} value={item.site_id}>
                                    {item.key_name}
                                  </option>
                                );
                              })
                            : null}
                        </select>
                      </div>
                      <label className="col-xs-4 col-md-3 col-lg-2 text-left text-md-right">
                        ประเภท:
                      </label>
                      <div className="col-xs-8 col-md-9 col-lg-4">
                        <select
                          className="form-control form-control-sm"
                          style={{ width: "100%" }}
                          aria-hidden="true"
                          onChange={(e) => onCateBy(e.target.value)}
                          value={cateValue}
                        >
                          <option value="">-ทั้งหมด-</option>

                          {cateListData && cateListData.length > 0
                            ? cateListData.map((item, index) => {
                                return (
                                  <option key={index} value={item.id}>
                                    {item.cate_name}
                                  </option>
                                );
                              })
                            : null}
                        </select>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <label className="col-xs-4 col-md-3 col-lg-2 text-left text-md-right">
                        วันที่:
                      </label>
                      <div className="col-xs-8 col-md-9 col-lg-4">
                        <DatePicker
                          className="form-control form-control-sm text-center"
                          dateFormat="dd/MM/yyyy"
                          selected={formDateValue}
                          onChange={(value) => onFromDate(value)}
                        />
                      </div>
                      <label className="col-xs-4 col-md-3 col-lg-2 text-left text-md-right">
                        ถึง:
                      </label>
                      <div className="col-xs-8 col-md-9 col-lg-4">
                        <DatePicker
                          className="form-control form-control-sm text-center"
                          dateFormat="dd/MM/yyyy"
                          selected={toDateValue}
                          onChange={(value) => onToDate(value)}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <label className="col-xs-4 col-md-3 col-lg-2 text-left text-md-right">
                        ชื่อ
                      </label>
                      <div className="col-xs-8 col-md-9 col-lg-4">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="searchName"
                          id="searchName"
                          onBlur={(e) => onName(e.target.value)}
                        />
                      </div>
                      <label className="col-xs-4 col-md-3 col-lg-2 text-left text-md-right">
                        สถานะ
                      </label>
                      <div className="col-xs-8 col-md-9 col-lg-4">
                        <MultiSelect
                          hasSelectAll={true}
                          className="small"
                          options={options}
                          value={selected}
                          onChange={setSelected}
                          labelledBy="Select"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-row mt-3 justify-content-end">
                  <button
                    type="button"
                    className="btn btn-info btn-sm"
                    onClick={onSearch}
                  >
                    <i className="fas fa-search"></i> ค้นหา
                  </button>
                  <div>&nbsp;</div>
                  <button
                    type="reset"
                    className="btn btn-dark btn-sm"
                    onClick={clear}
                  >
                    <i className="fas fa-redo-alt"></i> ล้างค่า
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ChatListTable = ({ datas, handleOpenModal }) => {

  const onViewInfo = useCallback((value, name)=>{
    //console.log("data: ",value);
    //console.log("name: ",name);
    handleOpenModal(value, name);
  }, [handleOpenModal])

  const columns = [
    {
      name: "no",
      label: "#",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "chat_create_date",
      label: "วันที่สร้างรายการ",
      options: {
        filter: false,
      },
    },
    {
      name: "key_name",
      label: "ชื่อเว็บไซต์",
      options: {
        filter: true,
      },
    },
    {
      name: "domain",
      label: "เว็บไซต์",
      options: {
        filter: true,
      },
    },
    {
      name: "namenickname",
      label: "ผู้สนทนา",
      options: {
        filter: false,
      },
    },
    {
      name: "cate_name",
      label: "ประเภท",
      options: {
        filter: true,
      },
    },
    // {
    //   name: "email",
    //   label: "อีเมล",
    //   options: {
    //     filter: false
    //   }
    // },
    {
      name: "detail",
      label: "รายละเอียด",
      options: {
        filter: false,
      },
    },
    {
      name: "rs_name_th",
      label: "สถานะ",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <StatusTags value={value} />;
        },
      },
    },
    {
      name: "id",
      label: "การกระทำ",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              className="btn btn-info btn-sm"
              onClick={() => onViewInfo(value, tableMeta.rowData[4])}
            >
              ดูข้อมูล
            </button>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    filterType: "dropdown",
    selectableRows: "none",
    selectableRowsOnClick: false,
    print: false,
    onDownload: (buildHead, buildBody, columns, data) => {
      return "\uFEFF" + buildHead(columns) + buildBody(data);
    },
  };

  return (
    <MUIDataTable
      title="รายการ"
      data={datas}
      columns={columns}
      options={options}
    />
  );
};

function MessageList({
  show,
  titleDetail,
  messages,
  handleSuccess,
  handleCancel,
}) {
  const [title] = useState("Message List");

  return (
    <Modal
      modalId="messageListInfo"
      show={show}
      title={title}
      btnSuccessText="ตกลง"
      handleSuccess={handleSuccess}
      cancel={handleCancel}
    >
      <p>ชื่อลูกค้า : {titleDetail.titleName}</p>
      <p>รายละเอียด : {titleDetail.room_detail}</p>
      <div className="d-flex flex-row justify-content-end">
        <span className="mr-1">
          <i className="fa fa-circle text-info" aria-hidden="true"></i>
          เจ้าหน้าที่
        </span>
        <span>
          <i className="fa fa-circle text-warning" aria-hidden="true"></i>
          ลูกค้า
        </span>
      </div>
      <div className="row">
        <div className="col-md-12">
          {/* The time line */}
          <div className="timeline">
            {messages &&
              messages.length > 0 &&
              messages.map((i, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="time-label">
                      <span className="bg-red">{i.message_date}</span>
                    </div>
                    {i.messages &&
                      i.messages.length > 0 &&
                      i.messages.map((j, num) => {
                        return (
                          <div key={`child${num}`}>
                            <i className="fas fa-comments bg-green" />
                            <div
                              className={`timeline-item ${
                                j.is_staff === 1 ? "bg-info" : "bg-warning"
                              }`}
                            >
                              <span
                                className={`time ${
                                  j.is_staff === 1 ? "text-light" : "text-dark"
                                }`}
                              >
                                <i className="fas fa-clock" /> {j.chat_time}
                              </span>
                              <h3
                                className={`timeline-header no-border ${
                                  j.is_staff === 1 ? "text-light" : "text-dark"
                                }`}
                              >
                                <b>{j.person_name} :</b>{" "}
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: j.content,
                                  }}
                                />
                              </h3>
                            </div>
                          </div>
                        );
                      })}
                  </React.Fragment>
                );
              })}
            <div>
              <i className="fas fa-clock bg-gray" />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const StatusTags = (props) => {
  const Status = useRecoilValue(chatStatusState) || [];
  const data = Status.find((e) => e.name_th === props.value);
  return data ? (
    <span key={data.id} className={`badge badge-${data.color}`}>
      {data.name_th}
    </span>
  ) : (
    <div />
  );
};

export default ChatListPage;
