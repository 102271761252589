import apiService from "../../../Services/APIService";
import responseBody from "../../../Services/ResponseBody";
const ASSIGN_ROOM_PATH = "api/backend/chat-assign-room";
const GET_ROOM_LIST = "api/backend/chat-room-list";
const GET_MSG_BY_ROOM = "api/backend/chat-msg-byroom";
const GET_STATUS_ROOM = "api/backend/chat-room-status-list";
const GET_UPDATE_STATUS_ROOM = "api/backend/chat-update-case-status-room";
const UPDATE_NICKNAME = "api/backend/chat-room/update/nickname";

class AdminChatPage {
  async onGetMsgByRoom(data) {
    let response = await apiService.post(GET_MSG_BY_ROOM, data);
    if (response && response.header.success === "Y") {
      let res = responseBody(true, response.body);
      return res;
    } else {
      let res = responseBody(false, null, response.header.message);
      return res;
    }
  }
  assignRoom(params) {
    return new Promise(async (success, reject) => {
      let response = await apiService.post(ASSIGN_ROOM_PATH, params);
      if (response && response.header.success === "Y") {
        let res = responseBody(true, response.body);
        success(res);
      } else {
        let res = responseBody(false, null, response.header.message);
        success(res);
      }
    });
  }
  //Top Fix 24/01/2023
  // async onGetRoomList(params) {
  //   let url = GET_ROOM_LIST + "?tokenKey=" + params.tokenKey;
  //   let response = await apiService.post(url,params);
  //   let result = null;
  //   if (response && response.header.success === "Y") {
  //     result = responseBody(true, response.body);
  //   } else {
  //     result = responseBody(false, null, response.header.message);
  //   }
  //   return result;
  // }
  async onGetStatusRoom() {
    let response = await apiService.get(GET_STATUS_ROOM);
    let result = null;
    if (response && response.header.success === "Y") {
      result = responseBody(true, response.body);
    } else {
      result = responseBody(false, null, response.header.message);
    }
    return result;
  }
  async onUpdateRoomCaseStatus(params) {
    let response = await apiService.post(GET_UPDATE_STATUS_ROOM, params);
    let result = null;
    if (response && response.header.success === "Y") {
      result = responseBody(true, response.body);
    } else {
      result = responseBody(false, null, response.header.message);
    }
    return result;
  }
  async onUpdateNickname(params) {
    let response = await apiService.post(UPDATE_NICKNAME, params);
    let result = null;
    if (response && response.header.success === "Y") {
      result = responseBody(true, response.body);
    } else {
      result = responseBody(false, null, response.header.message);
    }
    return result;
  }
}

export default new AdminChatPage();
