import APIService from "../../../Services/APIService";
import responseBody from "../../../Services/ResponseBody";

const UPLOAD_AVATAR = "api/backend/upload-avatar";

class HomePageData {
  async onUploadAvatar(formData) {
    //console.log("== START UPLOAD AVATAR ==");
    const headers = {
      headers: { "Content-Type": "multipart/form-data;" },
    }
    let response = await APIService.post(UPLOAD_AVATAR, formData, headers);
    //console.log("== END UPLOAD AVATAR ==");
    let result = null;
    if (response && response.header.success === "Y") {
      result = responseBody(true, response.body);
    } else {
      result = responseBody(false, null, response.header.message);
    }
    return result;
  }
}

export default new HomePageData;