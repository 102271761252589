import { SyncLoader } from "react-spinners";
import GlobalConstant from "../../../../GlobalConstant";
import '../AdminChatPageCss.css';

const MessageContent = ({ children, msgIsLoading, isActiveRoom }) => {
    return ( 
        <div className={`content-messages ${isActiveRoom ? 'block-display-chat' : 'none-display-chat'}`}>
          <SyncLoader 
            color={GlobalConstant.LOADING_COLOR} 
            loading={msgIsLoading} 
            css={GlobalConstant.LOADING_CSS} 
          />
          {!(isActiveRoom) &&
            <div className="d-flex flex-row justify-content-center align-items-center"
              style={{ height: '100%', fontSize: '13px', color: '#727171' }}>
              <label>กรุณาเลือกห้องสนทนาของคุณ</label>
            </div>
          }
          {isActiveRoom && children}
        </div>
      );
}
export default MessageContent;