import axios from 'axios';
//import oauth from 'axios-oauth-client';
import qs from 'qs';
import config from '../Config';
import cookieService from '../Services/CookieService';
const url_login = config.SERVER_URL + 'api/auth/login';
const url_grant = config.SERVER_URL + 'oauth/token';
const CREDENTIALS = 'credentials';
class AuthenService {
    async userLogin(dataLogin){
        try{
            let response = await axios.post(url_login, dataLogin);
            //console.log("response", response);
            const data = response.data;
            if(data){
                //console.log("LOGIN_DATA", data);
                if('Y' === data.success && data.accessToken && data.user){
                    let credentials = {
                        ...data
                    }
                    this.handleTokenStore(credentials);
                }else{
                    return {is_log: false, error: data.errorText};
               }
            }
            return {is_log: true, data: data} || {is_log: false, error: "Not Found data!"};
        }catch(error){
            //console.log("LOGIN_ERROR", error);
            return {is_log: false, error: error};
        }
    }
    async grantPassword(clientToken, username, password){
        try{
            const getOwnerCredentials = await axios({
                method: 'post',
                url: url_grant,
                data:qs.stringify({
                    "grant_type": 'password',
                    username: username,
                    password: password
                }),
            headers:{
                Authorization: clientToken,
                "Content-Type": "application/x-www-form-urlencoded",
            } 
            });
            return getOwnerCredentials;
        }catch(error){
            console.log('GRANT_ERROR', error);
        }
        return false;
    }
    async refreshToken(){
        try{
            //console.log("refreshToken");
            const credentials = this.getCredentials();
            const refreshToken = credentials.refreshToken;
            const clientToken = credentials.clientToken;
            const newToken = await axios({
                method: 'post',
                url: url_grant,
                data:qs.stringify({
                    grant_type: 'refresh_token',
                    refresh_token: refreshToken
                }),
            headers:{
                Authorization: clientToken,
                "Content-Type": "application/x-www-form-urlencoded",
            } 
            });
            //console.log("newToken",newToken);
            if(newToken && newToken.data && newToken.data.accessToken && newToken.data.user){
                const credentials = newToken.data;
                credentials.clientToken = clientToken;
                this.handleTokenStore(credentials);
                return newToken;
            }
            
        }catch(error){
            console.log('refresh_token_error', error);
            // refreshtokenisexpired
            this.userLogout();
            alert('Unauthorized');
            window.location.replace('/login');
        }
        return false;
    }
    getAccessor(){
        const cred = this.getCredentials();
        if(cred) return cred.user;
        return false;
    }
    userLogout(){
        cookieService.remove(CREDENTIALS);
        //console.log("Logout " + CREDENTIALS, cookieService.get(CREDENTIALS));
    }
    handleTokenStore(credentials){
        cookieService.set(CREDENTIALS, credentials, {path:'/'});
    }
    isAuthen(){
        let cred = cookieService.get(CREDENTIALS);
        //console.log("CheckAuthen", cred);
        if(cred && cred.accessToken && cred.user && cred.user.userid){
            return true;
        }else{
            return false;
        }
    }
    getCredentials(){
        let cred = cookieService.get(CREDENTIALS,{path:"/"});
        // console.log(CREDENTIALS, cred);

        return cred;
    }
}

export default new AuthenService();