import React from 'react'

function PageNotFound(){

    return (
        <>
            
            <div className="content">
                <div className="container-fluid">
                    Page Not Found / 4040
                </div>
            </div>
        </>
    );
}
export default PageNotFound;