import React, { useState, useEffect, useCallback } from "react";
import ReactPaginate from "react-paginate";
import SweetAlertUtil from "../../../Containers/SweetAlertUtil";
import GlobalConstant from "../../../GlobalConstant";
import useUserInfo from "../../../hooks/useUserInfo";
import useRenew from "../../../hooks/useRenew";
import ContentHeader from "../../ContentHeader";
import PromotionItem from "./components/PromotionItem";
import RenewItem from "./components/RenewItem";
import MemberManageData from "./MemberManage.data";
import usePackageList from "../../../hooks/usePackageList";
import useMember from "../../../hooks/useMember";
import './Member.css'
import Image from "../../../Components/Image";

const MemberPlanPage = (props) => {

  const size = 10;

  const filterMemberList = [
    {name: "ทั้งหมด", value: ''},
    {name: "กำลังใช้งาน", value: 1},
    {name: "หมดอายุ", value: '-1'}
  ];

  const filterRenewList = [
    {name: "ทั้งหมด", value: ''},
    {name: "รอการยืนยัน", value: '1'},
    {name: "สำเร็จ", value: '2'},
    {name: "ยกเลิก", value: '3'}
  ];

  const {renewList, paginate, getRenewList, setRenewList} = useRenew();
  const [status, setStatus] = useState(0);
  const {userInfo, getUserInfo} = useUserInfo();
  const {packageList, getPackageList,lastPackage,getLastPackage} = usePackageList();
  const {memberDetail, getMemberDetail} = useMember();
  
  useEffect(() => {
    getUserInfo();
    getPackageList(1, size, 'ASC', 'seqno');
    // getLastPackage();
  }, []);

  useEffect(() => {
    userInfo && getMemberDetail(userInfo.company);
  }, [userInfo]);

  useEffect(() => {
    userInfo && getRenewList(userInfo.company, status, 1, size);
  }, [userInfo, status]);

  const handleMemberFilter = async (value) => {
    // console.log(value);
  };

  const handleRenewFilter = async (status) => {
    setStatus(status);
  };

  const handleRenew = async (item) => {
    let values = {
      userId: userInfo.userid,
      companyId: userInfo.company,
      packageId: item.package_id,
      promotionId: item.id
    }
    const {status, data, errorText} = await MemberManageData.onCreateRenew(values);
    if (!status) return SweetAlertUtil.showAlert(GlobalConstant.ALERT_TYPE.ERROR, GlobalConstant.ERROR_TITLE, errorText);

    if (data) {
      SweetAlertUtil.showAlert(GlobalConstant.ALERT_TYPE.SUCCESS, GlobalConstant.SUCCESS_TITLE, "");
      getRenewList(userInfo.company, 0, 1, size);
    }
  };

  const handleShowModal = useCallback((item) => {
    SweetAlertUtil.showAlertWithConfirmButton(GlobalConstant.ALERT_TYPE.WARNING, GlobalConstant.WARNING_TITLE, "ยืนยันการทำรายการ")
    .then((ok) => {
      if (!ok) return;
      handleRenew(item);
    });
  }, [userInfo]);

  const handelCancelRenew = (id) => {
    // console.log(id);
    SweetAlertUtil.showAlertWithConfirmButton(GlobalConstant.ALERT_TYPE.WARNING, GlobalConstant.WARNING_TITLE, "คุณต้องการยกเลิกรายการขอต่ออายุ?")
    .then(
      async (ok) => {
        if (!ok) return;

        const {status, data, errorText} = await MemberManageData.onCancelRenew(id);
        if (status) {
          const {success} = data;
          if (success) {
            SweetAlertUtil.showAlert(GlobalConstant.ALERT_TYPE.SUCCESS, GlobalConstant.SUCCESS_TITLE, "ยกเลิกรายการขอต่ออายุเรียบร้อยแล้ว");

            setRenewList(pv => {
              return pv.filter(i => i.id != id);
            });
          }
        }else {
          SweetAlertUtil.showAlert(GlobalConstant.ALERT_TYPE.ERROR, GlobalConstant.ERROR_TITLE, errorText);
        }
      }
    );
  };

  const handlePageClick = useCallback(async (e) => {
    let selectedPage = parseInt(e.selected) + 1;
    await getRenewList(userInfo.company, status, selectedPage, size);
  }, [userInfo, status]);

  return (
    <div>
      <ContentHeader
        title="จัดการแพ็คเกจ"
        titleName="จัดการแพ็คเกจ"
        backTitle="Home"
        backLink="/"
      />

      <div className="content">
      {/* {lastPackage.length > 0 &&
          lastPackage.map(pk => {
            return(
              <div className="container-fluid" key={pk.id}>
                <div className="badge badge-pill badge-primary mb-4" data-toggle="collapse" href="#packageDetail" role="button" style={{fontSize: 18}}>ต่ออายุแพคเก็ต</div>
                <div className="collapse" id="packageDetail">
                  <div className="card card-body">
                    {pk.desc_th}
                  </div>
                </div>

                <div className={`horizontal-scrollable ${pk.promotions.length <= 3 && 'd-sm-flex justify-content-sm-center'} py-5`}>
                  {pk.promotions.length > 0 ? 
                    pk.promotions.map((pm) => ( 
                      <div className="col-sm-4 col-lg-3" key={pm.id}>
                        <PromotionItem pk={pk} item={pm} handleClick={() => handleShowModal(pm)}/>
                      </div>
                    ))
                    :
                    <div className="text-center">ไม่มีรายการ</div>
                  }
                </div>
              </div>
          )})
        } */}
        { memberDetail &&
          <div className="container-fluid">
            <div className="row justify-content-lg-center">
              <div className="col-lg-8">
                <div className="card">
                  <div className="card-header">
                    <h5>แพ็คเกจปัจจุบัน</h5>
                  </div>
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col-md-5">
                        <PromotionItem pk={memberDetail.package} pm={memberDetail.package.promotion} isCurrent={true} handleClick={() => handleShowModal(memberDetail.package.promotion)}/> 
                      </div>

                      <div className="col-md-7 text-center">
                        <Image className="profile-user-img img-fluid img-circle mt-4 mt-md-0 mb-4" key="profile" src="profile" fallback={GlobalConstant.AVATAR_EMPTY_PATH}/>
                        <h4>{memberDetail.firstname} {memberDetail.lastname}</h4>
                        <div>{memberDetail.email}</div>
                        <div>{memberDetail.mobile}</div>
                        <div className="badge badge-danger mr-0">
                          <div className="d-flex flex-column">
                            <div className="mb-1">หมดอายุ</div>
                            <div>{memberDetail.expire_dt}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {packageList.length > 0 &&
          packageList.map(pk => {
            return(
              <div className="container-fluid" key={pk.id}>
                <div className="badge badge-pill badge-primary mb-4" data-toggle="collapse" href="#packageDetail" role="button" style={{fontSize: 18}}>รายการแพ็คเกจ {pk.name_th}</div>
                <div className="collapse" id="packageDetail">
                  <div className="card card-body">
                    {pk.desc_th}
                  </div>
                </div>

                <div className={`horizontal-scrollable ${pk.promotions.length <= 3 && 'd-sm-flex justify-content-sm-center'} py-5`}>
                  {pk.promotions.length > 0 ? 
                    pk.promotions.map((pm) => ( 
                      <div className="col-sm-4 col-lg-3" key={pm.id}>
                        <PromotionItem pk={pk} pm={pm} isCurrent={false} handleClick={() => handleShowModal(pm)}/>
                      </div>
                    ))
                    :
                    <div className="text-center">ไม่มีรายการ</div>
                  }
                </div>
              </div>
          )})
        }

        <div className="dropdown-divider mt-5"></div>

        <div className="container-fluid position-relative">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <div className="badge badge-pill badge-warning" style={{fontSize: 18}}>รายการขอต่ออายุ</div>
            <div className="form-group m-0">
              <select className="form-control" onChange={(e) => handleRenewFilter(e.target.value)}>
                {filterRenewList.map((item, index) => (
                  <option key={index} value={item.value}>{item.name}</option>
                ))}
              </select>
            </div>
          </div>
          
          <div className="row row-cols-1 row-cols-md-3 row-cols-lg-5 justify-content-center px-3 px-sm-0">
            {renewList.length > 0 ? 
              renewList.map((item) => ( <RenewItem key={item.id} renew={item} onCancel={() => handelCancelRenew(item.id)}/> ))
              :
              <div className="text-center">ไม่มีรายการ</div>
            }
          </div>

          <div className="d-flex justify-content-end">
            {paginate.currentCount > 0 &&
              <ReactPaginate
                previousLabel={"prev"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={paginate.pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination pagination-sm"}
                subContainerClassName={"page-item"}
                activeClassName={"active"}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberPlanPage;
