import React, { useState, useEffect } from 'react'
// import PropTypes from 'prop-types'
import ContentHeader from '../../ContentHeader'
import ColorPicker from '../../../Components/ColorPicker';
import GlobalConstant from '../../../GlobalConstant';
import SiteSettingData from './SiteSetting.data';

// import Modal from "../../../Components/Modal";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import SweetAlertUtil from '../../../Containers/SweetAlertUtil';
import Catagory from './components/Catagory';

import { useSetRecoilState } from "recoil";
import { categoryListAtom } from './Recoil/categoryListAtom';
import { showAddCategoryAtom } from './Recoil/showAddCategoryAtom';
import Image from '../../../Components/Image';

function SiteSettingPage(props) {
    const {tokenKey} = props.match.params;
    const [selectLogo, setSelectLogo] = useState(undefined);
    const [logoState, setLogoState] = useState(undefined);
    const [colorState, setColorState] = useState("");
    const [siteDetailState, setSiteDetailState] = useState({});
    const [hasShowLineToken, setHasShowLineToken] = useState(false);
    const [redioState, setRedioState] = useState("BR");

    const setCategoryList = useSetRecoilState(categoryListAtom)
    const setShowAddCategory = useSetRecoilState(showAddCategoryAtom)
    const handleOpenAddModal = () => setShowAddCategory(true)

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    useEffect(() => {
        const getSiteDetail = async () => {
          const {status, data, errorText} = await SiteSettingData.onGetSiteSetting(tokenKey);
          if (status) {
            let site = data.site || {};
            setSiteDetailState(site);
            setDataToForm(site);

            const category = data.category || [];
            setCategoryList(category);
          }else {
            SweetAlertUtil.showAlert(GlobalConstant.ALERT_TYPE.ERROR, GlobalConstant.ERROR_TITLE, errorText);
          }
        }
        getSiteDetail();
        return () => {}
    }, []);

    useEffect(() => {
      if (!selectLogo) {
        setLogoState(undefined);
        return;
      }

      const objectUrl = URL.createObjectURL(selectLogo)
      setLogoState(objectUrl);

      return () => URL.revokeObjectURL(objectUrl);
    }, [selectLogo]);

    function setDataToForm(params) {
      const fields = ["domain", "key_name", "line_notify_token"];
      fields.forEach((field) => {
        setValue(field, params[field]);
      });
      if (!params.color) params.color = "#343a40";
      setColorState(params.color);
      setLogoState(params.logo_full_path);
    }

    const onchangeColor = (color) => {
        setColorState(color.hex);
    }

    const handleShowLineToken = () => {
        setHasShowLineToken(!hasShowLineToken);
    }

    const handleRedio = (data) => {
      setRedioState(data);
    }

    const handlerLogo = (e) => {
      const image = e.target.files[0];
      setSelectLogo(image);
    };

    const onSubmit = (params) => {
      SweetAlertUtil.showAlertWithConfirmButton("warning", "", `คุณต้องการบันทึกการแก้ไข ?`).then( async (ok) => {
        if (ok) {
          params.color = colorState;
          params.logo = selectLogo;
          params.position = redioState;
          const {status, errorText} = await SiteSettingData.updateSite(params, siteDetailState.id);
          if (status) {
            SweetAlertUtil.showAlert("success", "สำเร็จ", "");
          }else {
            SweetAlertUtil.showAlert("error", "", `${errorText}`);
          }
        }
      });
    };

    const onDelete = () => {
      SweetAlertUtil.showAlertWithConfirmButton("warning", "", `คุณต้องการลบเว็บไซต์ ${siteDetailState.key_name} ?`).then(
        async (ok) => {
          if (ok) {
            const {status, data, errorText} = await SiteSettingData.delete(tokenKey)
            if (status) {
              SweetAlertUtil.showAlertWithCallBack("success", "", `${data}`).then(
                () => {
                  window.location.replace("/page/sites");
                }
              );
            }else {
              SweetAlertUtil.showAlert("error", "", `${errorText}`);
            }
          }
        }
      );
    }

    return (
      <div>
        <ContentHeader
          title="ตั้งค่าเว็บไซต์"
          titleName="ตั้งค่าเว็บไซต์"
          backTitle="เว็บไซต์"
          backLink="/page/sites"
        />

        <div className="content">
          <div className="container-fluid">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-8">
                <div className="card card-primary card-outline">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="card-header">
                      {/* <button type="button" className="btn btn-danger btn-sm" onClick={onDelete}>ลบ</button> */}
                      <span className="float-right">
                          <button type="submit" className="btn btn-sm btn-primary">บันทึก</button>
                        </span>
                    </div>

                    <div className="card-body box-profile">
                      <div className="text-center pb-3">
                        <a href="#">
                          <Image 
                            className="profile-user-img img-circle" 
                            key={logoState} 
                            src={logoState} 
                            fallback={GlobalConstant.EMPTY_IMAGE}
                            width='100' 
                            height='100'
                            onClick={() => document.getElementById("logo_img").click()}
                          />
                          
                          <input
                            type="file"
                            id="logo_img"
                            className="d-none"
                            accept="image/*"
                            multiple={false}
                            onChange={handlerLogo}
                          />
                        </a>
                      </div>

                      <div className="form-group">
                        <input
                          className={`
                            profile-username form-control text-center 
                            ${errors.key_name ? "is-invalid" : ""}
                          `}
                          id="key_name"
                          name="key_name"
                          {...register("key_name", {
                            required: "Please enter site name",
                            type: "text",
                          })}
                        />

                        <ErrorMessage
                          errors={errors}
                          name="key_name"
                          render={({ message }) => (
                            <span className="error invalid-feedback">
                              {message}
                            </span>
                          )}
                        />
                      </div>

                      <div className="form-group">
                        <input
                          className={`
                            text-muted form-control text-center
                            ${errors.domain ? "is-invalid" : ""}
                          `}
                          id="domain"
                          name="domain"
                          {...register("domain", {
                            required: "Please enter domain",
                            type: "text",
                          })}
                        />

                        <ErrorMessage
                          errors={errors}
                          name="domain"
                          render={({ message }) => (
                            <span className="error invalid-feedback">
                              {message}
                            </span>
                          )}
                        />
                      </div>

                      <ul className="list-group list-group-unbordered mb-3">
                        <li className="list-group-item">
                          <div className="row">
                            <div className="col-md-6">
                              <b>Line notify</b>
                            </div>

                            <div className="col-md-6">
                              {hasShowLineToken ? (
                                <span className="text-gray">
                                  <input
                                    id="line_notify_token"
                                    className="form-control form-control-sm text-center"
                                    {...register("line_notify_token", {
                                      type: "text",
                                    })}
                                  />
                                </span>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-sm btn-outline-primary float-right"
                                  onClick={handleShowLineToken}
                                >
                                  Show
                                </button>
                              )}
                            </div>
                          </div>
                        </li>
                      </ul>

                      <div className="row">
                        {/* <div className="col-md-6">
                          <div className="container-fluid border border-secondary rounded p-3">
                            <h6>ตำแหน่งแชท</h6>
                            <div className="row">
                              <div className="col-md-6 text-center">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="position"
                                    id="BL"
                                    defaultChecked={
                                      (siteDetailState.position || "BR") ===
                                      "BL"
                                        ? true
                                        : false
                                    }
                                    onClick={(e) => handleRedio(e.target.id)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="BL"
                                  >
                                    ซ้ายล่าง
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-6 text-center">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="position"
                                    id="BR"
                                    defaultChecked={
                                      (siteDetailState.position || "BR") ===
                                      "BR"
                                        ? true
                                        : false
                                    }
                                    onClick={(e) => handleRedio(e.target.id)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="BR"
                                  >
                                    ขวาล่าง
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}

                        <div className="col-md-6">
                          <div className="container-fluid border border-secondary rounded p-3">
                            <h6>
                              สีแชท
                              <span className="float-right">
                                {colorState && (
                                  <ColorPicker
                                    color={colorState}
                                    onChange={onchangeColor}
                                  />
                                )}
                              </span>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.card-body */}
                  </form>
                </div>
                {/* /.card */}

                <div className="card card-primary card-outline">
                  <div className="card-header">
                    <h5>หมวดหมู่สนทนา
                      <span className='float-right'>
                        <button className='btn btn-primary btn-sm' onClick={handleOpenAddModal}>เพิ่มหมวดหมู่</button>
                      </span>
                    </h5>
                  </div>
                  <div className="card-body">
                    <Catagory keyId={siteDetailState.id || null}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default SiteSettingPage

