import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import useSiteList from "../../../../hooks/useSiteList";
import useSiteUnread from "../../../../hooks/useSiteUnread";

const HeaderChat = ({tokenKey}) => {
  const {siteList, getSiteList} = useSiteList()
  const {unreadSite, getSiteUnread} = useSiteUnread()

  useEffect(() => {
    const onGetSite = async () => {
      await getSiteList()
      getSiteUnread()
    }
    onGetSite()
  }, [])

  return (
    <nav className="main-header navbar navbar-expand navbar-dark sidebar-dark-primary ml-0">
      <div className="container">
        <div className="collapse navbar-collapse" id="navbarCollapse">
          {/* Left navbar links */}
          <ul className="navbar-nav mx-auto">
            <li className="nav-item">
              <Link to="/page/homepage" className="nav-link text-white">
                <i className="fa fa-home" aria-hidden="true"></i> หน้าหลัก
              </Link>
            </li>

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="siteDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {siteList && siteList.map((item) => (`${item.token_key === tokenKey ? item.key_name : ""}`))}
                {siteList && unreadSite.map((i, index) => {
                  if (i.token_key === tokenKey && i.un_read_count !== '') {
                    return (
                      <span key={index} className="badge badge-danger right ml-2">{i.un_read_count}</span>
                    );
                  }
                  })
                }
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="siteDropdown"
              >
                {siteList && siteList.map((item) => (
                  <Link
                    key={item.domain}
                    to={{ pathname: `/page/chat/${item.token_key}` }}
                    className="dropdown-item d-flex justify-content-between align-items-center"
                  >
                    {item.key_name}
                    {unreadSite.map((i, index) => {
                      if (i.token_key === item.token_key && i.un_read_count !== '0') {
                          return (
                            <span key={index} className="badge badge-danger ml-2">{i.un_read_count}</span>
                          );
                      }
                    })}
                  </Link>
                ))}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default HeaderChat;
