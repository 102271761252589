import React, { useState, useEffect } from 'react';
import "./MyRoomsCss.css";

const MyRooms = ({ activeRoom, onlineUsers, unreads, room, handleSelect, handleClose }) => {
  const [unread, setUnread] = useState("");
  const [statusRoom, setStatusRoom] = useState(null);
  const [statusRoomClass, setStatusRoomClass] = useState(null);

  const [caseStatusRoom, setCaseStatusRoom] = useState(null);
  const [caseStatusRoomClass, setcaseStatusRoomClass] = useState(null);

  useEffect(() => {
    if (room.status) {
      setStatusRoom({ id: room.status.id, name: room.status.name_th });
    } else {
      setStatusRoom(null);
    }

    if (room.case_status) {
      setCaseStatusRoom({ id: room.case_status.id, name: room.case_status.name_th });
    } else {
      setCaseStatusRoom(null);
    }
  }, [room]);

  useEffect(() => {
    if (statusRoom) {
      let statusRoomClass = 'status-room ';
      statusRoomClass += statusRoom.id === 1 ? 'new' : ''
      statusRoomClass += statusRoom.id === 2 ? 'in-process' : ''
      statusRoomClass += statusRoom.id === 3 ? 'cancel' : ''
      statusRoomClass += statusRoom.id === 4 ? 'cancel' : ''
      statusRoomClass += ' mr-1'
      setStatusRoomClass(statusRoomClass);
    }
  }, [statusRoom]);

  useEffect(() => {
    if (caseStatusRoom) {
      let statusRoomClass = 'status-room ';
      statusRoomClass += caseStatusRoom.id === 1 ? 'success' : ''
      statusRoomClass += caseStatusRoom.id === 2 ? 'in-process' : ''
      statusRoomClass += caseStatusRoom.id === 3 ? 'not-found' : ''
      statusRoomClass += caseStatusRoom.id === 4 ? 'cancel' : ''
      setcaseStatusRoomClass(statusRoomClass);
    }
  }, [caseStatusRoom]);

  useEffect(() => {
    if (Array.isArray(unreads) && unreads.length > 0 && room.id) {
      let temp = unreads.filter((i) => i.id === room.id);
      if (temp[0]) {
        setUnread(temp[0].un_read_count);
      } else {
        setUnread('');
      }
    }else {
      setUnread('');
    }
  }, [unreads, room]);

  const handleSelectRoom = (guestId, roomId, statusId) => {
    handleSelect(guestId, roomId, statusId);
  };

  const handleCloseRoom = (id) => {
    handleClose(id)
  };

  return (
    <li
      className={activeRoom.guestId === room.guest_id && activeRoom.roomId === room.id ? "contact active" : "contact"}
      onClick={() => handleSelectRoom(room.guest_id, room.id, room.status_id)}
    >
      <div className="wrap">
        <div className="row text-gray align-items-center" style={{float: 'right', fontSize: '10px', marginRight: '0px', marginLeft: '30px'}}>{room.showLastActiontime}</div>
        {(room.status_id !== 1 && room.status_id !== 3 && room.status_id !== 4) &&
          <i 
            className="fas fa-times text-white" 
            style={{position: 'absolute', marginTop: '-15px', marginLeft: '-15px'}}
            onClick={() => handleCloseRoom(room.id)}
          ></i>
        }
        <span
          className={`contact-status ${onlineUsers.some((i) => i.userid === room.guest_id)
            ? "online"
            : "busy"
            }`}
        />
        <img src="/dist/img/icons/chat-avatar.png" alt='profile' />
        <div className="meta">
          <p className="name">{room.name}</p>
          <p className="preview">
            {room.content === room.description ? (room.detail || '-') : room.content}
          </p>
          {unread !== '' &&
            <div className="unread">
              <p className="badge badge-danger my-0">
                {unread}
              </p>
            </div>
          }
          <div className='status'>
            {caseStatusRoom && (statusRoom.id !== 3 || statusRoom.id !== 4) ?
              <p className={caseStatusRoomClass || 'status-room'}>{caseStatusRoom.name}</p>
              :
              (statusRoom && <p className={statusRoomClass || 'status-room'}>{statusRoom.name}</p>)
            }
            <p className={'status-room new'}>หัวข้อ: {room.cate_name}</p>
          </div>
          <div className='bottom-line'></div>
        </div>
      </div>
    </li>
  );
}

export default MyRooms;