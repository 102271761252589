import { useContext, useCallback } from "react";
import { atom, useRecoilState } from "recoil";
import { SocketContext } from "../Services/Socket";
import SweetAlertUtil from "../Containers/SweetAlertUtil";
import GlobalConstant from "../GlobalConstant";

const unreadRoomAtom = atom({
    key: "unreadRoom",
    default: [],
});

const useRoomUnread = () => {
  const socket = useContext(SocketContext);

  const [unreadRoom, setUnreadRoom] = useRecoilState(unreadRoomAtom);

  let _tokenKey = null
  const getRoomUnread = useCallback((tokenKey)=>{
    _tokenKey = tokenKey
    let values = {
        token_key: tokenKey,
    }
    socket.emit("room_unread_count", values, unreadCountCallBack);
  }, [])

  const unreadCountCallBack = useCallback((response) => {
    // console.log("room_unread_count ", response);
    const { status, data } = response;
    if (status) {
        data.token_key === _tokenKey && setUnreadRoom(data.list);
    }
  }, []);

  return {unreadRoom, getRoomUnread}
}
export default useRoomUnread;