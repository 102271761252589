import {useState, useEffect, useCallback} from 'react'
import PropTypes from 'prop-types'
import Modal from '../../../../Components/Modal'

import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import SweetAlertUtil from '../../../../Containers/SweetAlertUtil';
import GlobalConstant from '../../../../GlobalConstant';
import CategoryData from './Category.data';
import { useRecoilState } from 'recoil';
import { categoryListAtom } from '../Recoil/categoryListAtom';
import { showAddCategoryAtom } from '../Recoil/showAddCategoryAtom';

function Catagory({ keyId }) {
  const [categoryList, setCategoryList] = useRecoilState(categoryListAtom)
  const [showAddCategoryModal, setShowAddCategoryModal] = useRecoilState(showAddCategoryAtom)
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false)
  const [selectCategory, setSelectCategory] = useState(null)

  const addCategoryForm = useForm();
  const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm();

  useEffect(() => {
    //console.log("selectCategory :: ", selectCategory)
    reset()
    selectCategory && setValue("cate_name", selectCategory.cate_name)
  }, [selectCategory])
  
  const handleCloseAddModal = () => setShowAddCategoryModal(false)
  const handleOpenEditModal = (item) => {
    setSelectCategory(item)
    setShowEditCategoryModal(true)
  }
  const handleCloseEditModal = () => setShowEditCategoryModal(false)

  useEffect(() => {
    addCategoryForm.reset()
  }, [showAddCategoryModal])

  const handleAddCategory = (formData) => {
    //console.log("handleAddCategory : ", formData);
    handleCloseAddModal();
    const msg = `ต้องการเพิ่มหมวดหมู่ "${formData.cate_name}" ?`;
    SweetAlertUtil.showAlertWithConfirmButton(GlobalConstant.ALERT_TYPE.WARNING, GlobalConstant.WARNING_TITLE, msg).then(
        async (ok) => {
            if (!ok) return;

            const values = {
                key_id: keyId,
                cate_name: formData.cate_name
            }
            const {status, data, errorText} = await CategoryData.onAddCategory(values);
            if (status) {
                const category = data.category || {};
                setCategoryList(pv => [...pv, category] );
                
                SweetAlertUtil.showAlert(GlobalConstant.ALERT_TYPE.SUCCESS, GlobalConstant.SUCCESS_TITLE, "เพิ่มหมวดหมู่เรียบร้อยแล้ว");
            }else {
                SweetAlertUtil.showAlert(GlobalConstant.ALERT_TYPE.ERROR, GlobalConstant.ERROR_TITLE, errorText);
            }
        }
    ); 
  }

  const handleEditCategory = useCallback((formData) => {
    //console.log("handleEditCategory : ", formData);
    handleCloseEditModal();
    SweetAlertUtil.showAlertWithConfirmButton(GlobalConstant.ALERT_TYPE.WARNING, GlobalConstant.WARNING_TITLE, "ยืนยันการแก้ไข?").then(
        async (ok) => {
            if (!ok) return;

            const values = {
                id: selectCategory.id,
                cate_name: formData.cate_name
            }
            const {status, data, errorText} = await CategoryData.onEditCategory(values);
            if (status) {
                const category = data.category || {};
                setCategoryList((pv) => {
                    let index = pv.findIndex(item => item.id === category.id);
                    let newArr = [...pv];
                    if (index !== -1) {
                        let old = pv[index];
                        let newItem = {...old, cate_name: category.cate_name}
                        newArr[index] = newItem;
                    }
                    return newArr;
                });
                
                SweetAlertUtil.showAlert(GlobalConstant.ALERT_TYPE.SUCCESS, GlobalConstant.SUCCESS_TITLE, "แก้ไขหมวดหมู่เรียบร้อยแล้ว");
            }else {
                SweetAlertUtil.showAlert(GlobalConstant.ALERT_TYPE.ERROR, GlobalConstant.ERROR_TITLE, errorText);
            }
        }
    ); 
  },[selectCategory])

  const handleChangeStatus = async (event, item) => {
    //console.log("handleChangeStatus : ", event.target.checked);
    const values = {
        id: item.id,
        cate_name: item.cate_name,
        is_active: event.target.checked ? 1 : 0
    }
    const {status, data, errorText} = await CategoryData.onEditCategory(values);
    if (status) {
        const category = data.category || {};
        const msg = `${category.is_active ? 'เปิด' : 'ปิด'} หมวดหมู่ "${category.cate_name}" เรียบร้อยแล้ว`;
        SweetAlertUtil.showAlert(GlobalConstant.ALERT_TYPE.SUCCESS, GlobalConstant.SUCCESS_TITLE, msg);
    }else {
        SweetAlertUtil.showAlert(GlobalConstant.ALERT_TYPE.ERROR, GlobalConstant.ERROR_TITLE, errorText);
    }
  }

  
  return (
    <div>
        <table className="table table-hover text-nowrap">
            <thead className='thead-dark'>
                <tr>
                    <th className='text-center'>#</th>
                    <th>หมวดหมู่</th>
                    <th className='text-center'>สถานะ</th>
                    <th className='text-center'>จัดการ</th>
                </tr>
            </thead>
            <tbody>
                {categoryList.map((item, index) => (
                    <tr key={item.id}>
                        <td className='text-center'>{index + 1}</td>
                        <td>{item.cate_name}</td>
                        <td className='text-center'>
                            {item.id !== - 99 ?
                                <div className="form-group">
                                    <div className="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                        <input type="checkbox" className="custom-control-input" id={`customSwitch-${item.id}`} defaultChecked={item.is_active} onChange={(e) => handleChangeStatus(e, item)}/>
                                        <label className="custom-control-label" htmlFor={`customSwitch-${item.id}`}></label>
                                    </div>
                                </div>
                            : '-'}
                        </td>
                        <td className='text-center'>
                            {item.id !== -99 ? 
                                (
                                    <>
                                    <button type="button" className="btn btn-warning btn-sm mr-1" onClick={() => handleOpenEditModal(item)}>แก้ไข</button>
                                    </>
                                )
                            : ''}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>

        <Modal 
            modalId={"addCategory"} 
            show={showAddCategoryModal} 
            title={"เพิ่มหมวดหมู่"}
            btnSuccessText={"เพิ่ม"}
            handleSuccess={addCategoryForm.handleSubmit(handleAddCategory)}
            cancel={handleCloseAddModal}
        >
            <form>
                <div className="form-group">
                    <label htmlFor={"cate_name"}>หมวดหมู่ <code>*</code></label>
                    <input 
                        className={
                            addCategoryForm.formState.errors.cate_name
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        {...addCategoryForm.register(
                            "cate_name", {
                            required: "กรุณากรอกชื่อหมวดหมู่",
                            type: "text",
                        })}
                    />

                    <ErrorMessage
                        errors={addCategoryForm.formState.errors}
                        name="cate_name"
                        render={({ message }) => (
                        <span className="error invalid-feedback">{message}</span>
                        )}
                    />
                </div>
            </form>
        </Modal>

        <Modal 
            modalId={"editCategory"} 
            show={showEditCategoryModal} 
            title={`แก้ไข - ${selectCategory && selectCategory.cate_name}`}
            btnSuccessText={"บันทึก"}
            handleSuccess={handleSubmit(handleEditCategory)}
            cancel={handleCloseEditModal}
        >
            <form method="post">
                <div className="form-group">
                    <label htmlFor={"cate_name"}>หมวดหมู่</label>
                    <input 
                        className={
                            errors.cate_name
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        {...register(
                            "cate_name", {
                            required: "กรุณากรอกชื่อหมวดหมู่",
                            type: "text",
                        })}
                    />

                    <ErrorMessage
                        errors={errors}
                        name="cate_name"
                        render={({ message }) => (
                        <span className="error invalid-feedback">{message}</span>
                        )}
                    />
                </div>
            </form>
        </Modal>
    </div>
  )
}

Catagory.propTypes = {
    keyId: PropTypes.element.isRequired,
    keyId: PropTypes.number
}

export default Catagory
