import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil';
import SweetAlertUtil from '../../../../Containers/SweetAlertUtil';
import GlobalConstant from '../../../../GlobalConstant';
import AuthenService from '../../../../Services/AuthenService';
import { SocketContext } from '../../../../Services/Socket';
import { selectRoomAtom, showAssignAtom, showInputAtom, textAtom } from '../Recoil/AdminChatPageAtom';

const Input = ({tokenKey, onClick, onSendMessage}) => {
    const socket = useContext(SocketContext);

    const showInput = useRecoilValue(showInputAtom)
    const showAssignRoom = useRecoilValue(showAssignAtom)
    const selectRoom = useRecoilValue(selectRoomAtom)
    const [text, setText] = useRecoilState(textAtom)

    const { userid, firstname, lastname } = AuthenService.getAccessor();

    const handleEnter = (event) => {
        if (event.key === "Enter") {
            onSendMessage();
        }
    };

    const onTapAssignRoom = useCallback(() => {
      SweetAlertUtil.showAlertWithConfirmButton(
        GlobalConstant.ALERT_TYPE.WARNING,
        "ยืนยัน",
        "คุณต้องการรับเรื่องลูกค้านี้ ?"
      ).then((ok) => {
        if (ok) {
          onAssignRoom();
        }
      });
    }, [tokenKey, selectRoom])

    const onAssignRoom = () => {
      let fullName = `${firstname} ${lastname}`
      let msg = `${fullName} join chat`;
      const params = {
        token_key: tokenKey,
        to: selectRoom.guestId,
        owner: {
          id: userid, 
          full_name: fullName
        },
        room_id: selectRoom.roomId,
        content: msg,
        read_flag: 0,
        is_staff: true,
        create_date: new Date()
      };
      // console.log("ASSIGN ROOM : ", params);
      socket.emit("assign_room", params);
    }

    return ( 
        <>
        {showAssignRoom && 
          (
            <div className="assign-room">
              <button className="btn btn-sm btn-info" onClick={onTapAssignRoom}>
                <i className="fa fa-sign-in-alt" aria-hidden="true" style={{ color: '#fff' }}></i>
                {" "}
                รับเรื่องลูกค้า
              </button>
            </div>
          )
        }
        {showInput && 
          (
            <div className="message-input">
              <div className="wrap">
                <input
                  type="text"
                  placeholder="เขียนข้อความของคุณ..."
                  value={text}
                  onClick={onClick}
                  onChange={(e) => setText(e.target.value)}
                  onKeyUp={(e) => handleEnter(e)}
                />
                {/* <i
                    className="fa fa-paperclip attachment"
                    aria-hidden="true"
                  /> */}
                <button className="submit" onClick={onSendMessage}>
                  <i className="fa fa-paper-plane" aria-hidden="true" />
                </button>
              </div>
            </div>
          )
        }
        </>
    );
}
export default Input;