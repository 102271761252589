import apiService from "../../../Services/APIService";
import responseBody from "../../../Services/ResponseBody";
const GET_AGENT_LIST_PATH = "api/backend/operator/list";
const ADD_AGENT_PATH = "api/backend/operator/add";
const AGENT_ADD_SITE_PATH = "api/backend/operator/add-site";
const EDIT_STATUS_PATH = "api/backend/operator/edit-status";
const EDIT_AGENT_PATH = "api/backend/operator/edit";
const SEARCH_AGENT_PATH = "api/backend/operator/search";
const GET_SITE_LIST = "api/backend/site/list";

let responseMsg = {
  status: false,
  data: null,
  errorText: null,
};

class AgentController {
  async onGetSiteList() {
    //console.log("== START GET SITE LIST ==");
    let url = GET_SITE_LIST;
    let response = await apiService.post(url);
    //console.log("== END GET SITE LIST ==");
    let result = null;
    if (response && response.header.success === "Y") {
      result = responseBody(true, response.body);
    } else {
      result = responseBody(false, null, response.header.message);
    }
    return result;
  }
  getAgents(params) {
    return new Promise(async (success, reject) => {
      //console.log("===== START GET AGENT =====");
      let response = await apiService.post(GET_AGENT_LIST_PATH, params);
      //console.log("===== END GET AGENT =====");
      if (response && response.header.success == "Y") {
        responseMsg.status = true;
        responseMsg.data = response.body;
        success(responseMsg);
      } else {
        responseMsg.status = false;
        responseMsg.errorText = response.header.message;
        success(responseMsg);
      }
    });
  }
  addAgent(data) {
    return new Promise(async (success, reject) => {
      //console.log("===== START ADD AGENT =====");
      let response = await apiService.post(ADD_AGENT_PATH, data);
      //console.log("===== END ADD AGENT =====");
      if (response && response.header.success == "Y") {
        responseMsg.status = true;
        responseMsg.data = response.body;
        success(responseMsg);
      } else {
        responseMsg.status = false;
        responseMsg.errorText = response.header.message;
        success(responseMsg);
      }
    });
  }
  addSiteToAgent(data) {
    return new Promise(async (success, reject) => {
      //console.log("===== START ADD SITE AGENT =====");
      let response = await apiService.post(AGENT_ADD_SITE_PATH);
      //console.log("===== END ADD SITE AGENT =====");
      if (response && response.header.success == "Y") {
        responseMsg.status = true;
        responseMsg.data = response.body;
        success(responseMsg);
      } else {
        responseMsg.status = false;
        responseMsg.errorText = response.header.message;
        success(responseMsg);
      }
    });
  }
  editStatus(data) {
    return new Promise(async (success, reject) => {
      //console.log("===== START EDIT STATUS AGENT =====");
      let response = await apiService.post(EDIT_STATUS_PATH, data);
      //console.log("===== END EDIT STATUS AGENT =====");
      if (response && response.header.success == "Y") {
        responseMsg.status = true;
        responseMsg.data = response.body;
        success(responseMsg);
      } else {
        responseMsg.status = false;
        responseMsg.errorText = response.header.message;
        success(responseMsg);
      }
    });
  }
  editAgent(data) {
    let params = {
      email: data.edit_email,
      username: data.edit_username,
      firstname: data.edit_firstname,
      lastname: data.edit_lastname,
      mobile: data.edit_mobile,
      userid: data.edit_userid,
    };
    return new Promise(async (success, reject) => {
      //console.log("===== START EDIT AGENT =====");
      let response = await apiService.post(EDIT_AGENT_PATH, params);
      //console.log("===== END EDIT AGENT =====");
      if (response && response.header.success == "Y") {
        responseMsg.status = true;
        responseMsg.data = response.body;
        success(responseMsg);
      } else {
        responseMsg.status = false;
        responseMsg.errorText = response.header.message;
        success(responseMsg);
      }
    });
  }
  search(data) {
    return new Promise(async (success, reject) => {
      //console.log("===== START SEARCH AGENT =====");
      let response = await apiService.post(SEARCH_AGENT_PATH, data);
      //console.log("===== END SEARCH AGENT =====");
      if (response && response.header.success == "Y") {
        responseMsg.status = true;
        responseMsg.data = response.body;
        success(responseMsg);
      } else {
        responseMsg.status = false;
        responseMsg.errorText = response.header.message;
        success(responseMsg);
      }
    });
  }
}

export default new AgentController();
