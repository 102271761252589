import { useRecoilState } from "recoil";
import SweetAlertUtil from "../Containers/SweetAlertUtil";
import dateutility from "../Dev/dateutility";
import { checkPackage } from "../Dev/Recoil/checkPackage";
import GlobalConstant from "../GlobalConstant";
import APIService from "../Services/APIService";
import AuthenService from "../Services/AuthenService";
const CHECK_PACKAGE = "api/backend/check-package";

const useCheckPackage = () => {
    const { userid } = AuthenService.getAccessor();
    const [errorPackage, setErrorPackage] = useRecoilState(checkPackage);

    const getCheckPackage = async () => {
        const param = {
            userId: userid
        }
        const {body, message} = await APIService.post(CHECK_PACKAGE, param);
        if (message) return SweetAlertUtil.showAlert(GlobalConstant.ALERT_TYPE.ERROR, GlobalConstant.ERROR_TITLE , message);

        if (body) {
            const members = body.members || [];
            const currentMember = members.filter(m=> m.status_id === 1 );
            const currentPackages = currentMember.filter(m=> dateutility.betweenDate(new Date(m.start_dt), new Date(m.expire_dt)) );
            if (currentMember.length === 1 && currentMember[0].package_id === 0) {
                setErrorPackage("เพื่อการใช้งานที่เต็มประสิทธิภาพ กรุณาสมัครแพ็คเกจ");
            }else if (currentPackages.length === 0){ 
                setErrorPackage("แพ็คเกจหมดอายุ กรุณาติดต่อผู้ดูแลเว็บไซต์");
            }
        }
    };

return {errorPackage, getCheckPackage}
}
export default useCheckPackage;