import { useRecoilState } from "recoil";
import SweetAlertUtil from "../Containers/SweetAlertUtil";
import { userInfoAtom } from "../Dev/Recoil/userInfoAtom";
import GlobalConstant from "../GlobalConstant";
import APIService from "../Services/APIService";
import AuthenService from "../Services/AuthenService";
const GET_USER_INFO = "api/user/detail";

const useUserInfo = () => {
    const { userid } = AuthenService.getAccessor();
    const [userInfo, setUserInfo] = useRecoilState(userInfoAtom);

    const getUserInfo = async () => {
        const {body, message} = await APIService.get(GET_USER_INFO + `/${userid}`);
        if (message) return SweetAlertUtil.showAlert(GlobalConstant.ALERT_TYPE.ERROR, GlobalConstant.ERROR_TITLE , message);

        if (body) {
            // console.log(body);
            const {company, role_id} = body;
            if (!(company) && role_id == 200) {
                body.company = body.userid;
            }
            setUserInfo(body);
        }
    };

return {userInfo, getUserInfo}
}
export default useUserInfo;