import axios from 'axios';
import config from '../Config';
import AuthenService from './AuthenService';
const SERVER_HOST = config.SERVER_URL;
class APIService {
    async get(apiURL, data, optionals){
        //console.log("---get---");
        const crendential = AuthenService.getCredentials();
        let url = SERVER_HOST + apiURL;
        //if(crendential){
            if(!optionals) optionals = {};
            if(!optionals.headers) optionals.headers = {};
            if(!optionals.params) optionals.params = {};
            optionals.headers = {...optionals.headers, Authorization: `Bearer ${crendential.accessToken}`}
            optionals.params = data;
        //}
        var response = null;
        try{
            response = await axios.get(url, optionals);
            //console.log('rawData', response);
            response =  this.handleSuccess(response);
        }catch(error){
            //console.log('error', error.response);
            response = {
                errorResponse: error.response,
                status: error.status,
                rawData: error,
                message: error.message
            }
            if(error.response && error.response.data){
                var data = error.response.data;
                response.errorData = data;
                var tokenisexpired = data.tokenisexpired;
                //console.log('tokenisexpired', tokenisexpired);
                if(tokenisexpired){
                    await AuthenService.refreshToken();
                    await this.post(apiURL, data, optionals);
                }else if(data.name){
                    AuthenService.userLogout();
                    alert('Unauthorized');
                    window.location.replace('/login');
                }
            }
        }
        //console.log('response', response);
        return response;
    } 
    async post(apiURL, data, optionals){
        //console.log("---post---");
        const crendential = AuthenService.getCredentials();
        let url = SERVER_HOST + apiURL;
        //if(crendential){
            if(!optionals) optionals = {};
            if(!optionals.headers) optionals.headers = {};
            optionals.headers = {...optionals.headers, Authorization: `Bearer ${crendential.accessToken}`}
        //}
        var response = null;
        try{
            response = await axios.post(url, data, optionals);
            //console.log('rawData', response);
            response =  this.handleSuccess(response);
        }catch(error){
            //console.log('error', error.response);
            response = {
                errorResponse: error.response,
                status: error.status,
                rawData: error,
                message: error.message
            }
            if(error.response && error.response.data){
                var data = error.response.data;
                response.errorData = data;
                var tokenisexpired = data.tokenisexpired;
                //console.log('tokenisexpired', tokenisexpired);
                if(tokenisexpired){
                    await AuthenService.refreshToken();
                    await this.post(apiURL, data, optionals);
                }else if(data.name){
                    AuthenService.userLogout();
                    alert('Unauthorized');
                    window.location.replace('/login');
                }
            }
        }
        //console.log('response', response);
        return response;
    } 
    async put(apiURL, data, optionals){
        //console.log("---put---");
        const crendential = AuthenService.getCredentials();
        let url = SERVER_HOST + apiURL;
        //if(crendential){
            if(!optionals) optionals = {};
            if(!optionals.headers) optionals.headers = {};
            optionals.headers = {...optionals.headers, Authorization: `Bearer ${crendential.accessToken}`}
        //}
        var response = null;
        try{
            response = await axios.put(url, data, optionals);
            //console.log('rawData', response);
            response =  this.handleSuccess(response);
        }catch(error){
            //console.log('error', error.response);
            response = {
                errorResponse: error.response,
                status: error.status,
                rawData: error,
                message: error.message
            }
            if(error.response && error.response.data){
                var data = error.response.data;
                response.errorData = data;
                var tokenisexpired = data.tokenisexpired;
                //console.log('tokenisexpired', tokenisexpired);
                if(tokenisexpired){
                    await AuthenService.refreshToken();
                    await this.post(apiURL, data, optionals);
                }else if(data.name){
                    AuthenService.userLogout();
                    alert('Unauthorized');
                    window.location.replace('/login');
                }
            }
        }
        //console.log('response', response);
        return response;
    }
    async delete(apiURL, data, headers){
        //console.log("---delete---");
        const crendential = AuthenService.getCredentials();
        let url = SERVER_HOST + apiURL;
        //if(crendential){
            if(!headers) headers = {};
            headers = {...headers, Authorization: `Bearer ${crendential.accessToken}`}
        //}
        var response = null;
        try{
            response = await axios.delete(url, {data: data, headers: headers});
            //console.log('rawData', response);
            response =  this.handleSuccess(response);
        }catch(error){
            //console.log('error', error.response);
            response = {
                errorResponse: error.response,
                status: error.status,
                rawData: error,
                message: error.message
            }
            if(error.response && error.response.data){
                var data = error.response.data;
                response.errorData = data;
                var tokenisexpired = data.tokenisexpired;
                //console.log('tokenisexpired', tokenisexpired);
                if(tokenisexpired){
                    await AuthenService.refreshToken();
                    await this.post(apiURL, data, headers);
                }else if(data.name){
                    AuthenService.userLogout();
                    alert('Unauthorized');
                    window.location.replace('/login');
                }
            }
        }
        //console.log('response', response);
        return response;
    }
    handleSuccess(response){
        if(!response) return null;
        let result = {
            statusCode: response.status,
            message: response.message,
            body: response.data.body,
            header: response.data.header,
            rawData: response
        }
        return result;
    } 
}
export default new APIService();