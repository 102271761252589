import useFallbackImg from "../hooks/useFallBackImage";

/**
 * Usage <Image src='someUrl' fallback='fallbackUrl' alt='something' />
 */
function Image({src, fallback, ...rest}) {

    const imgProps = useFallbackImg(src, fallback)
  
    return <img {...imgProps} {...rest} alt=''/>
}
export default Image;