import React from "react";
import ContentHeader from "../../ContentHeader";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import api from "./ChangePasswordPage.data";
import Swal from "../../../Containers/SweetAlertUtil";

function ChangePasswordPage() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const minErrorText = 'รหัสผ่านต้องมากกว่า 8 ตัวอักษร'

  const onSubmit = (data) => {
    Swal.showAlertWithConfirmButton('warning', 'ยืนยันการทำรายการ?', '')
    .then(async (ok) => {
      if (!ok) return;

      try {
        const resps = await api.onChangePassword({ password: data.new_password });
        if (resps.status) {
          Swal.showAlert('success', 'สำเร็จ');
        } else {
          Swal.showAlert('error', 'ไม่สามารถทำรายการได้');
        }
      } catch (error) {
      }
    });
  };

  const className = (name) => {
    return errors[name] ? "form-control is-invalid" : "form-control";
  };

  return (
    <>
      <ContentHeader
        title="เปลี่ยนรหัสผ่าน"
        titleName="เปลี่ยนรหัสผ่าน"
        backTitle="Home"
        backLink="/"
      />
      <div className="content">
        <div className="container-fluid">
          <div className="d-flex justify-content-center">
            <div className="col-6">
              <div className="card">
                <div className=" card-header">
                  <h5>กรอกรหัสผ่าน</h5>
                </div>
                <div className="card-body">
                  <form method="post" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <div className="input-group mb-3">
                      <input
                        type="password"
                        className={className('new_password')}
                        placeholder="รหัสผ่านใหม่"
                        {...register("new_password", {
                          required: true,
                          minLength: {
                            value: 8,
                            message: minErrorText
                          }
                        })}
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <span className="fas fa-envelope" />
                        </div>
                      </div>
                      <ErrorMessage
                        errors={errors}
                        name="new_password"
                        render={({ message }) => (
                          <span className="error invalid-feedback">{message}</span>
                        )}
                      />
                    </div>

                    <div className="input-group mb-3">
                      <input
                        type="password"
                        className={className('confirm_new_password')}
                        placeholder="ยืนยันรหัสผ่านใหม่"
                        {...register("confirm_new_password", {
                          required: true,
                          minLength: {
                            value: 8,
                            message: minErrorText
                          },
                          validate: (val) => {
                            if (watch('new_password') !== val) {
                              return 'ยืนยันรหัสผ่านไม่ตรงกับรหัสผ่านใหม่';
                            }
                          }
                        })}
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <span className="fas fa-envelope" />
                        </div>
                      </div>
                      <ErrorMessage
                        errors={errors}
                        name="confirm_new_password"
                        render={({ message }) => (
                          <span className="error invalid-feedback">{message}</span>
                        )}
                      />
                    </div>

                    <button
                      type="submit"
                      className=" btn btn-block btn-primary"
                    >
                      เปลี่ยนรหัสผ่าน
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangePasswordPage;
