import React, { useEffect, useState, useRef, useCallback } from "react";
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";

import ContentHeader from "../../ContentHeader";
import MUIDataTable from "mui-datatables";
import AgentData from "./Agent.data";
import Modal from "../../../Components/Modal";

import AuthenService from "../../../Services/AuthenService";
import GlobalConstant from "../../../GlobalConstant";
import SweetAlertUtil from "../../../Containers/SweetAlertUtil";
import useSiteList from "../../../hooks/useSiteList";
import "./AgentPageCSS.css";

const siteState = atom({
  key: "siteState",
  default: '',
});

const dataEditState = atom({
  key: "dataEditState",
  default: null,
});

const hasShowAddModalState = atom({
  key: "hasShowAddModalState",
  default: false,
});

const AgentPage = () => {
  // const [siteList, setSiteList] = useRecoilState(siteListState);
  const [site, setSite] = useRecoilState(siteState);

  const [agentList, setAgentList] = useState([]);
  const [defaultSite, setDefalutSite] = useState("");
  const {siteList, getSiteList} = useSiteList();
  const [name, SetName] = useState("");
  const [userName, SetUserName] = useState("");

  useEffect(() => {
    const onGetSiteList = async () => {
      await getSiteList('');
    }
    onGetSiteList();
  }, []);

  useEffect(() => {
    siteList.length &&
    onSearchFilter();
  }, [siteList]);

  // async function getSiteList() {
  //   const {status, data, errorText} = await AgentData.onGetSiteList();
  //   if (status) {
  //     console.log("SITE list >>> ", data);
  //     setSiteList(data.sites && data.sites);
  //     setSite(data.sites[0] && data.sites[0].site_id);
  //     callChatListFirst(data.sites[0] && data.sites[0].site_id);
  //   }else {
  //     SweetAlertUtil.showAlert("error", GlobalConstant.ERROR_TITLE, "", errorText);
  //   }
  // };

  async function onGetAgentList(params) {
    const res = await AgentData.getAgents(params);
    const data = res.data;
    // console.log("data>>>", data);
    if(data){
      let i = 1;
      const ls = data.map((item) => {
        item.no = i;
        item.name = `${item.firstname} ${item.lastname}`;
        if(item.role_id === '200' || item.role_id === '300'){
          item.role_name = "master"
        }else{
          item.role_name = "operator"
        }
        item.statusOptions = {
            role_id: item.role_id, 
            status_id: item.status_id,
            userid: item.userid
          };
        item.activeOptions = {
            role_id: item.role_id,
            userid: item.userid,
            data: item
          };
        i++;
        return item;
      });
      setAgentList(ls);
    }
  };

  function onFilterByName(p) {
    // console.log(p);
    SetName(p);
  }  

  function onFilterByUserName(p) {
    SetUserName(p);
  }  

  function onFilterBySite(p) {
    setSite(p);
    const params = {
      site: p
    };
    onGetAgentList(params);
  };

  function onSearchFilter() {
    const p = {
      site: site,
      name :name,
      username:userName
    };
    onGetAgentList(p);
  };

  function onClearFilter() {
    // setSite(defaultSite);
    const p = {
      site: "",
      name: "",
      username: ""
    };
    onGetAgentList(p);
  };

    return (
      <div>
        <ContentHeader
          title="เจ้าหน้าที่"
          titleName="เจ้าหน้าที่"
          backTitle="Home"
          backLink="/"
        />
  
        <div className="content">
          <div className="container-fluid">
            <SearchComponent
              onSiteBy={onFilterBySite}
              onSearch={onSearchFilter} 
              onClear={onClearFilter}
              onName={onFilterByName}
              onUserName={onFilterByUserName} 
              siteList={siteList}
            />
            <AgentListTable datas={agentList} handleSuccess={onSearchFilter}/>
            <AddAgentModalComponent handleSuccess={onSearchFilter}/>
            <EditAgentModalComponent handleSuccess={onSearchFilter}/>
          </div>
        </div>
      </div>
    );
};

const SearchComponent = ({ onSiteBy, onSearch, onClear ,onName ,onUserName,siteList}) => {

  const setHasShowAddModal = useSetRecoilState(hasShowAddModalState);
  const site = useRecoilValue(siteState);

  const _form = useRef(null);


  const clear = () => {
    _form.current.reset();
    onClear();
  };

  return (
    <>
      <div className="content">
        <div className="container-fluid">
          <div className="card">
              <div className='card-header'>
                <h5>เกณฑ์การค้นหา</h5>
              </div>
              <div className="card-body">
                <form ref={_form}>
                <div className="row">
                  <div className="col-sm-12 col-md-12">
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-3">
                        <div className="form-group">
                          <label>เว็บไซต์:</label>
                          <select
                            className="form-control form-control-sm"
                            style={{ width: "100%" }}
                            aria-hidden="true"
                            onChange={(e) => onSiteBy(e.target.value)}
                            value={site}
                          >
                            <option value="">-ทั้งหมด-</option>

                            {siteList && siteList.length > 0
                              ? siteList.map((item, index) => {
                                  return (
                                    <option
                                      key={index} value={item.site_id}>
                                      {item.key_name}
                                    </option>
                                  );
                                })
                              : null}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-2">
                        <div className="form-group">
                          <label>ชื่อ</label><br/>
                          <input type="text" className="form-control form-control-sm" name="searchName" id="searchName" onBlur={(e) => onName(e.target.value)}/>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-2">
                        <div className="form-group">
                          <label>ชื่อผู้ใช้งาน</label><br/>
                          <input type="text" className="form-control form-control-sm" name="searchUsername" id="searchUsername" onBlur={(e) => onUserName(e.target.value)}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="button-groups d-flex flex-row" style={{width: "325px", float: "right"}}>
                  <button
                    type="button"
                    className="btn btn-info btn-block btn-sm"
                    onClick={onSearch}
                  >
                    <i className="fas fa-search"></i> ค้นหา
                  </button>
                  <div>&nbsp;</div>
                  <button
                    type="reset"
                    className="btn btn-dark btn-block btn-sm"
                    onClick={clear}
                  >
                    <i className="fas fa-redo-alt"></i> ล้างค่า
                  </button>
                  <div>&nbsp;</div>
                  <button
                    type="button"
                    className="btn btn-success btn-block btn-sm"
                    onClick={()=>setHasShowAddModal(true)}
                  >
                    <i className="fas fa-plus"></i> เพิ่ม
                  </button>
                </div>
                </form>
              </div>
            </div>
        </div>
      </div>
    </>
  )
};

const AgentListTable = ({datas, handleSuccess}) => {

  const setDataEdit = useSetRecoilState(dataEditState);

  const onChangeStatus = (checked, userid) => {
    let value = 1;
    let textConfirm = "คุณต้องยกเลิกการระงับการใช้งานเจ้าหน้าที่?";
    if(checked){
      value = 2;
      textConfirm = "คุณต้องการระงับการใช้งานเจ้าหน้าที่?";
    }
    let params = {
      status: value,
      agentid: userid,
    };
    SweetAlertUtil.showAlertWithConfirmButton(
      GlobalConstant.ALERT_TYPE.WARNING,
      GlobalConstant.WARNING_TITLE,
      textConfirm
    ).then((ok) => {
      if (ok) {
        AgentData.editStatus(params).then((res) => {
          if (res) {
            SweetAlertUtil.showAlertWithCallBack(
              GlobalConstant.ALERT_TYPE.SUCCESS,
              GlobalConstant.SUCCESS_TITLE,
              ""
            ).then(() => {
              handleSuccess();
            });
          }
        });
      }
    })
  };
  
    const columns = [
      {
        name: 'no',
        label: '#',
        options: {
            filter: false,
            sort: true,
            customBodyRender: (value) => {
              return (
                <div className="text-center">
                  {value}
                </div>
              )
            }
        }
      },
      {
        name: "username",
        label: "บัญชี",
        options: {
          filter: false
        }
      },
      {
        name: "name",
        label: "ชื่อ",
        options: {
          filter: false
        }
      },
      {
        name: "key_name",
        label: "เว็บไซต์",
        options: {
          filter: false
        }
      },
      {
        name: "role_name",
        label: "สิทธิ",
        options: {
          filter: true,
          customBodyRender: (value) => {
            return (
              <div className="text-center">
                {value}
              </div>
            )
          }
        }
      },
      {
        name: "statusOptions",
        label: "ระงับการใช้งาน",
        options: {
          filter: false,
          sort: false,
          // filter: true,
          // filterType: 'custom',
          // customFilterListRender: v => {
          //   console.log("V:", v)
          //   if (v[0] && v[1]) {
          //     return `Start Date: ${v[0]}, End Date: ${v[1]}`;
          //   } else if (v[0]) {
          //     return `Start Date: ${v[0]}`;
          //   } else if (v[1]) {
          //     return `End Date: ${v[1]}`;
          //   }
          //   return false;
          // },
          // filterOptions: {
          //   names: [],
          //   logic(date, filters) {

          //   },
          //   display: (filterList, onChange, index, column) => (
          //     <div>
          //       <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          //         <InputLabel id="demo-simple-select-standard-label">Age</InputLabel>
          //         <Select
          //           labelId="demo-simple-select-standard-label"
          //           id="demo-simple-select-standard"
          //           value={age}
          //           onChange={handleChange}
          //           label="Age"
          //         >
          //           <MenuItem value="">
          //             <em>None</em>
          //           </MenuItem>
          //           <MenuItem value={10}>Ten</MenuItem>
          //           <MenuItem value={20}>Twenty</MenuItem>
          //           <MenuItem value={30}>Thirty</MenuItem>
          //         </Select>
          //       </FormControl>
          //     </div>
          //   ),
          // },
          customBodyRender: (value, tableMeta, updateValue) => {
            const { role_id, status_id, userid } = value;
            return (
              <div className="text-center">
                <div className="form-check form-check-inline">
                  <input className="form-check-input" 
                    style={{width:'18px',height:'18px',cursor:'pointer'}} 
                    type="checkbox"
                    checked={status_id===2? true : false}
                    onChange={(e)=>{onChangeStatus(e.target.checked, userid)}}
                    disabled={(role_id === '200' || role_id === '300')? true : false}
                  />
                </div>
              </div>
            );
          }
        }
      },
      {
        name: "activeOptions",
        label: "จัดการ",
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              const { role_id, userid, data } = value; 
              if(role_id !== '200' && role_id !== '300'){
                return (
                  <React.Fragment>
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn btn-warning btn-sm"
                        onClick={() => {
                          setDataEdit(data);
                        }}
                      >
                        <i className="fas fa-edit mr-1"></i>
                        แก้ไข
                      </button>
                      <Link
                        to={`/page/agent/manage/site/${userid}`}
                        className="btn btn-primary ml-2 btn-sm"
                      >
                        <i className="fas fa-plus-square mr-1"></i>
                        เพิ่มเว็บไซต์
                      </Link>
                    </div>
                  </React.Fragment>
                );
              }else{
                return (
                  <></>
                );
              }
            }
          }
      }
    ];
    
    const options = {
      filter: true,
      filterType: "dropdown",
      selectableRows: "none",
      selectableRowsOnClick: false,
      print: false,
      download: false,
      onDownload: (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data);
      }
    };
  
    return (
        <div className="agent-table">
          <MUIDataTable
              title="รายการ"
              data={datas}
              columns={columns}
              options={options}
          />
        </div>
    );
};

const AddAgentModalComponent = ({handleSuccess}) => {
  const { username } = AuthenService.getAccessor();

  const {siteList} = useSiteList();

  const site = useRecoilValue(siteState);

  const [hasShowAddModal, setHasShowAddModal] = useRecoilState(hasShowAddModalState);

  const formAddAgent = useForm();
  const { errors: errorsAddAgent } = formAddAgent.formState;

  useEffect(() => {
    setSite();
    return () => {
      formAddAgent.reset();
    };
  }, [hasShowAddModal]);

  function setSite(){
    formAddAgent.setValue('site_id', site);
  }

  const onAddAgent = (params) => {
    setHasShowAddModal(false);
    SweetAlertUtil.showAlertWithConfirmButton(
      GlobalConstant.ALERT_TYPE.WARNING,
      GlobalConstant.ALERT_TYPE.WARNING,
      "คุณต้องการเพิ่มเจ้าหน้าที่?"
    ).then((ok) => {
      if (ok) {
        AgentData.addAgent(params).then((res) => {
          if (res.status) {
            SweetAlertUtil.showAlertWithCallBack(
              GlobalConstant.ALERT_TYPE.SUCCESS,
              GlobalConstant.SUCCESS_TITLE
            ).then(() => {
              handleSuccess();
            });
          } else {
            let msg = res.errorText;
            if (msg && msg != "") {
              SweetAlertUtil.showAlert("error", GlobalConstant.ERROR_TITLE, msg);
            }
          }
        });
      }
    });
  };

  return (
    <div>
      <Modal
        modalId="add_agent_modal"
        show={hasShowAddModal}
        title="เพิ่มเจ้าหน้าที่"
        btnSuccessText="เพิ่ม"
        handleSuccess={formAddAgent.handleSubmit(onAddAgent)}
        cancel={() => setHasShowAddModal(false)}
      >
        <form id="add_agent_modal_form" method="post">
          <div className="form-group">
            <label htmlFor="username">บัญชีผู้ใช้งาน <code>*</code></label>
            <div className="input-group">
              <input
                className={
                  errorsAddAgent.username
                    ? "form-control is-invalid"
                    : "form-control"
                }
                id="username"
                placeholder="Username"
                {...formAddAgent.register("username", {
                  required: "Please enter a username",
                  type: "text",
                })}
              />
              <div className="input-group-append">
                <span className="input-group-text">{`@${username}`}</span>
              </div>

              <ErrorMessage
                errors={errorsAddAgent}
                name="username"
                render={({ message }) => (
                  <span className="error invalid-feedback">{message}</span>
                )}
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="password">รหัสผ่าน <code>*</code></label>

            <div className="input-group">
              <input
                className={
                  errorsAddAgent.username
                    ? "form-control is-invalid"
                    : "form-control"
                }
                id="password"
                type="password"
                placeholder="Password"
                {...formAddAgent.register("password", {
                  required: "Please enter a password",
                  type: "password",
                })}
              />

              <ErrorMessage
                errors={errorsAddAgent}
                name="username"
                render={({ message }) => (
                  <span className="error invalid-feedback">{message}</span>
                )}
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="firstname">ชื่อ <code>*</code></label>
            <input
              className={
                errorsAddAgent.firstname
                  ? "form-control is-invalid"
                  : "form-control"
              }
              id="firstname"
              placeholder="Firstname"
              {...formAddAgent.register("firstname", {
                required: "Please enter a firstname.",
                type: "text",
              })}
            />

            <ErrorMessage
              errors={errorsAddAgent}
              name="firstname"
              render={({ message }) => (
                <span className="error invalid-feedback">{message}</span>
              )}
            />
          </div>

          <div className="form-group">
            <label htmlFor="lastname">นามสกุล <code>*</code></label>
            <input
              className={
                errorsAddAgent.lastname
                  ? "form-control is-invalid"
                  : "form-control"
              }
              id="lastname"
              placeholder="Lastname"
              {...formAddAgent.register("lastname", {
                required: "Please enter a lastname",
                type: "text",
              })}
            />

            <ErrorMessage
              errors={errorsAddAgent}
              name="lastname"
              render={({ message }) => (
                <span className="error invalid-feedback">{message}</span>
              )}
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">อีเมล์</label>
            <input
              className="form-control"
              id="email"
              placeholder="example@ichat.com"
            />
          </div>

          <div className="form-group">
            <label htmlFor="mobile">เบอร์โทรศัพท์</label>
            <input
              className="form-control"
              id="mobile"
              placeholder="Phone"
            />

            <ErrorMessage
              errors={errorsAddAgent}
              name="mobile"
              render={({ message }) => (
                <span className="error invalid-feedback">{message}</span>
              )}
            />
          </div>

          <div className="form-group">
            <label htmlFor="select_site">เว็บไซต์ <code>*</code></label>
            <select
              id="select_site"
              className={
                errorsAddAgent.site_id
                  ? "form-control is-invalid"
                  : "form-control"
              }
              placeholder="Please select first your site"
              defaultValue={""}
              {...formAddAgent.register("site_id", {
                required: "Please select first your site",
              })}
            >
              <option value="" disabled={true}>
                --กรุณาเลือก--
              </option>

              {siteList.map((item, index) => {
                return (
                  <option key={index} value={item.site_id}>
                    {item.key_name}
                  </option>
                );
              })}
            </select>

            <ErrorMessage
              errors={errorsAddAgent}
              name="site_id"
              render={({ message }) => (
                <span className="error invalid-feedback">{message}</span>
              )}
            />
          </div>
        </form>
      </Modal>
    </div>
  );
};

const EditAgentModalComponent = ({handleSuccess}) => {
  const [dataEdit, setDataEdit] = useRecoilState(dataEditState);

  const formEditAgent = useForm();
  const { errors: errorsEditAgent } = formEditAgent.formState;

  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setDataEdit(null);
    formEditAgent.reset();
    setShowModal(false);
  };

  useEffect(() => {
    if (dataEdit) {
      editAgent();
      handleShowModal();
    }
  }, [dataEdit]);

  function editAgent() {
    {
      const fields = [
        {
          id: "edit_userid",
          name: "userid",
        },
        {
          id: "edit_username",
          name: "username",
        },
        {
          id: "edit_email",
          name: "email",
        },
        {
          id: "edit_firstname",
          name: "firstname",
        },
        {
          id: "edit_lastname",
          name: "lastname",
        },
        {
          id: "edit_mobile",
          name: "mobile",
        },
      ];
      fields.forEach((field) => {
        formEditAgent.setValue(field.id, dataEdit[field.name]);
      });
    }
  }

  const onEditAgent = (params) => {
    handleCloseModal();
    SweetAlertUtil.showAlertWithConfirmButton(
      GlobalConstant.ALERT_TYPE.WARNING,
      GlobalConstant.WARNING_TITLE,
      "ยืนยันการทำรายการ?"
    ).then((ok) => {
      if (ok) {
        AgentData.editAgent(params).then((res) => {
          if (res.status) {
            let msg = `แก้ไขข้อมูลเจ้าหน้าที่ ${params.edit_username}`;
            SweetAlertUtil.showAlertWithCallBack(
              GlobalConstant.ALERT_TYPE.SUCCESS,
              GlobalConstant.SUCCESS_TITLE,
              msg
            ).then(() => {
              handleSuccess();
            });
          } else {
            let msg = res.errorText;
            if (msg && msg != "") {
              SweetAlertUtil.showAlert("error", GlobalConstant.ERROR_TITLE, msg);
            }
          }
        });
      }
    });
  };

  return (
    <Modal
      modalId="edit_agent_modal"
      show={showModal}
      title="แก้ไขข้อมูลเจ้าหน้าที่"
      btnSuccessText="บันทึก"
      handleSuccess={formEditAgent.handleSubmit(onEditAgent)}
      cancel={handleCloseModal}
    >
      <form>
        <div className="form-group">
          <input
            className="form-control"
            readOnly={true}
            type="hidden"
            {...formEditAgent.register("edit_userid")}
          />
        </div>

        <div className="form-group">
          <label htmlFor="input_edit_username">บัญชีผู้ใช้งาน <code>*</code></label>

          <div className="input-group">
            <input
              className={
                errorsEditAgent.edit_username
                  ? "form-control is-invalid"
                  : "form-control"
              }
              id="input_edit_username"
              placeholder="Username"
              {...formEditAgent.register("edit_username", {
                required: "Please enter a username",
                type: "text",
              })}
              readOnly={true}
            />

            <ErrorMessage
              errors={errorsEditAgent}
              name="edit_username"
              render={({ message }) => (
                <span className="error invalid-feedback">{message}</span>
              )}
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="input_edit_firstname">ชื่อ <code>*</code></label>
          <input
            className={
              errorsEditAgent.edit_firstname
                ? "form-control is-invalid"
                : "form-control"
            }
            id="input_edit_firstname"
            placeholder="Firstname"
            {...formEditAgent.register("edit_firstname", {
              required: "Please enter a firstname.",
              type: "text",
            })}
          />

          <ErrorMessage
            errors={errorsEditAgent}
            name="edit_firstname"
            render={({ message }) => (
              <span className="error invalid-feedback">{message}</span>
            )}
          />
        </div>

        <div className="form-group">
          <label htmlFor="input_edit_lastname">นามสกุล <code>*</code></label>
          <input
            className={
              errorsEditAgent.edit_lastname
                ? "form-control is-invalid"
                : "form-control"
            }
            id="input_edit_lastname"
            placeholder="Lastname"
            {...formEditAgent.register("edit_lastname", {
              required: "Please enter a lastname",
              type: "text",
            })}
          />

          <ErrorMessage
            errors={errorsEditAgent}
            name="edit_lastname"
            render={({ message }) => (
              <span className="error invalid-feedback">{message}</span>
            )}
          />
        </div>

        <div className="form-group">
          <label htmlFor="input_edit_email">อีเมล์</label>
          <input
            className={
              errorsEditAgent.edit_email
                ? "form-control is-invalid"
                : "form-control"
            }
            id="input_edit_email"
            placeholder="example@ichat.com"
            {...formEditAgent.register("edit_email", {
              type: "email",
            })}
          />

          <ErrorMessage
            errors={errorsEditAgent}
            name="edit_email"
            render={({ message }) => (
              <span className="error invalid-feedback">{message}</span>
            )}
          />
        </div>

        <div className="form-group">
          <label htmlFor="input_edit_mobile">เบอร์โทรศัพท์</label>
          <input
            className={
              errorsEditAgent.edit_mobile
                ? "form-control is-invalid"
                : "form-control"
            }
            id="input_edit_mobile"
            placeholder="Phone"
            {...formEditAgent.register("edit_mobile", {
              type: "text",
            })}
          />

          <ErrorMessage
            errors={errorsEditAgent}
            name="edit_mobile"
            render={({ message }) => (
              <span className="error invalid-feedback">{message}</span>
            )}
          />
        </div>
      </form>
    </Modal>
  );
};

export default AgentPage;