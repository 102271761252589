import { atom, selector } from "recoil";

const siteListState = atom({
  key: "siteListState",
  default: [],
});

const mySiteList = atom({
  key: "mySiteList",
  default: [],
});

const siteUnReadState = atom({
  key: "siteUnReadState",
  default: [],
});

const siteListSelector = selector({
  key: "siteListSelector",
  get: ({ get }) => get(siteListState),
  set: ({set}, newValue) => set(siteListState, newValue)
});

export { siteListState, mySiteList, siteUnReadState, siteListSelector };
