import { atom } from "recoil";

const userRecoilState = atom({ 
    key: "userState", 
    default: {
        user_info: {},
        counts: {}
    } 
});

export { userRecoilState };
