import { useContext, useCallback } from "react";
import { useRecoilState } from "recoil";
import { unreadRecoilState } from "../Dev/Recoil/unreadState";
import { SocketContext } from "../Services/Socket";
import AuthenService from "../Services/AuthenService";

const useSiteUnread = () => {
  const socket = useContext(SocketContext);
  const { userid } = AuthenService.getAccessor();

  const [unreadSite, setSiteUnreadCount] = useRecoilState(unreadRecoilState);

  const getSiteUnread = useCallback(()=>{
    socket.emit("site_unread_count", {user_id: userid}, unreadCountCallBack);
  }, [])

  const unreadCountCallBack = useCallback((object) => {
    // console.log("site_unread_count ", object);
    const {status, data} = object;
    if (status) {
      setSiteUnreadCount(data.list);
    }
  }, []);

  return {unreadSite, getSiteUnread}
}
export default useSiteUnread;