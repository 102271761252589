function responseBody(status, data, errorText) {
  let responseBody = {
    status: false,
    data: null,
    errorText: null,
  };

  if (status) {
    responseBody.status = true;
    responseBody.errorText = null;
  } else {
    responseBody.status = false;
    responseBody.errorText = errorText || "";
  }

  responseBody.data = data || null;
  return responseBody;
}

export default responseBody;
