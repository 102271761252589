import Swal from "sweetalert2";
import "@sweetalert2/theme-bootstrap-4/bootstrap-4.css";
class SweetAlertUtil {
  showLoading() {
    Swal.showLoading();
  }
  showAlert(type, title, msg) {
    Swal.fire({
      icon: type,
      title: title,
      confirmButtonText: 'ตกลง',
      text: msg,
    });
  }
  showAlertWithConfirmButton(type, title, msg) {
    return new Promise((success, cancel) => {
      Swal.fire({
        icon: type,
        title: title,
        text: msg,
        focusConfirm: false,
        showDenyButton: true,
        denyButtonText: 'ยกเลิก',
        confirmButtonText: 'ตกลง'
      }).then((result) => {
        if (result.isConfirmed) {
          success(true);
        } else {
          success(false);
        }
      });
    });
  }
  showAlertWithCallBack(type, title, msg) {
    return new Promise((success, reject) => {
      Swal.fire({
        icon: type,
        title: title,
        text: msg,
        confirmButtonText: 'ตกลง'
      }).then((result) => {
        if (result.isConfirmed) {
          success(true);
        } else {
          success(false);
        }
      });
    });
  }
  showAlertWithOutClose(type, title, msg) {
    return new Promise((success, reject) => {
      Swal.fire({
        icon: type,
        title: title,
        text: msg,
        confirmButtonText: "ปิด",
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          success(true);
        } else {
          success(false);
        }
      });
    });
  }
}

export default new SweetAlertUtil();
