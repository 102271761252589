import React, { useEffect } from "react";
import {
  RecoilRoot,
  atom,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { SyncLoader } from "react-spinners";
import ContentHeader from "../../ContentHeader";
import Modal from "../../../Components/Modal";
import Swal from "../../../Containers/SweetAlertUtil";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import AgentAddToSiteData from "./AgentAddToSite.data";
import GlobalConstant from "../../../GlobalConstant";
import { useState } from "react";

const cssLoading = {
  position: "relative",
  display: "flex",
  width: "100%",
  height: "100px",
  justifyContent: "center",
  alignItems: "center",
};

const color = "rgba(0, 0, 0, 0.4)";

const hasSiteLoading = atom({
  key: "hasSiteLoading",
  default: false,
});

const addSiteModalState = atom({
  key: "addSiteModalState",
  default: false,
});

const otherSiteListAtom = atom({
  key: "otherSiteListAtom",
  default: [],
});

const mySiteListState = atom({
  key: "mySiteListState",
  default: [],
});

const agentState = atom({
  key: "agentState",
  default: {
    firstname: "",
    lastname: "",
    mobile: "",
    is_active: "",
    role_nameen: "",
  },
});

// ================ Root ================ //
const AgentAddToSite = (props) => {
  const { agentId } = props.match.params;

  return (
    <RecoilRoot>
      <div>
        <ContentHeader
          title="Add Site To Operator"
          titleName="Add Site To Operator"
          backTitle="Operator"
          backLink="/page/agent"
        />

        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-6">
                <SiteListComponent agentId={agentId} />
              </div>
              <div className="col-6">
                <AgentDetailsComponent />
              </div>
            </div>
          </div>
        </div>

        <AddSiteModalComponent agentId={agentId} />
      </div>
    </RecoilRoot>
  );
};

// ================ Site List Table Component ================ //
const SiteListComponent = (props) => {
  const { agentId } = props;

  const [hasLoading, setHasLoading] = useRecoilState(hasSiteLoading);
  const setHasShowModal = useSetRecoilState(addSiteModalState);
  const [otherSites, setOtherSites] = useRecoilState(otherSiteListAtom);
  const [mySiteList, setMySiteList] = useRecoilState(mySiteListState);
  const setAgentInfo = useSetRecoilState(agentState);

  useEffect(() => {
    const onGetMySiteList = async () => {
      setHasLoading(true);
      const {status, data, errorText} = await AgentAddToSiteData.getMySiteList({ agentId: agentId });
      setHasLoading(false);
  
      if (status) {
        const userInfo = data.user_info || {};
        setAgentInfo(userInfo);
        const mySites = data.my_sites || [];
        setMySiteList(mySites);
        const otherSites = data.other_sites || [];
        setOtherSites(otherSites);
      }else {
        Swal.showAlert(GlobalConstant.ALERT_TYPE.ERROR, GlobalConstant.ERROR_TITLE, errorText);
      }
    }
    onGetMySiteList();
    return () => {};
  }, [agentId]);

  const onLeaveSite = (siteId) => {
    Swal.showAlertWithConfirmButton(
      GlobalConstant.ALERT_TYPE.WARNING,
      GlobalConstant.WARNING_TITLE,
      "คุณต้องการออกจากเว็บไซต์?"
    ).then( async (ok) => {
      if (ok) {
        const {status, data, errorText} = await AgentAddToSiteData.leaveSite({ siteId: siteId, agentId: agentId });

        if (status) {
          Swal.showAlert(GlobalConstant.ALERT_TYPE.SUCCESS, GlobalConstant.SUCCESS_TITLE, "");

          const site = data.site || {};
          setMySiteList(item => 
            item.filter(i => i.site_id !== site.site_id)
          );
          setOtherSites((item) => [...item, site]);

        } else {
          Swal.showAlert(GlobalConstant.ALERT_TYPE.ERROR, GlobalConstant.ERROR_TITLE, errorText);
        }
      }
    });
  };

  return (
    <div>
      <div className="card">
        <div className="card-header">
          <div className="row align-items-center">
            <div className="col-6">Sites</div>
            <div className="col-6">
              <button
                type="button"
                className="btn btn-primary float-right"
                disabled={otherSites && otherSites.length <= 0}
                onClick={() => setHasShowModal(true)}
              >
                <i className="fas fa-plus-square"></i> เพิ่ม
              </button>
            </div>
          </div>
        </div>
        <div className="card-body p-0">
          {hasLoading ? (
            <SyncLoader color={color} loading={hasLoading} css={cssLoading} />
          ) : (
            <div className="table-responsive">
              <table className="table table-hover text-nowrap">
                <thead>
                  <tr>
                    <th>DOMAIN</th>
                    <th>NAME</th>
                    <th scope="col" className="text-center">
                      LEAVE
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {mySiteList &&
                    mySiteList.map((item) => {
                      return (
                        <tr key={item.site_id}>
                          <td scope="row">{item.domain}</td>
                          <td scope="row">{item.key_name}</td>
                          <td scope="row" className="text-center">
                            <button
                              type="button"
                              className="btn btn-danger btn-sm"
                              onClick={() => onLeaveSite(item.site_id)}
                            >
                              <i className="fas fa-sign-out-alt" /> LEAVE
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// ================ Agent Component ================ //
const AgentDetailsComponent = (props) => {
  const hasLoading = useRecoilValue(hasSiteLoading);
  const agentDetails = useRecoilValue(agentState);

  return (
    <div>
      <div className="card">
        <div className="card-header">รายละเอียด</div>
        {hasLoading ? (
          <SyncLoader color={color} loading={hasLoading} css={cssLoading} />
        ) : (
          <div className="card-body">
            <div className="text-center mb-4">
              <img
                className="profile-user-img img-fluid img-circle"
                src="/dist/img/icons/chat-avatar.png"
                alt="User profile picture"
              />
            </div>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label>ชื่อ:</label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly={true}
                    value={agentDetails.firstname ?? ""}
                  />
                </div>

                <div className="form-group">
                  <label>เบอร์โทรศัพท์:</label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly={true}
                    value={agentDetails.mobile ?? ""}
                  />
                </div>

                <div className="form-group">
                  <label>สถานะ:</label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly={true}
                    value={agentDetails.status_name ?? ""}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label>นามสกุล:</label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly={true}
                    value={agentDetails.lastname ?? ""}
                  />
                </div>

                <div className="form-group">
                  <label>ระดับ:</label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly={true}
                    value={agentDetails.role_nameen ?? ""}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// ================ Add Site To Agent Modal Component ================ //
const AddSiteModalComponent = (props) => {
  const { agentId } = props;

  //register, handleSubmit, setValue, reset, clearErrors, formState
  const formAddAgentToSite = useForm();
  const { errors: errorsAddAgentToSite } = formAddAgentToSite.formState;

  const [hasShowAddSiteModal, setHasShowAddSiteModal] =
    useRecoilState(addSiteModalState);
  const handleCloseModal = () => {
    formAddAgentToSite.reset();
    setHasShowAddSiteModal(false);
  }

  const otherSites = useRecoilValue(otherSiteListAtom);
  const [mySiteList,setMySiteList] = useRecoilState(mySiteListState);
  const [listSelect, setListSelect] = useState([]);

  useEffect(() => {
    setListSelect([...otherSites]);
  },[otherSites,mySiteList]);

  const onAddSiteToAgent = (formData) => {
    if (formData) {
      Swal.showAlertWithConfirmButton(
        GlobalConstant.ALERT_TYPE.WARNING,
        GlobalConstant.WARNING_TITLE,
        "คุณต้องการเพิ่มเว็บไซต์?"
      ).then(async (ok) => {
        if (!ok) return;

        let params = {
          siteId: formData.site,
          agentId: agentId,
        }
        const {status, data, errorText} = await AgentAddToSiteData.addSiteToAgent(params);
        if (status) {
          const site = data.site || {};
          setMySiteList((list) => [...list, site]);
          formAddAgentToSite.reset();
          handleCloseModal();
          Swal.showAlert(GlobalConstant.ALERT_TYPE.SUCCESS,GlobalConstant.SUCCESS_TITLE, "");
        } else {
          Swal.showAlert(GlobalConstant.ALERT_TYPE.ERROR, GlobalConstant.ERROR_TITLE, errorText);
        }
      });
    }
  };

  return (
    <div>
      <Modal
        modalId="add_site_modal"
        show={hasShowAddSiteModal}
        title="เพิ่มเว็บไซต์"
        btnSuccessText="เพิ่ม"
        handleSuccess={formAddAgentToSite.handleSubmit(onAddSiteToAgent)}
        cancel={handleCloseModal}
      >
        <form method="post">
          <div className="container-fluid">
            <div className="form-group">
              <label htmlFor="select_site">รายชื่อเว็บไซต์ <code>*</code></label>
              <select
                className={
                  errorsAddAgentToSite.site
                    ? "form-control is-invalid"
                    : "form-control"
                }
                id="select_site"
                defaultValue="0"
                {...formAddAgentToSite.register("site", {
                  required: "Please select site",
                  validate: (value) => value != 0,
                })}
              >
                <option value="0" disabled>
                  --กรุณาเลือก--
                </option>

                {listSelect && listSelect.length > 0
                  ? listSelect.map((item) => {
                      return (
                        <option key={item.site_id} value={item.site_id}>
                          {item.domain}
                        </option>
                      );
                    })
                  : null}
              </select>

              <ErrorMessage
                errors={errorsAddAgentToSite}
                name="site"
                render={({ message }) => (
                  <span className="error invalid-feedback">{message}</span>
                )}
              />
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

AgentAddToSite.propTypes = {};

export default AgentAddToSite;
