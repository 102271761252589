import React, { useEffect } from "react";
import { format } from "timeago.js";

const Message = ({message, readMsgId}) => {
  const _messageDiv = document.querySelector(".messages");
  
  useEffect(() => {
    message && message.length > 0 && scrollToBottom();
  }, [message]);

  function scrollToBottom() {
    if (_messageDiv) {
      _messageDiv.scrollTop = _messageDiv.scrollHeight;
    }
  }
    return (
        <div className="messages">
          <ul>
            {message.map(m => (
              <React.Fragment key={m.id} >
              <li className={m.is_staff ? "replies" : "sent"}>
                <img src="/dist/img/icons/chat-avatar.png" />
                <p
                  dangerouslySetInnerHTML={{
                    __html: m.content,
                  }}
                />
              </li>
              <div
                className="row text-gray align-items-center"
                style={{
                  float: m.is_staff ? "right" : "left",
                  fontSize: 8,
                  marginRight: m.is_staff ? "55px" : "0px",
                  marginLeft: !m.is_staff ? "30px" : "0px",
                }}
              >
                {m.is_staff && m.id === readMsgId ? <i className="fas fa-check-circle mx-1"></i> : ""} {format(m.create_date, 'th_TH')}
              </div>
              </React.Fragment>
            ))}
          </ul>
        </div>
      );
}

export default Message;