
import { atom } from "recoil";

const memberListAtom = atom({
    key: "memberList",
    default: [],
});

const memberDetailAtom = atom({
    key: "memberDetail",
    default: null,
});

export {memberListAtom, memberDetailAtom};